/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * An account representing any user of the system.
 * @export
 * @interface StaffAccountDto
 */
export interface StaffAccountDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof StaffAccountDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof StaffAccountDto
     */
    version?: VersionDto;
    /**
     * An email address
     * @type {string}
     * @memberof StaffAccountDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof StaffAccountDto
     */
    displayName?: string;
    /**
     * The plaintext password to authenticate later.
     * @type {string}
     * @memberof StaffAccountDto
     */
    password?: string;
    /**
     * The current state, only approved accounts can log in.
     * @type {string}
     * @memberof StaffAccountDto
     */
    readonly state: StaffAccountDtoStateEnum;
    /**
     * 
     * @type {Array<SectionReferenceDto>}
     * @memberof StaffAccountDto
     */
    readonly memberships?: Array<SectionReferenceDto>;
}


/**
 * @export
 */
export const StaffAccountDtoStateEnum = {
    CREATED: 'CREATED',
    APPROVED: 'APPROVED',
    LOCKED: 'LOCKED'
} as const;
export type StaffAccountDtoStateEnum = typeof StaffAccountDtoStateEnum[keyof typeof StaffAccountDtoStateEnum];


/**
 * Check if a given object implements the StaffAccountDto interface.
 */
export function instanceOfStaffAccountDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('email' in value)) return false;
    if (!('state' in value)) return false;
    return true;
}

export function StaffAccountDtoFromJSON(json: any): StaffAccountDto {
    return StaffAccountDtoFromJSONTyped(json, false);
}

export function StaffAccountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffAccountDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'email': json['email'],
        'displayName': json['displayName'] == null ? undefined : json['displayName'],
        'password': json['password'] == null ? undefined : json['password'],
        'state': json['state'],
        'memberships': json['memberships'] == null ? undefined : ((json['memberships'] as Array<any>).map(SectionReferenceDtoFromJSON)),
    };
}

export function StaffAccountDtoToJSON(value?: StaffAccountDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'email': value['email'],
        'displayName': value['displayName'],
        'password': value['password'],
    };
}

