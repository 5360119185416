import { Accordion, Stack } from '@chakra-ui/react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import usePermission, { Permission } from '../../permission/use-permission';
import { AppNavigationMenuItem } from './app-navigation-menu-item';
import isActiveSubMenu from './is-active-sub-menu';
import MenuItem from './menu-item';
import SubMenuItem from './sub-menu-item';

interface AppNavigationMenuProps {
  items: MenuItem[];
  isCollapsed?: boolean;
}

export default function AppNavigationMenu({ items, isCollapsed = false }: AppNavigationMenuProps) {
  const { pathname } = useLocation();
  const { hasPermission } = usePermission();
  const visibleItems = React.useMemo(
    () => items.filter((value) => value.menuItems.some((value) => hasAccess(value, hasPermission))),
    [hasPermission, items],
  );
  const [expandedIndices, setExpandedIndices] = React.useState(() => getActiveIndices(visibleItems, pathname) ?? []);

  React.useEffect(() => {
    const activeIndices = getActiveIndices(visibleItems, pathname);

    setExpandedIndices((expandedIndices) => {
      const missingIndices = activeIndices.filter((index) => !expandedIndices.includes(index));

      if (missingIndices.length > 0) {
        return [...expandedIndices, ...missingIndices];
      }

      return expandedIndices;
    });
  }, [visibleItems, pathname]);

  if (isCollapsed) {
    return (
      <Stack spacing={1}>
        {visibleItems.map((item, index) => (
          <AppNavigationMenuItem key={index} item={item} isCollapsed={isCollapsed} />
        ))}
      </Stack>
    );
  }

  return (
    <Accordion
      as={Stack}
      variant="unstyled"
      spacing={1}
      allowMultiple
      index={expandedIndices}
      onChange={(expandedIndex) => {
        setExpandedIndices(expandedIndex as number[]);
      }}
    >
      {visibleItems.map((item, index) => (
        <AppNavigationMenuItem key={index} item={item} />
      ))}
    </Accordion>
  );
}

function getActiveIndices(menuItems: MenuItem[], pathname: string) {
  return menuItems.reduce<number[]>((activeIndices, item, index) => {
    const matches = item.menuItems.some((item) => item.path != null && isActiveSubMenu(item, pathname));

    if (matches) {
      return [...activeIndices, index];
    }

    return activeIndices;
  }, []);
}

export function hasAccess(subMenuItem: SubMenuItem, hasPermission: (permission: Permission) => boolean) {
  if (subMenuItem.necessaryPermission != null) {
    return hasPermission(subMenuItem.necessaryPermission);
  }

  return true;
}
