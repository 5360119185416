import { MenuItem } from '@chakra-ui/react';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { CompanyDto } from '../../../api';
import companyApi from '../../../data-access/company-api';
import DeleteButton from '../../../ui/action-menu/delete-button/delete-button';
import { useTranslation } from 'react-i18next';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchCompanyStatus } from '../company-queries';

interface DeleteCompanyMenuItemProps {
  company: CompanyDto;
  onDelete: () => void;
}

export default function DeleteCompanyMenuItem({ company, onDelete }: DeleteCompanyMenuItemProps) {
  const { t } = useTranslation('company');
  const companyStatusCheckDto = useFetcher(fetchCompanyStatus, { companyId: company.id });

  return (
    <MenuItem
      key="delete"
      isDisabled={!companyStatusCheckDto.isDeletable}
      disableReason={t('action.deleteFailedReason')}
      color="text.error"
      icon={<FontAwesomeIcon fixedWidth icon={faTrashAlt} />}
      as={DeleteButton<CompanyDto>}
      businessObject={company}
      objectName={company.name}
      deleteObject={({ id }: CompanyDto) => companyApi.deleteCompany({ id })}
      objectTypeToDelete={t('company')}
      afterDelete={onDelete}
      objectTypeWithArticleNominative={t('companyWithArticle')}
      objectTypeWithArticleGenitive={t('company_with_article_genetive')}
    >
      {t('action.delete')}
    </MenuItem>
  );
}
