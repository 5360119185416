import React from 'react';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';

const drivingScheduleRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'driving-schedule',
    handle: {
      breadcrumb: <Translate ns="driving" i18nKey="schedule.drivingSchedule" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./driving-schedule-lister/driving-schedule-lister-route'),
      },
    ],
  },
];

export default drivingScheduleRoutes;
