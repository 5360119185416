import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { PluginToken } from '../../util/plugin/use-plugins';
import invariant from 'tiny-invariant';
import usePlugins from '../../util/plugin/use-plugins';

export default interface NotificationTypeExtension {
  type: string;
  icon: IconDefinition;
  linkUrl: (entityId: string) => string;
}

export const NOTIFICATION_TYPE_EXTENSION = new PluginToken<NotificationTypeExtension>('NotificationTypeExtension');

export function useNotificationTypeExtension(type: string) {
  const extensions = usePlugins(NOTIFICATION_TYPE_EXTENSION);

  const extension = extensions.find((x) => x.type === type);
  invariant(extension != null, `extension not found for type ${type}`);
  return extension;
}
