import useSWR, { BareFetcher, Key, SWRConfiguration } from 'swr';
import Fetcher from './fetcher';

export interface UseFetcherConfig<TData = unknown, TError = unknown>
  extends SWRConfiguration<TData, TError, BareFetcher<TData>> {
  suspense?: boolean;
  active?: boolean;
  immutable?: boolean;
}

export default function useFetcher<TArgs extends Key, TData, TError>(
  fetcher: Fetcher<TArgs, TData, TError>,
  args: TArgs,
  config: UseFetcherConfig<TData, TError> & { active: false },
): undefined;
export default function useFetcher<TArgs extends Key, TData, TError>(
  fetcher: Fetcher<TArgs, TData, TError>,
  args: TArgs,
  config: UseFetcherConfig<TData, TError> & { suspense: false },
): TData | undefined;
export default function useFetcher<TArgs extends Key, TData, TError>(
  fetcher: Fetcher<TArgs, TData, TError>,
  args: TArgs,
  config: UseFetcherConfig<TData, TError> & { active: boolean | undefined },
): TData | undefined;
export default function useFetcher<TArgs extends Key, TData, TError>(
  fetcher: Fetcher<TArgs, TData, TError>,
  args: TArgs,
  config?: UseFetcherConfig<TData, TError> | (UseFetcherConfig<TData, TError> & { suspense?: true; active?: true }),
): TData;
export default function useFetcher<TArgs extends Key, TData, TError>(
  fetcher: Fetcher<TArgs, TData, TError>,
  args: TArgs,
  { active = true, immutable, ...config }: UseFetcherConfig<TData, TError> = {},
) {
  const key = active ? fetcher.key(args) : null;
  const fetcherFn = () => fetcher.fetcher(args);
  config = {
    ...fetcher.config,
    suspense: true,
    ...config,
  };

  if (immutable) {
    config.revalidateOnMount = false;
    config.revalidateOnFocus = false;
    config.revalidateOnReconnect = false;
  }

  return useSWR(key, fetcherFn, config).data;
}
