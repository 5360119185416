import { useCallbackRef } from '@chakra-ui/react';
import { GetOptionValue, InputActionMeta } from 'chakra-react-select';
import React from 'react';
import ValueSelectOption from './value-select-option';

interface SortedOptionsProps<TOption> {
  options: ValueSelectOption<TOption>[];
  onInputChange?(value: string, actionMeta: InputActionMeta): void;
  getOptionValue(option: ValueSelectOption<TOption>): string;
}

export default function useSortedOptions<TOption, TProps extends SortedOptionsProps<TOption>>({
  options,
  onInputChange,
  getOptionValue,
  ...props
}: TProps): TProps {
  const [inputValue, setInputValue] = React.useState('');
  getOptionValue = useCallbackRef(getOptionValue);

  return {
    ...props,
    getOptionValue,
    options: React.useMemo(
      () => sortOptions(inputValue, options, getOptionValue),
      [inputValue, options, getOptionValue],
    ),
    onInputChange(value, actionMeta) {
      setInputValue(value);
      onInputChange?.(value, actionMeta);
    },
  } as TProps;
}

function sortOptions<TOption>(
  searchString: string,
  options: ValueSelectOption<TOption>[],
  getOptionStringValue?: GetOptionValue<ValueSelectOption<TOption>>,
): ValueSelectOption<TOption>[] {
  options = [...options].sort((a, b) => {
    const labelA = getOptionStringValue ? getOptionStringValue(a) : a.label;
    const labelB = getOptionStringValue ? getOptionStringValue(b) : b.label;
    if (typeof labelA !== 'string' || typeof labelB !== 'string') {
      return 0;
    }
    return labelA.localeCompare(labelB);
  });
  if (searchString === '') {
    return options;
  }

  return [...options].sort((a, b) => {
    const labelA = getOptionStringValue ? getOptionStringValue(a) : a.label;
    const labelB = getOptionStringValue ? getOptionStringValue(b) : b.label;

    if (typeof labelA !== 'string' || typeof labelB !== 'string') {
      return 0;
    }

    searchString = searchString.toLowerCase();

    const aStartsWithSearch = labelA.toLowerCase().startsWith(searchString);
    const bStartsWithSearch = labelB.toLowerCase().startsWith(searchString);

    if (bStartsWithSearch && !aStartsWithSearch) {
      return 1;
    } else if (aStartsWithSearch && !bStartsWithSearch) {
      return -1;
    } else {
      return 0;
    }
  });
}
