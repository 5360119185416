import { UseToastOptions } from '@chakra-ui/react';
import React from 'react';
import { ErrorContext, FailureDto, Middleware, ResponseContext } from '../api';
import { toast } from '../util/standalone-toast';
import Translate from '../util/translate/translate';
import { asFailureDto } from './as-failure-dto';
import { isOptimisticLockingFailure } from './optimistic-locking';

export type CustomRequestInit = RequestInit & {
  allowedErrorCodes?: number[];
  connectionLostError?: UseToastOptions;
};

const ERROR_TOAST_ID = 'server-error-toast';

function getErrorMessage(statusCode: number, failureDto: FailureDto) {
  if (statusCode === 409 && failureDto.code === 'SelfConflict') {
    return {
      title: <Translate ns="common" i18nKey="toast.self_conflict.title" suspense={false} />,
      description: <Translate ns="common" i18nKey="toast.self_conflict.description" suspense={false} />,
    };
  }

  if (statusCode === 409 && failureDto.code === 'DuplicateNameConflict') {
    return {
      title: <Translate ns="common" i18nKey="toast.duplicate_name_conflict.title" suspense={false} />,
      description: <Translate ns="common" i18nKey="toast.duplicate_name_conflict.description" suspense={false} />,
    };
  }

  if (statusCode === 409 && failureDto.code === 'StatusConflict') {
    return {
      title: <Translate ns="common" i18nKey="toast.status_conflict.title" suspense={false} />,
      description: <Translate ns="common" i18nKey="toast.status_conflict.description" suspense={false} />,
    };
  }

  if (statusCode === 409 && failureDto.code === 'RelationStatusConflict') {
    return {
      title: <Translate ns="common" i18nKey="toast.relation_status_conflict.title" suspense={false} />,
      description: <Translate ns="common" i18nKey="toast.relation_status_conflict.description" suspense={false} />,
    };
  }

  if (statusCode === 409 && failureDto.code === 'KeycloakEmailVerification') {
    return {
      title: <Translate ns="account" i18nKey="emailFailed.title" suspense={false} />,
      description: <Translate ns="account" i18nKey="emailFailed.description" suspense={false} />,
    };
  }

  if (statusCode === 409 && failureDto.code === 'CompanyAdminKeycloakEmailVerification') {
    return {
      title: <Translate ns="company" i18nKey="externalAdmin.emailFailed.title" suspense={false} />,
      description: <Translate ns="company" i18nKey="externalAdmin.emailFailed.description" suspense={false} />,
    };
  }

  if (statusCode === 422 && failureDto.code === 'FileUpload') {
    return {
      title: <Translate ns="attachment" i18nKey="errors.file_upload.title" suspense={false} />,
      description: <Translate ns="attachment" i18nKey="errors.file_upload.description" suspense={false} />,
    };
  }

  if (statusCode === 409 && failureDto.code === 'MissingOrderConfirmationRecipient') {
    return {
      title: <Translate ns="order_confirmation" i18nKey="errors.missingRecipient.title" suspense={false} />,
      description: <Translate ns="order_confirmation" i18nKey="errors.missingRecipient.description" suspense={false} />,
    };
  }

  if (statusCode === 409 && failureDto.code === 'MissingInvoiceRecipient') {
    return {
      title: <Translate ns="invoice" i18nKey="errors.missingRecipient.title" suspense={false} />,
      description: <Translate ns="invoice" i18nKey="errors.missingRecipient.description" suspense={false} />,
    };
  }

  if (statusCode === 409 && failureDto.code === 'InvalidInvoiceSumUpdate') {
    return {
      title: <Translate ns="invoice" i18nKey="errors.invalidInvoiceSumUpdate.title" suspense={false} />,
      description: <Translate ns="invoice" i18nKey="errors.invalidInvoiceSumUpdate.description" suspense={false} />,
    };
  }

  return errorMessages[statusCode] || defaultError;
}

const errorMessages: Record<number, UseToastOptions> = {
  409: {
    title: <Translate ns="common" i18nKey="toast.conflict.title" suspense={false} />,
    description: <Translate ns="common" i18nKey="toast.conflict.description" suspense={false} />,
  },
  401: {
    title: <Translate ns="common" i18nKey="toast.unauthorized.title" suspense={false} />,
    description: <Translate ns="common" i18nKey="toast.unauthorized.description" suspense={false} />,
  },
  403: {
    title: <Translate ns="common" i18nKey="toast.forbidden.title" suspense={false} />,
    description: <Translate ns="common" i18nKey="toast.forbidden.description" suspense={false} />,
  },
  404: {
    title: <Translate ns="common" i18nKey="toast.not_found.title" suspense={false} />,
    description: <Translate ns="common" i18nKey="toast.not_found.description" suspense={false} />,
  },
  422: {
    title: <Translate ns="common" i18nKey="toast.unprocessable_entity.title" suspense={false} />,
    description: <Translate ns="common" i18nKey="toast.unprocessable_entity.description" suspense={false} />,
  },
  429: {
    title: <Translate ns="common" i18nKey="toast.too_many_requests.title" suspense={false} />,
    description: <Translate ns="common" i18nKey="toast.too_many_requests.description" suspense={false} />,
  },
};

export const defaultError: UseToastOptions = {
  title: <Translate ns="common" i18nKey="toast.server_error.title" suspense={false} />,
  description: <Translate ns="common" i18nKey="toast.server_error.description" suspense={false} />,
};

function isAllowedErrorCode(init: CustomRequestInit, response: Response) {
  return init.allowedErrorCodes != null && init.allowedErrorCodes.includes(response.status);
}

const ErrorMiddleware: Middleware = {
  async post({
    response,
    init,
  }: ResponseContext & {
    init: CustomRequestInit;
  }): Promise<Response | void> {
    if (response.ok || isAllowedErrorCode(init, response)) {
      return response;
    }

    const failureDto = await asFailureDto(response);

    if (toast.isActive(ERROR_TOAST_ID)) {
      return response;
    }

    if (!isOptimisticLockingFailure(failureDto)) {
      toast({
        ...getErrorMessage(response.status, failureDto),
        id: ERROR_TOAST_ID,
        status: 'error',
      });
    }

    return response;
  },
  async onError({
    error,
    init,
  }: ErrorContext & {
    init: CustomRequestInit;
  }): Promise<Response | void> {
    if (error instanceof DOMException) {
      throw error;
    }

    if (init.connectionLostError != null && !toast.isActive(ERROR_TOAST_ID)) {
      toast({ ...init.connectionLostError, id: ERROR_TOAST_ID, status: 'error' });
    }

    // By returning undefined the runtime.ts throws the exception again
    return undefined;
  },
};

export default ErrorMiddleware;
