import React from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyDto } from '../../../api';
import { refundApi } from '../../../data-access/invoice-api';
import { DisableableButton } from '../../../ui/disableable-button/disableable-button';
import useToast from '../../../ui/use-toast/use-toast';
import useFetcher from '../../../util/swr/use-fetcher';
import useDialog from '../../../util/use-dialog/use-dialog';
import { existsByRecipient, invoiceFetcher } from '../invoice-queries';
import InvoiceRefundTaxDialog from './invoice-refund-tax-dialog';

interface InvoiceRefundButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  company: CompanyDto;
}

export const InvoiceRefundTaxButton = React.forwardRef<HTMLButtonElement, InvoiceRefundButtonProps>(
  ({ company, children, ...props }, ref) => {
    const { t } = useTranslation(['invoice']);
    const { isOpen, onClose, openDialog } = useDialog();

    const showExemptionSuccessToast = useToast({
      id: 'invoice-refund-tax-success-toast',
      status: 'success',
    });

    const companyHasNoTaxExemptions = company.taxExemptions && company.taxExemptions.length < 1;
    const invoicesExist = useFetcher(existsByRecipient, { companyId: company.id });

    const handleClick = openDialog(async () => {
      await refundApi.exemptCompanyFromValueAddedTax({ companyId: company.id });

      showExemptionSuccessToast({
        title: t('invoice:action.refundTax.title'),
        description: t('invoice:action.refundTax.description', {
          companyName: company.name,
        }),
      });

      await invoiceFetcher.mutate();
    });

    return (
      <>
        <DisableableButton
          {...props}
          ref={ref}
          onClick={handleClick}
          aria-label={t('invoice:action.refundTax.label')}
          isDisabled={companyHasNoTaxExemptions || !invoicesExist}
          disableReason={
            companyHasNoTaxExemptions
              ? t('invoice:action.refundTax.disabledIfNoTaxExemptions', { companyName: company.name })
              : t('invoice:action.refundTax.disabledIfNoInvoices', { companyName: company.name })
          }
        >
          <>
            {children}
            {t('invoice:action.refundTax.label')}
          </>
        </DisableableButton>
        <InvoiceRefundTaxDialog company={company} onClose={onClose} isOpen={isOpen} />
      </>
    );
  },
);
