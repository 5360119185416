import { Stack } from '@chakra-ui/react';
import { useState } from 'react';
import { PageSpinner } from '../../../ui/page';
import Pagination from '../../../ui/pagination/pagination';
import { PageState } from '../../../ui/pagination/use-pagination-state';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchNotifications } from '../notification-queries';
import Notification from './notification';

interface NotificationDrawerContentProps {
  onClose: () => void;
}

export default function NotificationDrawerContent({ onClose }: NotificationDrawerContentProps) {
  const [state, setState] = useState<PageState>({ page: 0, size: 20 });

  const page = useFetcher(fetchNotifications, { pageable: state }, { suspense: false });

  return (
    <>
      <Stack>
        {page == null && <PageSpinner />}
        {page?.content.map((notification) => {
          return <Notification key={notification.id} notification={notification} onClick={onClose} />;
        })}
        {page != null && page.totalPages > 1 && (
          <Pagination
            page={page}
            onPrevPage={() => {
              setState({ ...state, page: (state.page ?? 0) - 1 });
            }}
            onNextPage={() => {
              setState({ ...state, page: (state.page ?? 0) + 1 });
            }}
          />
        )}
      </Stack>
    </>
  );
}
