import { ReactNode } from 'react';
import { CollectiveOrderConfirmationViewerDto, OrderConfirmationViewerDto } from '../../../api';
import { PluginToken } from '../../../util/plugin/use-plugins';

export interface OrderConfirmationViewerItemExtension {
  item: (
    orderConfirmation: OrderConfirmationViewerDto | CollectiveOrderConfirmationViewerDto,
    key: number,
  ) => ReactNode;
}

export const ORDER_CONFIRMATION_VIEWER_ITEM_EXTENSION = new PluginToken<OrderConfirmationViewerItemExtension>(
  'OrderConfirmationViewerItemExtension',
);
