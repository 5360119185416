import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { vehicleBookingIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function VehicleBookingSearchResult({ result, onClick }: SearchResultProps) {
  invariant(
    result.resultType === 'VEHICLE_BOOKING',
    `${result.resultType} is no instance of VehicleBookingSearchResult`,
  );

  const vehicleBooking = result.result;
  const { t } = useTranslation(['common', 'vehicle_booking', 'driving']);

  return (
    <SearchResult
      icon={vehicleBookingIcon}
      title={vehicleBooking.transactionNumber}
      link={`/vehicle-bookings/${vehicleBooking.id}`}
      score={result.score}
      onClick={onClick}
      // todo BX-4489 necessaryPermission="VEHICLE_BOOKING:CAN_SEE_DETAILS_OWN"
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('driving:common.edition'),
            element: vehicleBooking.edition.name,
          },
          {
            label: t('driving:common.principal'),
            element: vehicleBooking.principal.firstName + ' ' + vehicleBooking.principal.surname,
          },
          {
            label: t('driving:common.pickUpTime'),
            element: t('common:format.date_time', { dateTime: vehicleBooking.pickUpTime }),
          },
          {
            label: t('driving:common.startingPoint'),
            element: vehicleBooking.startingPoint,
          },
          {
            label: t('driving:common.personPassengers'),
            element: vehicleBooking.personPassengers
              ?.map((person) => person.firstName + ' ' + person.surname)
              .join(', '),
          },
          {
            label: t('driving:common.customPassengers'),
            element: vehicleBooking.customPassengers,
          },
          {
            label: t('driving:common.groupPassengers'),
            element: vehicleBooking.groups?.map((group) => group.name).join(', '),
          },
          {
            label: t('vehicle_booking:status'),
            element: t(`vehicle_booking:statusOptions.${vehicleBooking.status}`),
          },
        ]}
      />
    </SearchResult>
  );
}
