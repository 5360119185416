import { CompanyDto } from '../../api';
import companyApi from '../../data-access/company-api';
import { createFetcherScope } from '../../util/swr/fetcher-scope';

export const companyFetcher = createFetcherScope(companyApi);
export const fetchCompany = companyFetcher.createFetcher(({ id }: { id?: string }): Promise<CompanyDto> => {
  return id != null ? companyApi.fetchCompany({ id }) : companyApi.templateCompany();
});
export const fetchCompanyPage = companyFetcher.createFetcher(companyApi.searchCompanies);
export const fetchCompanyHistory = companyFetcher.createFetcher(companyApi.fetchCompanyHistory);
export const fetchCompanyFilmHistory = companyFetcher.createFetcher(companyApi.fetchCompanyFilmHistory);
export const fetchFilmCompanyHistory = companyFetcher.createFetcher(companyApi.fetchFilmCompanyHistory);
export const fetchCompanyComments = companyFetcher.createFetcher(companyApi.fetchCompanyComments);
export const fetchCompanyDuplicates = companyFetcher.createFetcher(companyApi.getPossibleCompanyDuplicates);
export const fetchExistingCompanyDuplicates = companyFetcher.createFetcher(
  companyApi.getExistingPossibleCompanyDuplicates,
);
export const fetchAllCompanyDuplicates = companyFetcher.createFetcher(companyApi.getAllPossibleCompanyDuplicates);

export const relatedCompanyFetcher = createFetcherScope(companyApi);
export const fetchRelatedCompanies = relatedCompanyFetcher.createFetcher(companyApi.fetchRelatedCompaniesForCompany);

export const relatedFilmFetcher = createFetcherScope(companyApi);
export const fetchRelatedFilms = relatedFilmFetcher.createFetcher(companyApi.searchCompanyFilmRelationListItems);
export const fetchRelatedCompaniesForFilm = relatedFilmFetcher.createFetcher(
  companyApi.searchFilmCompanyRelationListItems,
);

export const fetchCompanyStatus = companyFetcher.createFetcher(companyApi.checkCompanyStatus);
