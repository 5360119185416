import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const orderConfirmationMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.orderConfirmation" />,
    path: '/order-confirmations',
    necessaryPermission: 'ORDER_CONFIRMATION:CAN_SEE_LIST_VIEW',
  },
];

export default orderConfirmationMenuItems;
