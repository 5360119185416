import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../../permission-class-group/permission-class-group-extension';

export function useCollectiveOrderConfirmationPermissionClassGroupExtension(): PermissionClassGroupExtension<'COLLECTIVE_ORDER_CONFIRMATION'> {
  const { t } = useTranslation(['order_confirmation']);

  return {
    name: 'COLLECTIVE_ORDER_CONFIRMATION',
    label: t('order_confirmation:collectiveOrderConfirmation.label_one'),
    additionalInformation: t('order_confirmation:collectiveOrderConfirmation.permissionAdditionalInfo'),
    getActionLabel: (action) => t(`order_confirmation:collectiveOrderConfirmation.permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`order_confirmation:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`order_confirmation:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
