import paymentApi from '../../../data-access/payment-api';
import { createFetcherScope } from '../../../util/swr/fetcher-scope';

export const paymentFetcher = createFetcherScope(paymentApi);
export const fetchPayment = paymentFetcher.createFetcher(paymentApi.fetchPayment);
export const fetchPayments = paymentFetcher.createFetcher(paymentApi.searchPayments);
export const fetchOrderConfirmationPayments = paymentFetcher.createFetcher(
  paymentApi.searchPaymentsByOrderConfirmation,
);
export const fetchCollectiveOrderConfirmationPayments = paymentFetcher.createFetcher(
  paymentApi.searchPaymentsByCollectiveOrderConfirmation,
);
