import React from 'react';
import { productIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { NOTIFICATION_TYPE_EXTENSION } from '../notification/notification-type-extension';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import { useProductPermissionClassGroupExtension } from './product-permissions';
import ProductSearchResult from './product-search-result';

export default function ProductPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, { type: 'PRODUCT', Component: ProductSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'PRODUCT',
    icon: productIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.PRODUCT" />,
    necessaryPermission: 'PRODUCT:CAN_SEE_DETAILS_OWN',
  });

  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useProductPermissionClassGroupExtension);
  registry.register(NOTIFICATION_TYPE_EXTENSION, {
    type: 'Product',
    icon: productIcon,
    linkUrl: (entityId) => `products/${entityId}`,
  });
}
