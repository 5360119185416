import {
  Badge,
  Box,
  DarkMode,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  HStack,
  IconButton,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';
import notificationApi from '../../../data-access/notification-api';
import MarkAllAsReadButton from './mark-all-as-read-button';
import NotificationDrawerContent from './notification-drawer-content';
import NotificationSettingsButton from './notification-settings-button';

export default function NotificationCenter() {
  const { t } = useTranslation(['notification']);
  const location = useLocation();
  const { data: unreadNotificationCount } = useSWR(
    ['unreadNotificationCount', location.key],
    async () => await notificationApi.getUnreadNotificationCount(),
    {
      keepPreviousData: true,
    },
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <DarkMode>
        <Tooltip label={t('notification:center.label')}>
          <IconButton
            variant="ghost"
            className="group"
            icon={
              <Box position="relative">
                <FontAwesomeIcon icon={faEnvelope} />
                {unreadNotificationCount! > 0 && (
                  <Badge
                    position="absolute"
                    fontSize="xs"
                    left="1.5"
                    top="-2"
                    variant="solid"
                    border="2px solid"
                    borderRadius="4px"
                    borderColor="gray.900"
                    backgroundColor="text.interactive"
                    color="gray.900"
                  >
                    {unreadNotificationCount! > 99 ? '99+' : unreadNotificationCount}
                  </Badge>
                )}
              </Box>
            }
            aria-label={t('notification:center.label')}
            onClick={onOpen}
          />
        </Tooltip>
      </DarkMode>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader px={8} py={0} minH={18} display="flex" alignItems="center" justifyContent="space-between">
            <Heading as="h2" size="md">
              {t('notification:center.label')}
            </Heading>
            <HStack>
              <MarkAllAsReadButton />
              <NotificationSettingsButton />
            </HStack>
          </DrawerHeader>
          <DrawerBody>
            <NotificationDrawerContent onClose={onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
