import { ParseKeys } from 'i18next';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchLocation } from '../../location/location-queries';

interface HotelEditionDataHelmetProps {
  i18nKey: ParseKeys<'hotel_edition_data'>;
}

export default function HotelEditionDataHelmet({ i18nKey }: HotelEditionDataHelmetProps) {
  const { t } = useTranslation('hotel_edition_data');
  const params = useParams<{ locationId: string }>();
  invariant(params.locationId, 'Empty locationId');
  const location = useFetcher(fetchLocation, { id: params.locationId });

  return (
    <Helmet
      title={t(i18nKey, {
        location: location.name,
        abbreviation: location.abbreviation,
      })}
    />
  );
}
