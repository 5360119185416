import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useHotelBookingPermissionClassGroupExtension(): PermissionClassGroupExtension<'HOTEL_BOOKING'> {
  const { t } = useTranslation(['hotel_booking']);

  return {
    name: 'HOTEL_BOOKING',
    label: t('hotel_booking:hotelBooking'),
    getActionLabel: (action) => t(`hotel_booking:permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`hotel_booking:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`hotel_booking:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
