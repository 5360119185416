/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LocationPostalAddressDto } from './LocationPostalAddressDto';
import {
    LocationPostalAddressDtoFromJSON,
    LocationPostalAddressDtoFromJSONTyped,
    LocationPostalAddressDtoToJSON,
} from './LocationPostalAddressDto';

/**
 * 
 * @export
 * @interface AddressSearchResultDto
 */
export interface AddressSearchResultDto {
    /**
     * 
     * @type {LocationPostalAddressDto}
     * @memberof AddressSearchResultDto
     */
    address: LocationPostalAddressDto;
    /**
     * 
     * @type {string}
     * @memberof AddressSearchResultDto
     */
    locationName: string;
}

/**
 * Check if a given object implements the AddressSearchResultDto interface.
 */
export function instanceOfAddressSearchResultDto(value: object): boolean {
    if (!('address' in value)) return false;
    if (!('locationName' in value)) return false;
    return true;
}

export function AddressSearchResultDtoFromJSON(json: any): AddressSearchResultDto {
    return AddressSearchResultDtoFromJSONTyped(json, false);
}

export function AddressSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'address': LocationPostalAddressDtoFromJSON(json['address']),
        'locationName': json['locationName'],
    };
}

export function AddressSearchResultDtoToJSON(value?: AddressSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': LocationPostalAddressDtoToJSON(value['address']),
        'locationName': value['locationName'],
    };
}

