/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CollectiveOrderConfirmationDto } from './CollectiveOrderConfirmationDto';
import {
    CollectiveOrderConfirmationDtoFromJSON,
    CollectiveOrderConfirmationDtoFromJSONTyped,
    CollectiveOrderConfirmationDtoToJSON,
} from './CollectiveOrderConfirmationDto';

/**
 * 
 * @export
 * @interface CreateCollectiveOrderConfirmationDto
 */
export interface CreateCollectiveOrderConfirmationDto {
    /**
     * 
     * @type {CollectiveOrderConfirmationDto}
     * @memberof CreateCollectiveOrderConfirmationDto
     */
    collectiveOrderConfirmation: CollectiveOrderConfirmationDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCollectiveOrderConfirmationDto
     */
    orderConfirmationIds: Array<string>;
}

/**
 * Check if a given object implements the CreateCollectiveOrderConfirmationDto interface.
 */
export function instanceOfCreateCollectiveOrderConfirmationDto(value: object): boolean {
    if (!('collectiveOrderConfirmation' in value)) return false;
    if (!('orderConfirmationIds' in value)) return false;
    return true;
}

export function CreateCollectiveOrderConfirmationDtoFromJSON(json: any): CreateCollectiveOrderConfirmationDto {
    return CreateCollectiveOrderConfirmationDtoFromJSONTyped(json, false);
}

export function CreateCollectiveOrderConfirmationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCollectiveOrderConfirmationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'collectiveOrderConfirmation': CollectiveOrderConfirmationDtoFromJSON(json['collectiveOrderConfirmation']),
        'orderConfirmationIds': json['orderConfirmationIds'],
    };
}

export function CreateCollectiveOrderConfirmationDtoToJSON(value?: CreateCollectiveOrderConfirmationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'collectiveOrderConfirmation': CollectiveOrderConfirmationDtoToJSON(value['collectiveOrderConfirmation']),
        'orderConfirmationIds': value['orderConfirmationIds'],
    };
}

