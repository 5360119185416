import { listIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { NOTIFICATION_TYPE_EXTENSION } from '../notification/notification-type-extension';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import { useListPermissionClassGroupExtension } from './list-permissions';
import ListSearchResult from './list-search-result';

export default function ListPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, { type: 'LIST', Component: ListSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'LIST',
    icon: listIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.LIST" />,
    necessaryPermission: 'LIST:CAN_SEE_DETAILS_OWN',
  });
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useListPermissionClassGroupExtension);
  registry.register(NOTIFICATION_TYPE_EXTENSION, {
    type: 'List',
    icon: listIcon,
    linkUrl: (entityId) => `lists/${entityId}`,
  });
}
