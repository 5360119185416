/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FieldModelDto
 */
export interface FieldModelDto {
    /**
     * 
     * @type {string}
     * @memberof FieldModelDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FieldModelDto
     */
    translation: string;
    /**
     * 
     * @type {string}
     * @memberof FieldModelDto
     */
    type: FieldModelDtoTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof FieldModelDto
     */
    unique: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldModelDto
     */
    list: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldModelDto
     */
    isInsideList?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof FieldModelDto
     */
    enumTranslations?: { [key: string]: string; };
    /**
     * 
     * @type {Array<FieldModelDto>}
     * @memberof FieldModelDto
     */
    fields: Array<FieldModelDto>;
}


/**
 * @export
 */
export const FieldModelDtoTypeEnum = {
    STRING: 'STRING',
    BOOLEAN: 'BOOLEAN',
    OBJECT: 'OBJECT',
    NUMBER: 'NUMBER',
    UUID: 'UUID',
    DATE_TIME: 'DATE_TIME',
    DATE: 'DATE',
    TIME: 'TIME',
    ENUM: 'ENUM'
} as const;
export type FieldModelDtoTypeEnum = typeof FieldModelDtoTypeEnum[keyof typeof FieldModelDtoTypeEnum];


/**
 * Check if a given object implements the FieldModelDto interface.
 */
export function instanceOfFieldModelDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('translation' in value)) return false;
    if (!('type' in value)) return false;
    if (!('unique' in value)) return false;
    if (!('list' in value)) return false;
    if (!('fields' in value)) return false;
    return true;
}

export function FieldModelDtoFromJSON(json: any): FieldModelDto {
    return FieldModelDtoFromJSONTyped(json, false);
}

export function FieldModelDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldModelDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'translation': json['translation'],
        'type': json['type'],
        'unique': json['unique'],
        'list': json['list'],
        'isInsideList': json['isInsideList'] == null ? undefined : json['isInsideList'],
        'enumTranslations': json['enumTranslations'] == null ? undefined : json['enumTranslations'],
        'fields': ((json['fields'] as Array<any>).map(FieldModelDtoFromJSON)),
    };
}

export function FieldModelDtoToJSON(value?: FieldModelDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'translation': value['translation'],
        'type': value['type'],
        'unique': value['unique'],
        'list': value['list'],
        'isInsideList': value['isInsideList'],
        'enumTranslations': value['enumTranslations'],
        'fields': ((value['fields'] as Array<any>).map(FieldModelDtoToJSON)),
    };
}

