import { Button, ButtonGroup } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHistory, faHomeAlt } from '@fortawesome/pro-regular-svg-icons';
import { sortBy } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, LoaderFunctionArgs, Outlet, useNavigate } from 'react-router-dom';
import Page, { PageHeader, PageTab } from '../../../ui/page';
import usePlugins, { PluginToken } from '../../../util/plugin/use-plugins';
import useFetcher from '../../../util/swr/use-fetcher';
import useParam from '../../../util/use-params/use-params';
import usePermission from '../../permission/use-permission';
import AccessGroupMenu from '../access-group-menu/access-group-menu';
import { fetchAccessGroup } from '../access-group-queries';

export interface AccessGroupTabItem {
  order: number;
  label: React.ReactNode;
  icon: IconProp;
  to: string;
}

export const ACCESS_GROUP_TAB_ITEM = new PluginToken<AccessGroupTabItem>('AccessGroupTabItem');

export const Component = AccessGroupPage;

export const loader = ({ params }: LoaderFunctionArgs) => {
  return fetchAccessGroup.mutate({ id: String(params.accessGroupId) });
};

export function AccessGroupPage() {
  const { t } = useTranslation('access_group');
  const accessGroupId = useParam('accessGroupId');
  const { hasPermission } = usePermission();

  const accessGroup = useFetcher(fetchAccessGroup, { id: accessGroupId });
  const tabs = <AccessGroupPageTabs />;
  const navigate = useNavigate();

  return (
    <Page
      header={
        <PageHeader
          title={accessGroup.title}
          actions={
            <ButtonGroup>
              <AccessGroupMenu accessGroup={accessGroup} afterDelete={() => navigate('/access-groups')} />
              {hasPermission('ACCESS_GROUP:CAN_EDIT') && (
                <Button as={RouterLink} to="edit" variant="primary">
                  {t('action.edit')}
                </Button>
              )}
            </ButtonGroup>
          }
          tabs={tabs}
        />
      }
    >
      <Outlet />
    </Page>
  );
}

function AccessGroupPageTabs() {
  const { t } = useTranslation('common');

  const tabItems = usePlugins(ACCESS_GROUP_TAB_ITEM);
  const sortedTabItems = React.useMemo(() => sortBy(tabItems, 'order'), [tabItems]);

  return (
    <>
      <PageTab to="." icon={faHomeAlt} />
      {sortedTabItems.map((tabItem, index) => (
        <PageTab key={'additionalTab' + index} to={tabItem.to} icon={tabItem.icon}>
          {tabItem.label}
        </PageTab>
      ))}
      <PageTab to="./history" icon={faHistory}>
        {t('history.label')}
      </PageTab>
    </>
  );
}
