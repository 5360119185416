import { Box, Text } from '@chakra-ui/react';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EditionDto, EventEventConnectionDto, EventReferenceDto, EventStatusDto } from '../../../api';
import eventApi from '../../../data-access/event-api';
import Connection from '../../../ui/connection/connection';
import { useElementForm } from '../../../ui/form/element-control';
import ValueAsyncSelectControl from '../../../ui/form/select-control/value-async-select-control';
import { eventIcon } from '../../../ui/icons/business-objects';
import ValueSelect from '../../../ui/select/value-select';
import useActiveEdition from '../../edition/use-active-edition/use-active-edition';

export interface ConnectionControlProps {
  connectionEditable?: boolean;
  toReferenceEditable?: boolean;
  fromReference: EventReferenceDto;
  connectedReferenceIds?: string[];
}

function ConnectionControl(
  { toReferenceEditable = true, fromReference, connectedReferenceIds = [] }: ConnectionControlProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const { t } = useTranslation('event');
  const { element } = useElementForm<EventEventConnectionDto>();
  const { register } = useFormContext<EventEventConnectionDto>();
  const connectedEvent = element?.connectedEvent;

  const activeEdition = useActiveEdition();

  const loadOptions = async (query: string, pageSizeLimit: number) => {
    const filter: string[] = [
      `status,!eq,${EventStatusDto.CANCELLED}`,
      `id,!in,${[fromReference, ...connectedReferenceIds].join(',')}`,
    ];

    const page = await eventApi.searchOwnEventReferences({
      editionId: activeEdition.id,
      searchQuery: query,
      filter,
      pageable: { size: pageSizeLimit },
    });

    return page.content;
  };

  // jaj, cv, lh: inside react-hook-form not registered fields are omitted from the submitted data
  register('connectedEvent');

  return (
    <Connection
      start={{
        icon: <Box as={FontAwesomeIcon} icon={eventIcon} fixedWidth color="text.interactive" />,
        element: (
          <Text as="span" fontWeight="medium" aria-label={t('eventConnections.from_event')}>
            {isEmpty(fromReference.title) ? 'Neue Veranstaltung' : fromReference.title}
          </Text>
        ),
      }}
      connection={
        toReferenceEditable
          ? {
              icon: <Box as={FontAwesomeIcon} icon={faLink} fixedWidth color="text.muted" />,
              element: (
                <ValueSelect<EditionDto>
                  options={[activeEdition]}
                  renderLabel={(value) => value.name}
                  onChange={() => {}}
                  isDisabled
                  value={activeEdition}
                  defaultValue={activeEdition}
                />
              ),
            }
          : undefined
      }
      end={{
        icon: <Box as={FontAwesomeIcon} icon={eventIcon} fixedWidth color="text.interactive" />,
        element: toReferenceEditable ? (
          <ValueAsyncSelectControl<EventReferenceDto>
            aria-label={t('eventConnections.to_event')}
            loadOptions={loadOptions}
            ref={ref}
            optionIdentifier={(event) => event.id}
            defaultOptions={false}
            renderLabel={(event) => event.title}
            name="connectedEvent"
            isRequired
          />
        ) : (
          <Text as="span" fontWeight="medium">
            {connectedEvent?.title ? connectedEvent.title : t('forbiddenEventTitle')}
          </Text>
        ),
      }}
      size="sm"
      ml={-2}
    />
  );
}

export default React.forwardRef(ConnectionControl);
