/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  HistoryPageDto,
  PageableDto,
  SectionDto,
  SectionPageDto,
  SectionReferencePageDto,
  SectionStatusCheckDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    SectionDtoFromJSON,
    SectionDtoToJSON,
    SectionPageDtoFromJSON,
    SectionPageDtoToJSON,
    SectionReferencePageDtoFromJSON,
    SectionReferencePageDtoToJSON,
    SectionStatusCheckDtoFromJSON,
    SectionStatusCheckDtoToJSON,
} from '../models/index';

export interface ActivateSectionRequest {
    id: string;
}

export interface CanAddProgramIdToSectionRequest {
    id: string;
    programId: number;
}

export interface CheckSectionStatusRequest {
    id: string;
}

export interface CreateSectionRequest {
    sectionDto: SectionDto;
}

export interface DeactivateSectionRequest {
    id: string;
}

export interface FetchSectionRequest {
    id: string;
}

export interface FetchSectionHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface SearchSectionReferencesRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchSectionsRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface UpdateSectionRequest {
    id: string;
    sectionDto: SectionDto;
}

/**
 * 
 */
export class SectionApi extends runtime.BaseAPI {

    /**
     * Activate the section
     */
    async activateSectionRaw(requestParameters: ActivateSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling activateSection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sections/{id}/activate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Activate the section
     */
    async activateSection(requestParameters: ActivateSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.activateSectionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async canAddProgramIdToSectionRaw(requestParameters: CanAddProgramIdToSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling canAddProgramIdToSection().'
            );
        }

        if (requestParameters['programId'] == null) {
            throw new runtime.RequiredError(
                'programId',
                'Required parameter "programId" was null or undefined when calling canAddProgramIdToSection().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['programId'] != null) {
            queryParameters['programId'] = requestParameters['programId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sections/{id}/can-add-program-id-to-section`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async canAddProgramIdToSection(requestParameters: CanAddProgramIdToSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.canAddProgramIdToSectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the current status of the section.
     */
    async checkSectionStatusRaw(requestParameters: CheckSectionStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SectionStatusCheckDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling checkSectionStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sections/{id}/check-status`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SectionStatusCheckDtoFromJSON(jsonValue));
    }

    /**
     * Get the current status of the section.
     */
    async checkSectionStatus(requestParameters: CheckSectionStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SectionStatusCheckDto> {
        const response = await this.checkSectionStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new section.
     */
    async createSectionRaw(requestParameters: CreateSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SectionDto>> {
        if (requestParameters['sectionDto'] == null) {
            throw new runtime.RequiredError(
                'sectionDto',
                'Required parameter "sectionDto" was null or undefined when calling createSection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sections`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SectionDtoToJSON(requestParameters['sectionDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SectionDtoFromJSON(jsonValue));
    }

    /**
     * Create a new section.
     */
    async createSection(requestParameters: CreateSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SectionDto> {
        const response = await this.createSectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deactivate the section
     */
    async deactivateSectionRaw(requestParameters: DeactivateSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deactivateSection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sections/{id}/deactivate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deactivate the section
     */
    async deactivateSection(requestParameters: DeactivateSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deactivateSectionRaw(requestParameters, initOverrides);
    }

    /**
     * Get the section business object.
     */
    async fetchSectionRaw(requestParameters: FetchSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SectionDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchSection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sections/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SectionDtoFromJSON(jsonValue));
    }

    /**
     * Get the section business object.
     */
    async fetchSection(requestParameters: FetchSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SectionDto> {
        const response = await this.fetchSectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the section.
     */
    async fetchSectionHistoryRaw(requestParameters: FetchSectionHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchSectionHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sections/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the section.
     */
    async fetchSectionHistory(requestParameters: FetchSectionHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchSectionHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for section references.
     */
    async searchSectionReferencesRaw(requestParameters: SearchSectionReferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SectionReferencePageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sections/references`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SectionReferencePageDtoFromJSON(jsonValue));
    }

    /**
     * Search for section references.
     */
    async searchSectionReferences(requestParameters: SearchSectionReferencesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SectionReferencePageDto> {
        const response = await this.searchSectionReferencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for sections.
     */
    async searchSectionsRaw(requestParameters: SearchSectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SectionPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sections`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SectionPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for sections.
     */
    async searchSections(requestParameters: SearchSectionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SectionPageDto> {
        const response = await this.searchSectionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get template for a new section
     */
    async templateSectionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SectionDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sections/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SectionDtoFromJSON(jsonValue));
    }

    /**
     * Get template for a new section
     */
    async templateSection(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SectionDto> {
        const response = await this.templateSectionRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update the existing section
     */
    async updateSectionRaw(requestParameters: UpdateSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SectionDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateSection().'
            );
        }

        if (requestParameters['sectionDto'] == null) {
            throw new runtime.RequiredError(
                'sectionDto',
                'Required parameter "sectionDto" was null or undefined when calling updateSection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sections/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SectionDtoToJSON(requestParameters['sectionDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SectionDtoFromJSON(jsonValue));
    }

    /**
     * Update the existing section
     */
    async updateSection(requestParameters: UpdateSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SectionDto> {
        const response = await this.updateSectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
