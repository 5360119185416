/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LocationUtilisationDto = {
    CONFERENCE_ROOMS: 'CONFERENCE_ROOMS',
    OFFICE_SPACES: 'OFFICE_SPACES',
    DRIVER_SERVICE: 'DRIVER_SERVICE',
    HOTEL: 'HOTEL',
    INTERVIEW_SPACES: 'INTERVIEW_SPACES',
    LOUNGES: 'LOUNGES',
    MEDIA_CENTRE: 'MEDIA_CENTRE',
    SERVICE_POINT: 'SERVICE_POINT',
    CINEMA: 'CINEMA',
    EVENT_LOCATION: 'EVENT_LOCATION',
    DRIVE_UP: 'DRIVE_UP'
} as const;
export type LocationUtilisationDto = typeof LocationUtilisationDto[keyof typeof LocationUtilisationDto];


export function LocationUtilisationDtoFromJSON(json: any): LocationUtilisationDto {
    return LocationUtilisationDtoFromJSONTyped(json, false);
}

export function LocationUtilisationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationUtilisationDto {
    return json as LocationUtilisationDto;
}

export function LocationUtilisationDtoToJSON(value?: LocationUtilisationDto | null): any {
    return value as any;
}

