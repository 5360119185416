import React from 'react';
import { FieldPath, FieldValues, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CodeDto, CodeDtoPaymentModifierEnum } from '../../../api';
import FormControl from '../../../ui/form/form-control';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';

const options: CodeDtoPaymentModifierEnum[] = ['NONE', 'DISCOUNT', 'PAYMENT_SUBSTITUTE'];

interface DiscountControlProps<T extends FieldValues> {
  path?: FieldPath<T>;
}

export default function PaymentModifierControl<T extends FieldValues>({ path }: DiscountControlProps<T>) {
  const prefixWithPath = <TPath extends string>(name: TPath) => (path != null ? (`${path}.${name}` as TPath) : name);
  const { t } = useTranslation('code');
  const currentValue = useWatch({ name: prefixWithPath('paymentModifier') }) as CodeDtoPaymentModifierEnum | undefined;
  const { setValue } = useFormContext();

  return (
    <FormControl<CodeDto> name={prefixWithPath('paymentModifier')} label={t('paymentModifier')} isRequired>
      <ValueSelectControl<CodeDtoPaymentModifierEnum>
        isRequired
        label={t('paymentModifier')}
        options={options}
        renderLabel={(option) => t(`paymentModifierOptions.${option}`)}
        onChange={(newValue) => {
          if (newValue === currentValue) {
            return;
          }
          if (newValue !== 'DISCOUNT') {
            setValue(prefixWithPath('discount'), undefined);
          }
        }}
        name={prefixWithPath('paymentModifier')}
      />
    </FormControl>
  );
}
