/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { RoleReferenceDto } from './RoleReferenceDto';
import {
    RoleReferenceDtoFromJSON,
    RoleReferenceDtoFromJSONTyped,
    RoleReferenceDtoToJSON,
} from './RoleReferenceDto';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';
import type { StaffSourceDto } from './StaffSourceDto';
import {
    StaffSourceDtoFromJSON,
    StaffSourceDtoFromJSONTyped,
    StaffSourceDtoToJSON,
} from './StaffSourceDto';
import type { StaffStatusDto } from './StaffStatusDto';
import {
    StaffStatusDtoFromJSON,
    StaffStatusDtoFromJSONTyped,
    StaffStatusDtoToJSON,
} from './StaffStatusDto';
import type { StaffTypeDto } from './StaffTypeDto';
import {
    StaffTypeDtoFromJSON,
    StaffTypeDtoFromJSONTyped,
    StaffTypeDtoToJSON,
} from './StaffTypeDto';

/**
 * 
 * @export
 * @interface StaffListItemDto
 */
export interface StaffListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof StaffListItemDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StaffListItemDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof StaffListItemDto
     */
    surname: string;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof StaffListItemDto
     */
    person: PersonReferenceDto;
    /**
     * 
     * @type {Array<SectionReferenceDto>}
     * @memberof StaffListItemDto
     */
    belongsToSections: Array<SectionReferenceDto>;
    /**
     * 
     * @type {Array<RoleReferenceDto>}
     * @memberof StaffListItemDto
     */
    roles: Array<RoleReferenceDto>;
    /**
     * 
     * @type {boolean}
     * @memberof StaffListItemDto
     */
    hasAdminRole: boolean;
    /**
     * 
     * @type {string}
     * @memberof StaffListItemDto
     */
    site?: string;
    /**
     * 
     * @type {string}
     * @memberof StaffListItemDto
     */
    room?: string;
    /**
     * 
     * @type {StaffStatusDto}
     * @memberof StaffListItemDto
     */
    status: StaffStatusDto;
    /**
     * 
     * @type {StaffSourceDto}
     * @memberof StaffListItemDto
     */
    source: StaffSourceDto;
    /**
     * 
     * @type {Array<StaffTypeDto>}
     * @memberof StaffListItemDto
     */
    staffType: Array<StaffTypeDto>;
}

/**
 * Check if a given object implements the StaffListItemDto interface.
 */
export function instanceOfStaffListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('firstName' in value)) return false;
    if (!('surname' in value)) return false;
    if (!('person' in value)) return false;
    if (!('belongsToSections' in value)) return false;
    if (!('roles' in value)) return false;
    if (!('hasAdminRole' in value)) return false;
    if (!('status' in value)) return false;
    if (!('source' in value)) return false;
    if (!('staffType' in value)) return false;
    return true;
}

export function StaffListItemDtoFromJSON(json: any): StaffListItemDto {
    return StaffListItemDtoFromJSONTyped(json, false);
}

export function StaffListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'surname': json['surname'],
        'person': PersonReferenceDtoFromJSON(json['person']),
        'belongsToSections': ((json['belongsToSections'] as Array<any>).map(SectionReferenceDtoFromJSON)),
        'roles': ((json['roles'] as Array<any>).map(RoleReferenceDtoFromJSON)),
        'hasAdminRole': json['hasAdminRole'],
        'site': json['site'] == null ? undefined : json['site'],
        'room': json['room'] == null ? undefined : json['room'],
        'status': StaffStatusDtoFromJSON(json['status']),
        'source': StaffSourceDtoFromJSON(json['source']),
        'staffType': ((json['staffType'] as Array<any>).map(StaffTypeDtoFromJSON)),
    };
}

export function StaffListItemDtoToJSON(value?: StaffListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'firstName': value['firstName'],
        'surname': value['surname'],
        'person': PersonReferenceDtoToJSON(value['person']),
        'belongsToSections': ((value['belongsToSections'] as Array<any>).map(SectionReferenceDtoToJSON)),
        'roles': ((value['roles'] as Array<any>).map(RoleReferenceDtoToJSON)),
        'hasAdminRole': value['hasAdminRole'],
        'site': value['site'],
        'room': value['room'],
        'status': StaffStatusDtoToJSON(value['status']),
        'source': StaffSourceDtoToJSON(value['source']),
        'staffType': ((value['staffType'] as Array<any>).map(StaffTypeDtoToJSON)),
    };
}

