import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonProps,
  IconButton,
  PlacementWithLogical,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import useDefinedContext from '../../../util/context/use-defined-context/use-defined-context';
import ElementContext from './element-context';
import { ElementTableDispatchContext } from './element-table-control';

/**
 * Properties for delete button.
 */
interface DeleteElementButtonProps<TElement> extends Omit<ButtonProps, 'aria-label'> {
  label: string;
  tooltipPlacement?: PlacementWithLogical;
  dialogSize?: 'sm' | 'md' | 'lg' | 'xl';

  renderDeleteMessage(element: TElement): React.ReactNode;

  renderDeleteLabel?(element: TElement): string;

  renderDeleteButtonLabel?(element: TElement): string;
}

/**
 * Button to delete element to a list or table.
 */
export default function DeleteElementButton<TElement>({
  label,
  renderDeleteMessage,
  renderDeleteLabel,
  renderDeleteButtonLabel,
  tooltipPlacement = 'bottom',
  dialogSize = 'md',
  onClick,
  ...props
}: DeleteElementButtonProps<TElement>) {
  const { t } = useTranslation('common');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const dispatch = useDefinedContext(ElementTableDispatchContext);
  const { element, index } = useDefinedContext(ElementContext);
  const [isPending, startTransition] = useTransition();

  const handleDelete = () => {
    startTransition(() => {
      onClose();
      dispatch({ type: 'DELETE_ELEMENT', payload: index });
    });
  };

  return (
    <>
      <Tooltip label={renderDeleteLabel != null ? renderDeleteLabel(element) : label} placement={tooltipPlacement}>
        <IconButton
          colorScheme="red"
          onClick={(event) => {
            onClick?.(event);

            if (!event.defaultPrevented) {
              onOpen();
            }
          }}
          size="sm"
          variant="ghost"
          icon={<FontAwesomeIcon icon={faTrashAlt} />}
          aria-label={renderDeleteLabel != null ? renderDeleteLabel(element) : label}
          {...props}
        />
      </Tooltip>

      <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={closeButtonRef} size={dialogSize}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>{renderDeleteLabel != null ? renderDeleteLabel(element) : label}</AlertDialogHeader>
          <AlertDialogBody>{renderDeleteMessage(element)}</AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onClose} mr={3} ref={closeButtonRef}>
              {t('action.abort')}
            </Button>
            <Button onClick={handleDelete} colorScheme="red" isLoading={isPending}>
              {renderDeleteButtonLabel?.(element) ?? renderDeleteLabel?.(element) ?? t('action.delete')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
