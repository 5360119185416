/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VehicleBookingDto } from './VehicleBookingDto';
import {
    VehicleBookingDtoFromJSON,
    VehicleBookingDtoFromJSONTyped,
    VehicleBookingDtoToJSON,
} from './VehicleBookingDto';

/**
 * 
 * @export
 * @interface VehicleBookingSearchResultDto
 */
export interface VehicleBookingSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleBookingSearchResultDto
     */
    resultType: VehicleBookingSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof VehicleBookingSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {VehicleBookingDto}
     * @memberof VehicleBookingSearchResultDto
     */
    result: VehicleBookingDto;
}


/**
 * @export
 */
export const VehicleBookingSearchResultDtoResultTypeEnum = {
    VEHICLE_BOOKING: 'VEHICLE_BOOKING'
} as const;
export type VehicleBookingSearchResultDtoResultTypeEnum = typeof VehicleBookingSearchResultDtoResultTypeEnum[keyof typeof VehicleBookingSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the VehicleBookingSearchResultDto interface.
 */
export function instanceOfVehicleBookingSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function VehicleBookingSearchResultDtoFromJSON(json: any): VehicleBookingSearchResultDto {
    return VehicleBookingSearchResultDtoFromJSONTyped(json, false);
}

export function VehicleBookingSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleBookingSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': VehicleBookingDtoFromJSON(json['result']),
    };
}

export function VehicleBookingSearchResultDtoToJSON(value?: VehicleBookingSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': VehicleBookingDtoToJSON(value['result']),
    };
}

