/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CollectiveOrderConfirmationStatusDto = {
    NOT_SEND: 'NOT_SEND',
    SEND: 'SEND',
    PAID: 'PAID',
    CANCELLED: 'CANCELLED',
    EXPIRED: 'EXPIRED',
    INVOICE_GENERATED: 'INVOICE_GENERATED'
} as const;
export type CollectiveOrderConfirmationStatusDto = typeof CollectiveOrderConfirmationStatusDto[keyof typeof CollectiveOrderConfirmationStatusDto];


export function CollectiveOrderConfirmationStatusDtoFromJSON(json: any): CollectiveOrderConfirmationStatusDto {
    return CollectiveOrderConfirmationStatusDtoFromJSONTyped(json, false);
}

export function CollectiveOrderConfirmationStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectiveOrderConfirmationStatusDto {
    return json as CollectiveOrderConfirmationStatusDto;
}

export function CollectiveOrderConfirmationStatusDtoToJSON(value?: CollectiveOrderConfirmationStatusDto | null): any {
    return value as any;
}

