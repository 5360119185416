/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FavouredVehicleItemDto
 */
export interface FavouredVehicleItemDto {
    /**
     * 
     * @type {string}
     * @memberof FavouredVehicleItemDto
     */
    vehicleType: string;
    /**
     * 
     * @type {number}
     * @memberof FavouredVehicleItemDto
     */
    quantity: number;
    /**
     * 
     * @type {Date}
     * @memberof FavouredVehicleItemDto
     */
    timestamp: Date;
}

/**
 * Check if a given object implements the FavouredVehicleItemDto interface.
 */
export function instanceOfFavouredVehicleItemDto(value: object): boolean {
    if (!('vehicleType' in value)) return false;
    if (!('quantity' in value)) return false;
    if (!('timestamp' in value)) return false;
    return true;
}

export function FavouredVehicleItemDtoFromJSON(json: any): FavouredVehicleItemDto {
    return FavouredVehicleItemDtoFromJSONTyped(json, false);
}

export function FavouredVehicleItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FavouredVehicleItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'vehicleType': json['vehicleType'],
        'quantity': json['quantity'],
        'timestamp': (new Date(json['timestamp'])),
    };
}

export function FavouredVehicleItemDtoToJSON(value?: FavouredVehicleItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'vehicleType': value['vehicleType'],
        'quantity': value['quantity'],
        'timestamp': ((value['timestamp']).toISOString()),
    };
}

