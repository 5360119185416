/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { GroupReferenceDto } from './GroupReferenceDto';
import {
    GroupReferenceDtoFromJSON,
    GroupReferenceDtoFromJSONTyped,
    GroupReferenceDtoToJSON,
} from './GroupReferenceDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { ReasonForJourneyDto } from './ReasonForJourneyDto';
import {
    ReasonForJourneyDtoFromJSON,
    ReasonForJourneyDtoFromJSONTyped,
    ReasonForJourneyDtoToJSON,
} from './ReasonForJourneyDto';
import type { StaffReferenceDto } from './StaffReferenceDto';
import {
    StaffReferenceDtoFromJSON,
    StaffReferenceDtoFromJSONTyped,
    StaffReferenceDtoToJSON,
} from './StaffReferenceDto';
import type { VehicleBookingStatusDto } from './VehicleBookingStatusDto';
import {
    VehicleBookingStatusDtoFromJSON,
    VehicleBookingStatusDtoFromJSONTyped,
    VehicleBookingStatusDtoToJSON,
} from './VehicleBookingStatusDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * Booking of a vehicle for the transportation of guests or film teams
 * @export
 * @interface VehicleBookingListItemDto
 */
export interface VehicleBookingListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof VehicleBookingListItemDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof VehicleBookingListItemDto
     */
    version?: VersionDto;
    /**
     * The unique key for a vehicle booking
     * @type {string}
     * @memberof VehicleBookingListItemDto
     */
    transactionNumber: string;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof VehicleBookingListItemDto
     */
    edition: EditionReferenceDto;
    /**
     * 
     * @type {Date}
     * @memberof VehicleBookingListItemDto
     */
    pickUpTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof VehicleBookingListItemDto
     */
    durationInHours?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleBookingListItemDto
     */
    numberOfPassengers: number;
    /**
     * 
     * @type {Array<PersonReferenceDto>}
     * @memberof VehicleBookingListItemDto
     */
    personPassengers?: Array<PersonReferenceDto>;
    /**
     * 
     * @type {string}
     * @memberof VehicleBookingListItemDto
     */
    customPassengers?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleBookingListItemDto
     */
    route: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleBookingListItemDto
     */
    comment?: string;
    /**
     * 
     * @type {ReasonForJourneyDto}
     * @memberof VehicleBookingListItemDto
     */
    reasonForJourney: ReasonForJourneyDto;
    /**
     * 
     * @type {Array<GroupReferenceDto>}
     * @memberof VehicleBookingListItemDto
     */
    groups?: Array<GroupReferenceDto>;
    /**
     * 
     * @type {StaffReferenceDto}
     * @memberof VehicleBookingListItemDto
     */
    principal: StaffReferenceDto;
    /**
     * 
     * @type {Array<StaffReferenceDto>}
     * @memberof VehicleBookingListItemDto
     */
    involvedParties?: Array<StaffReferenceDto>;
    /**
     * 
     * @type {StaffReferenceDto}
     * @memberof VehicleBookingListItemDto
     */
    localContact?: StaffReferenceDto;
    /**
     * 
     * @type {VehicleBookingStatusDto}
     * @memberof VehicleBookingListItemDto
     */
    status: VehicleBookingStatusDto;
}

/**
 * Check if a given object implements the VehicleBookingListItemDto interface.
 */
export function instanceOfVehicleBookingListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('transactionNumber' in value)) return false;
    if (!('edition' in value)) return false;
    if (!('numberOfPassengers' in value)) return false;
    if (!('route' in value)) return false;
    if (!('reasonForJourney' in value)) return false;
    if (!('principal' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function VehicleBookingListItemDtoFromJSON(json: any): VehicleBookingListItemDto {
    return VehicleBookingListItemDtoFromJSONTyped(json, false);
}

export function VehicleBookingListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleBookingListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'transactionNumber': json['transactionNumber'],
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'pickUpTime': json['pickUpTime'] == null ? undefined : (new Date(json['pickUpTime'])),
        'durationInHours': json['durationInHours'] == null ? undefined : json['durationInHours'],
        'numberOfPassengers': json['numberOfPassengers'],
        'personPassengers': json['personPassengers'] == null ? undefined : ((json['personPassengers'] as Array<any>).map(PersonReferenceDtoFromJSON)),
        'customPassengers': json['customPassengers'] == null ? undefined : json['customPassengers'],
        'route': json['route'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'reasonForJourney': ReasonForJourneyDtoFromJSON(json['reasonForJourney']),
        'groups': json['groups'] == null ? undefined : ((json['groups'] as Array<any>).map(GroupReferenceDtoFromJSON)),
        'principal': StaffReferenceDtoFromJSON(json['principal']),
        'involvedParties': json['involvedParties'] == null ? undefined : ((json['involvedParties'] as Array<any>).map(StaffReferenceDtoFromJSON)),
        'localContact': json['localContact'] == null ? undefined : StaffReferenceDtoFromJSON(json['localContact']),
        'status': VehicleBookingStatusDtoFromJSON(json['status']),
    };
}

export function VehicleBookingListItemDtoToJSON(value?: VehicleBookingListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'transactionNumber': value['transactionNumber'],
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'pickUpTime': value['pickUpTime'] == null ? undefined : ((value['pickUpTime']).toISOString()),
        'durationInHours': value['durationInHours'],
        'numberOfPassengers': value['numberOfPassengers'],
        'personPassengers': value['personPassengers'] == null ? undefined : ((value['personPassengers'] as Array<any>).map(PersonReferenceDtoToJSON)),
        'customPassengers': value['customPassengers'],
        'route': value['route'],
        'comment': value['comment'],
        'reasonForJourney': ReasonForJourneyDtoToJSON(value['reasonForJourney']),
        'groups': value['groups'] == null ? undefined : ((value['groups'] as Array<any>).map(GroupReferenceDtoToJSON)),
        'principal': StaffReferenceDtoToJSON(value['principal']),
        'involvedParties': value['involvedParties'] == null ? undefined : ((value['involvedParties'] as Array<any>).map(StaffReferenceDtoToJSON)),
        'localContact': StaffReferenceDtoToJSON(value['localContact']),
        'status': VehicleBookingStatusDtoToJSON(value['status']),
    };
}

