import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OrderConfirmationSendRequestDto } from '../../../api';
import { CompanyDto, CompanyDtoStatusEnum } from '../../../api/models/CompanyDto';
import { CompanyReferenceDto } from '../../../api/models/CompanyReferenceDto';
import { PersonDto } from '../../../api/models/PersonDto';
import { PersonReferenceDto } from '../../../api/models/PersonReferenceDto';
import companyApi from '../../../data-access/company-api';
import personApi from '../../../data-access/person-api';
import EmailControl from '../../../ui/form/emails-control/emails-control';
import Form from '../../../ui/form/form';
import MultiValueAsyncSelectFormControl from '../../../ui/form/select-control/multi-value-async-select-form-control';
import SubmitButton from '../../../ui/form/submit-button';
import useToast from '../../../ui/use-toast/use-toast';
import { orderConfirmationFetcher } from '../order-confirmation-queries';

interface OrderConfirmationSendingModalProps {
  number: string;
  isCollective: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSend: (data: OrderConfirmationSendRequestDto) => void;
}

export const OrderConfirmationSendingModal = ({
  number,
  isCollective,
  isOpen,
  onClose,
  onSend,
}: OrderConfirmationSendingModalProps) => {
  const { t } = useTranslation(['common', 'order_confirmation']);

  const showSendSuccessToast = useToast({
    id: 'order-confirmation-send-success-toast',
    status: 'success',
  });

  const handleSendOrderConfirmation = async (data: OrderConfirmationSendingFormData) => {
    onSend({
      emailRecipients: data.emailRecipients,
      personRecipientIds: data.personRecipients.map((person) => person.id),
      companyRecipientIds: data.companyRecipients.map((company) => company.id),
    });

    showSendSuccessToast({
      title: isCollective
        ? t('order_confirmation:collectiveOrderConfirmation.action.send.title')
        : t('order_confirmation:action.send.title'),
      description: isCollective
        ? t('order_confirmation:collectiveOrderConfirmation.action.send.description', {
            collectiveOrderConfirmationNumber: number,
          })
        : t('order_confirmation:action.send.description', {
            orderConfirmationNumber: number,
          }),
    });

    await orderConfirmationFetcher.mutate();

    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" closeOnOverlayClick={false}>
      <ModalOverlay />
      <OrderConfirmationSendingForm
        onSend={handleSendOrderConfirmation}
        onClose={onClose}
        isCollective={isCollective}
      />
    </Modal>
  );
};

interface OrderConfirmationSendingFormProps {
  onSend(data: OrderConfirmationSendingFormData): void;

  isCollective: boolean;

  onClose: () => void;
}

export type OrderConfirmationSendingFormData = {
  personRecipients: PersonReferenceDto[];
  companyRecipients: CompanyReferenceDto[];
  emailRecipients: string[];
};

const OrderConfirmationSendingForm = ({ onSend, onClose, isCollective }: OrderConfirmationSendingFormProps) => {
  const { t } = useTranslation(['common', 'order_confirmation']);
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  const form = useForm<OrderConfirmationSendingFormData>({
    mode: 'all',
    defaultValues: { personRecipients: [], companyRecipients: [], emailRecipients: [] },
  });

  const personRecipients = form.watch('personRecipients');
  const companyRecipients = form.watch('companyRecipients');
  const emailRecipients = form.watch('emailRecipients');

  const validatePersonContactEmails = React.useCallback(
    async (recipients: PersonDto[]) => {
      if (isEmpty(recipients)) {
        return true;
      }

      const invalidRecipients = recipients.filter(
        (recipient) => !recipient.emailAddresses?.some((emailAddress) => emailAddress.label === 'CONTACT'),
      );
      const invalidRecipientsFormatted = invalidRecipients
        .map((recipient) => recipient.firstName + ' ' + recipient.surname)
        .join(', ');

      return (
        isEmpty(invalidRecipients) ||
        t('order_confirmation:action.send.email.errorPersonContact', { persons: invalidRecipientsFormatted })
      );
    },
    [t],
  );

  const validateCompanyContactEmails = React.useCallback(
    async (recipients: CompanyDto[]) => {
      if (isEmpty(recipients)) {
        return true;
      }

      const invalidRecipients = recipients.filter(
        (recipient) => !recipient.emailAddresses?.some((emailAddress) => emailAddress.label === 'CONTACT'),
      );
      const invalidRecipientsFormatted = invalidRecipients.map((recipient) => recipient.name).join(', ');

      return (
        isEmpty(invalidRecipients) ||
        t('order_confirmation:action.send.email.errorCompanyContact', { companies: invalidRecipientsFormatted })
      );
    },
    [t],
  );

  return (
    <ModalContent>
      <FormProvider<OrderConfirmationSendingFormData> {...form}>
        <Form<OrderConfirmationSendingFormData> onValid={onSend} initialFocusRef={initialFocusRef}>
          <ModalHeader>
            {isCollective
              ? t('order_confirmation:collectiveOrderConfirmation.action.send.label')
              : t('order_confirmation:action.send.label')}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={6}>
              <MultiValueAsyncSelectFormControl<PersonDto>
                label={t('order_confirmation:action.send.email.persons')}
                name="personRecipients"
                loadOptions={async (q: string, size: number) => {
                  const page = await personApi.searchPersons({
                    pageable: { size },
                    q,
                  });
                  return page.content;
                }}
                renderLabel={(value) => `${value.firstName} ${value.surname} ${value.personKey}`}
                optionIdentifier={(person) => person.id!}
                rules={{ validate: validatePersonContactEmails }}
                ref={initialFocusRef}
              />
              <MultiValueAsyncSelectFormControl<CompanyReferenceDto>
                name="companyRecipients"
                label={t('order_confirmation:action.send.email.companies')}
                loadOptions={async (q: string, size: number) => {
                  const page = await companyApi.searchCompanies({
                    pageable: { size },
                    filter: [`status,eq,${CompanyDtoStatusEnum.ACTIVE}`],
                    q,
                  });
                  return page.content;
                }}
                renderLabel={(company) => `${company.name}`}
                optionIdentifier={(company) => company.id!}
                rules={{ validate: validateCompanyContactEmails }}
              />
              <EmailControl
                label={t('order_confirmation:action.send.email.addresses')}
                helperText={t('order_confirmation:action.send.email.helperText')}
                name="emailRecipients"
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup spacing={4}>
              <Button onClick={onClose}>{t('common:action.abort')}</Button>
              <SubmitButton
                variant="primary"
                isDisabled={
                  !form.formState.isValid ||
                  (isEmpty(personRecipients) && isEmpty(companyRecipients) && isEmpty(emailRecipients))
                }
              >
                {isCollective
                  ? t('order_confirmation:collectiveOrderConfirmation.action.send.label')
                  : t('order_confirmation:action.send.label')}
              </SubmitButton>
            </ButtonGroup>
          </ModalFooter>
        </Form>
      </FormProvider>
    </ModalContent>
  );
};
