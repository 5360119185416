import { faQrcode } from '@fortawesome/pro-regular-svg-icons';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import EVENT_CHILD_ROUTES from '../event/event-child-routes';
import { EVENT_MENU_ITEM, EventMenuItem } from '../event/event-page/event-page-menu';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { useLiveTicketingPermissionClassGroupExtension } from './live-ticketing-permissions';
import liveTicketingRoutes from './live-ticketing-routes';

export const liveTicketingListEventMenuItem: EventMenuItem = {
  order: 7,
  label: <Translate ns="live_ticketing" i18nKey="live_ticketing" />,
  icon: faQrcode,
  path: (eventId) => `/events/${eventId}/live-ticketing`,
  isVisible: (ownerSectionIds, permissions) =>
    permissions.hasPermission('LIVE_TICKETING:CAN_SEE_DETAILS') ||
    ownerSectionIds.some((sectionId) =>
      permissions.hasPermissionFromSection(sectionId, 'LIVE_TICKETING:CAN_SEE_DETAILS_OWN'),
    ),
};

export default function LiveTicketingPluginProvider(registry: PluginRegistry) {
  registry.register(EVENT_MENU_ITEM, liveTicketingListEventMenuItem);
  registry.register(EVENT_CHILD_ROUTES, liveTicketingRoutes);
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useLiveTicketingPermissionClassGroupExtension);
}
