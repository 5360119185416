/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccountingCreationTypeDto } from './AccountingCreationTypeDto';
import {
    AccountingCreationTypeDtoFromJSON,
    AccountingCreationTypeDtoFromJSONTyped,
    AccountingCreationTypeDtoToJSON,
} from './AccountingCreationTypeDto';
import type { AccountingIssuerDto } from './AccountingIssuerDto';
import {
    AccountingIssuerDtoFromJSON,
    AccountingIssuerDtoFromJSONTyped,
    AccountingIssuerDtoToJSON,
} from './AccountingIssuerDto';
import type { AccountingItemWithOrderConfirmationReferenceViewerDto } from './AccountingItemWithOrderConfirmationReferenceViewerDto';
import {
    AccountingItemWithOrderConfirmationReferenceViewerDtoFromJSON,
    AccountingItemWithOrderConfirmationReferenceViewerDtoFromJSONTyped,
    AccountingItemWithOrderConfirmationReferenceViewerDtoToJSON,
} from './AccountingItemWithOrderConfirmationReferenceViewerDto';
import type { AccountingPaymentStatusDto } from './AccountingPaymentStatusDto';
import {
    AccountingPaymentStatusDtoFromJSON,
    AccountingPaymentStatusDtoFromJSONTyped,
    AccountingPaymentStatusDtoToJSON,
} from './AccountingPaymentStatusDto';
import type { AccountingRecipientDto } from './AccountingRecipientDto';
import {
    AccountingRecipientDtoFromJSON,
    AccountingRecipientDtoFromJSONTyped,
    AccountingRecipientDtoToJSON,
} from './AccountingRecipientDto';
import type { CollectiveOrderConfirmationReferenceDto } from './CollectiveOrderConfirmationReferenceDto';
import {
    CollectiveOrderConfirmationReferenceDtoFromJSON,
    CollectiveOrderConfirmationReferenceDtoFromJSONTyped,
    CollectiveOrderConfirmationReferenceDtoToJSON,
} from './CollectiveOrderConfirmationReferenceDto';
import type { FileMetadataDto } from './FileMetadataDto';
import {
    FileMetadataDtoFromJSON,
    FileMetadataDtoFromJSONTyped,
    FileMetadataDtoToJSON,
} from './FileMetadataDto';
import type { InvoiceReferenceDto } from './InvoiceReferenceDto';
import {
    InvoiceReferenceDtoFromJSON,
    InvoiceReferenceDtoFromJSONTyped,
    InvoiceReferenceDtoToJSON,
} from './InvoiceReferenceDto';
import type { InvoiceStatusDto } from './InvoiceStatusDto';
import {
    InvoiceStatusDtoFromJSON,
    InvoiceStatusDtoFromJSONTyped,
    InvoiceStatusDtoToJSON,
} from './InvoiceStatusDto';
import type { InvoiceTypeDto } from './InvoiceTypeDto';
import {
    InvoiceTypeDtoFromJSON,
    InvoiceTypeDtoFromJSONTyped,
    InvoiceTypeDtoToJSON,
} from './InvoiceTypeDto';
import type { OrderConfirmationReferenceDto } from './OrderConfirmationReferenceDto';
import {
    OrderConfirmationReferenceDtoFromJSON,
    OrderConfirmationReferenceDtoFromJSONTyped,
    OrderConfirmationReferenceDtoToJSON,
} from './OrderConfirmationReferenceDto';
import type { RecipientUpdateApplicationDto } from './RecipientUpdateApplicationDto';
import {
    RecipientUpdateApplicationDtoFromJSON,
    RecipientUpdateApplicationDtoFromJSONTyped,
    RecipientUpdateApplicationDtoToJSON,
} from './RecipientUpdateApplicationDto';
import type { SumTotalDto } from './SumTotalDto';
import {
    SumTotalDtoFromJSON,
    SumTotalDtoFromJSONTyped,
    SumTotalDtoToJSON,
} from './SumTotalDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface InvoiceViewerDto
 */
export interface InvoiceViewerDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof InvoiceViewerDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof InvoiceViewerDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof InvoiceViewerDto
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {AccountingRecipientDto}
     * @memberof InvoiceViewerDto
     */
    recipient: AccountingRecipientDto;
    /**
     * 
     * @type {AccountingIssuerDto}
     * @memberof InvoiceViewerDto
     */
    issuer: AccountingIssuerDto;
    /**
     * 
     * @type {AccountingCreationTypeDto}
     * @memberof InvoiceViewerDto
     */
    creationType: AccountingCreationTypeDto;
    /**
     * 
     * @type {FileMetadataDto}
     * @memberof InvoiceViewerDto
     */
    document?: FileMetadataDto;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceViewerDto
     */
    sentAt?: Date;
    /**
     * 
     * @type {Array<AccountingItemWithOrderConfirmationReferenceViewerDto>}
     * @memberof InvoiceViewerDto
     */
    items: Array<AccountingItemWithOrderConfirmationReferenceViewerDto>;
    /**
     * 
     * @type {OrderConfirmationReferenceDto}
     * @memberof InvoiceViewerDto
     */
    orderConfirmation?: OrderConfirmationReferenceDto;
    /**
     * 
     * @type {CollectiveOrderConfirmationReferenceDto}
     * @memberof InvoiceViewerDto
     */
    collectiveOrderConfirmation?: CollectiveOrderConfirmationReferenceDto;
    /**
     * 
     * @type {InvoiceStatusDto}
     * @memberof InvoiceViewerDto
     */
    status: InvoiceStatusDto;
    /**
     * 
     * @type {InvoiceTypeDto}
     * @memberof InvoiceViewerDto
     */
    type: InvoiceTypeDto;
    /**
     * 
     * @type {AccountingPaymentStatusDto}
     * @memberof InvoiceViewerDto
     */
    paymentStatus: AccountingPaymentStatusDto;
    /**
     * 
     * @type {SumTotalDto}
     * @memberof InvoiceViewerDto
     */
    sumTotal: SumTotalDto;
    /**
     * 
     * @type {string}
     * @memberof InvoiceViewerDto
     */
    invoiceDate: string;
    /**
     * 
     * @type {RecipientUpdateApplicationDto}
     * @memberof InvoiceViewerDto
     */
    recipientUpdateApplication?: RecipientUpdateApplicationDto;
    /**
     * 
     * @type {InvoiceReferenceDto}
     * @memberof InvoiceViewerDto
     */
    originalInvoice?: InvoiceReferenceDto;
    /**
     * 
     * @type {InvoiceReferenceDto}
     * @memberof InvoiceViewerDto
     */
    cancellationInvoice?: InvoiceReferenceDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvoiceViewerDto
     */
    sentToEmails: Array<string>;
}

/**
 * Check if a given object implements the InvoiceViewerDto interface.
 */
export function instanceOfInvoiceViewerDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('recipient' in value)) return false;
    if (!('issuer' in value)) return false;
    if (!('creationType' in value)) return false;
    if (!('items' in value)) return false;
    if (!('status' in value)) return false;
    if (!('type' in value)) return false;
    if (!('paymentStatus' in value)) return false;
    if (!('sumTotal' in value)) return false;
    if (!('invoiceDate' in value)) return false;
    if (!('sentToEmails' in value)) return false;
    return true;
}

export function InvoiceViewerDtoFromJSON(json: any): InvoiceViewerDto {
    return InvoiceViewerDtoFromJSONTyped(json, false);
}

export function InvoiceViewerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceViewerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'invoiceNumber': json['invoiceNumber'] == null ? undefined : json['invoiceNumber'],
        'recipient': AccountingRecipientDtoFromJSON(json['recipient']),
        'issuer': AccountingIssuerDtoFromJSON(json['issuer']),
        'creationType': AccountingCreationTypeDtoFromJSON(json['creationType']),
        'document': json['document'] == null ? undefined : FileMetadataDtoFromJSON(json['document']),
        'sentAt': json['sentAt'] == null ? undefined : (new Date(json['sentAt'])),
        'items': ((json['items'] as Array<any>).map(AccountingItemWithOrderConfirmationReferenceViewerDtoFromJSON)),
        'orderConfirmation': json['orderConfirmation'] == null ? undefined : OrderConfirmationReferenceDtoFromJSON(json['orderConfirmation']),
        'collectiveOrderConfirmation': json['collectiveOrderConfirmation'] == null ? undefined : CollectiveOrderConfirmationReferenceDtoFromJSON(json['collectiveOrderConfirmation']),
        'status': InvoiceStatusDtoFromJSON(json['status']),
        'type': InvoiceTypeDtoFromJSON(json['type']),
        'paymentStatus': AccountingPaymentStatusDtoFromJSON(json['paymentStatus']),
        'sumTotal': SumTotalDtoFromJSON(json['sumTotal']),
        'invoiceDate': json['invoiceDate'],
        'recipientUpdateApplication': json['recipientUpdateApplication'] == null ? undefined : RecipientUpdateApplicationDtoFromJSON(json['recipientUpdateApplication']),
        'originalInvoice': json['originalInvoice'] == null ? undefined : InvoiceReferenceDtoFromJSON(json['originalInvoice']),
        'cancellationInvoice': json['cancellationInvoice'] == null ? undefined : InvoiceReferenceDtoFromJSON(json['cancellationInvoice']),
        'sentToEmails': json['sentToEmails'],
    };
}

export function InvoiceViewerDtoToJSON(value?: InvoiceViewerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'invoiceNumber': value['invoiceNumber'],
        'recipient': AccountingRecipientDtoToJSON(value['recipient']),
        'issuer': AccountingIssuerDtoToJSON(value['issuer']),
        'creationType': AccountingCreationTypeDtoToJSON(value['creationType']),
        'document': FileMetadataDtoToJSON(value['document']),
        'sentAt': value['sentAt'] == null ? undefined : ((value['sentAt']).toISOString()),
        'items': ((value['items'] as Array<any>).map(AccountingItemWithOrderConfirmationReferenceViewerDtoToJSON)),
        'orderConfirmation': OrderConfirmationReferenceDtoToJSON(value['orderConfirmation']),
        'collectiveOrderConfirmation': CollectiveOrderConfirmationReferenceDtoToJSON(value['collectiveOrderConfirmation']),
        'status': InvoiceStatusDtoToJSON(value['status']),
        'type': InvoiceTypeDtoToJSON(value['type']),
        'paymentStatus': AccountingPaymentStatusDtoToJSON(value['paymentStatus']),
        'sumTotal': SumTotalDtoToJSON(value['sumTotal']),
        'invoiceDate': value['invoiceDate'],
        'recipientUpdateApplication': RecipientUpdateApplicationDtoToJSON(value['recipientUpdateApplication']),
        'originalInvoice': InvoiceReferenceDtoToJSON(value['originalInvoice']),
        'cancellationInvoice': InvoiceReferenceDtoToJSON(value['cancellationInvoice']),
        'sentToEmails': value['sentToEmails'],
    };
}

