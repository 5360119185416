/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  NotificationPageDto,
  NotificationSettingsDto,
  PageableDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    NotificationPageDtoFromJSON,
    NotificationPageDtoToJSON,
    NotificationSettingsDtoFromJSON,
    NotificationSettingsDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface ReadNotificationRequest {
    id: string;
    type: string;
}

export interface SearchNotificationsRequest {
    pageable?: PageableDto;
}

export interface UpdateNotificationSettingsRequest {
    notificationSettingsDto: NotificationSettingsDto;
}

/**
 * 
 */
export class NotificationApi extends runtime.BaseAPI {

    /**
     */
    async getNotificationSettingsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationSettingsDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications/settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationSettingsDtoFromJSON(jsonValue));
    }

    /**
     */
    async getNotificationSettings(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationSettingsDto> {
        const response = await this.getNotificationSettingsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Query for unread notification count.
     */
    async getUnreadNotificationCountRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications/unread`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Query for unread notification count.
     */
    async getUnreadNotificationCount(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getUnreadNotificationCountRaw(initOverrides);
        return await response.value();
    }

    /**
     * Set the existing notification to read.
     */
    async readNotificationRaw(requestParameters: ReadNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling readNotification().'
            );
        }

        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling readNotification().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set the existing notification to read.
     */
    async readNotification(requestParameters: ReadNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.readNotificationRaw(requestParameters, initOverrides);
    }

    /**
     * Search for notifications.
     */
    async searchNotificationsRaw(requestParameters: SearchNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for notifications.
     */
    async searchNotifications(requestParameters: SearchNotificationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationPageDto> {
        const response = await this.searchNotificationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Marks all unread notification as read.
     */
    async setAllToReadRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications/unread`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Marks all unread notification as read.
     */
    async setAllToRead(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setAllToReadRaw(initOverrides);
    }

    /**
     */
    async updateNotificationSettingsRaw(requestParameters: UpdateNotificationSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['notificationSettingsDto'] == null) {
            throw new runtime.RequiredError(
                'notificationSettingsDto',
                'Required parameter "notificationSettingsDto" was null or undefined when calling updateNotificationSettings().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications/settings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationSettingsDtoToJSON(requestParameters['notificationSettingsDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateNotificationSettings(requestParameters: UpdateNotificationSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateNotificationSettingsRaw(requestParameters, initOverrides);
    }

}
