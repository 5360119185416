/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Accessibility of the vehicle.
 * @export
 */
export const VehicleAccessibilityDto = {
    NONE: 'NONE',
    EASY_ENTRY: 'EASY_ENTRY',
    WHEELCHAIR: 'WHEELCHAIR'
} as const;
export type VehicleAccessibilityDto = typeof VehicleAccessibilityDto[keyof typeof VehicleAccessibilityDto];


export function VehicleAccessibilityDtoFromJSON(json: any): VehicleAccessibilityDto {
    return VehicleAccessibilityDtoFromJSONTyped(json, false);
}

export function VehicleAccessibilityDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleAccessibilityDto {
    return json as VehicleAccessibilityDto;
}

export function VehicleAccessibilityDtoToJSON(value?: VehicleAccessibilityDto | null): any {
    return value as any;
}

