import React from 'react';
import { vehicleBookingIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import { useVehicleBookingPermissionClassGroupExtension } from './vehicle-booking-permissions';
import VehicleBookingSearchResult from './vehicle-booking-search-result';

export default function VehicleBookingPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, {
    type: 'VEHICLE_BOOKING',
    Component: VehicleBookingSearchResult,
  });
  registry.register(SEARCH_FILTER, {
    key: 'VEHICLE_BOOKING',
    icon: vehicleBookingIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.VEHICLE_BOOKING" />,
    // todo BX-4489 necessaryPermission: 'VEHICLE_BOOKING:CAN_SEE_DETAILS',
  });

  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useVehicleBookingPermissionClassGroupExtension);
}
