import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Spacer,
  Stack,
  useId,
} from '@chakra-ui/react';
import React from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  EditionDto,
  EditionDtoFromJSON,
  EditionImplicationDto,
  EditionTypeDto,
  ImplicationVersionDto,
  ResponseError,
} from '../../../api';
import editionApi from '../../../data-access/edition-api';
import { CustomRequestInit } from '../../../data-access/error-middleware';
import Form from '../../../ui/form/form';
import SubmitButton from '../../../ui/form/submit-button';
import useWatchChange from '../../../ui/form/use-watch-change/use-watch-change';
import useOptimisticLockingDialog from '../../../ui/optimistic-locking/use-optimistic-locking-dialog';
import { ResetButton } from '../../../ui/reset-button/reset-button';
import useUnsavedChangesPrompt from '../../../ui/use-unsaved-changes-prompt/use-unsaved-changes-prompt';
import HistoryBackButton from '../../../util/history/history-back-button';
import useDialog from '../../../util/use-dialog/use-dialog';
import { EditionEditorAction } from '../edition-editor/edition-editor';
import useEditionHistorySettings from '../edition-history/use-edition-history-settings';
import EditionImplicationType from '../edition-implication/edition-implication-type';
import EditionDateRangeControl from './edition-date-range-control';
import EditionFestivalDateRangeControl from './edition-festival-date-range-control';
import EditionImplicationFormDialog from './edition-implication-form-dialog';
import EditionImplicationWarning from './edition-implication-warning';
import EditionNameControl from './edition-name-control';
import EditionTypeControl from './edition-type-control';
import EditionVisibilityControl from './edition-visibility-control';

export interface EditionFormProps {
  edition: DefaultValues<EditionDto>;
  action: EditionEditorAction;
  onEditionSave(edition: EditionDto, implications: ImplicationVersionDto[]): Promise<void>;
}

export default function EditionForm({ edition, onEditionSave, action }: EditionFormProps) {
  const { t } = useTranslation(['common', 'edition']);
  const [isImplicationDialogOpen, onImplicationDialogClose, openImplicationDialog] = useDialog<boolean>();
  const editionHistorySettings = useEditionHistorySettings();
  const form = useForm<EditionDto>({
    mode: 'all',
    defaultValues: edition,
  });
  const { implications, checkImplications, resetImplications, hasConflict } = useEditionImplications();

  useUnsavedChangesPrompt({ formState: form.formState });

  const { catchOptimisticLockingFailure, optimisticLockDialog } = useOptimisticLockingDialog<EditionDto>({
    historyDisplaySettings: editionHistorySettings,
    form,
    mapFunction: EditionDtoFromJSON,
    clearEntity: cleanUpEdition,
  });

  const reset = () => {
    form.reset();
    resetImplications();
  };

  const handleValid = React.useCallback(
    async (edition: EditionDto) => {
      edition = cleanUpEdition(edition);
      const implications = await checkImplications(edition);

      if (implications && (implications.length === 0 || (await openImplicationDialog()))) {
        await catchOptimisticLockingFailure(() =>
          onEditionSave(
            edition,
            implications.map(({ affected }) => ({
              revision: affected.version?.revision,
              affected: affected.id,
            })),
          ),
        );
      }
    },
    [checkImplications, openImplicationDialog, catchOptimisticLockingFailure, onEditionSave],
  );

  useWatchChange<EditionDto>(
    ['editionType', 'dateRange.start', 'dateRange.end'],
    async (edition) => {
      // lh: Manually trigger validation. Validation in react-hook-form is async and in some
      // situations done after watch callbacks like this one is called.
      if (
        edition.editionType === EditionTypeDto.REGULAR &&
        edition.dateRange?.start != null &&
        (await form.trigger(['dateRange.start']))
      ) {
        const template = await editionApi.templateEdition({ startDate: edition.dateRange.start });
        if (template?.name != null) {
          form.setValue('name', template.name);
        }
      }

      resetImplications();
      if (await form.trigger(['editionType', 'dateRange'])) {
        await checkImplications(cleanUpEdition(form.getValues()));
      }
    },
    form,
  );

  const isDirty = action == EditionEditorAction.EDIT ? form.formState.isDirty : true;
  const initialFocusRef = React.useRef<HTMLSelectElement>(null);

  return (
    <FormProvider {...form}>
      <Form<EditionDto> onValid={handleValid} initialFocusRef={initialFocusRef}>
        <Stack spacing={6}>
          <HStack spacing={6} alignItems="flex-start">
            <EditionTypeControl initialFocusRef={initialFocusRef} />
            <EditionDateRangeControl />
          </HStack>

          {hasConflict && <ConflictAlert />}

          {implications != null && (
            <EditionImplicationWarning implications={implications} type={EditionImplicationType.DATE_RANGE} />
          )}

          <EditionNameControl />

          {implications != null && (
            <EditionImplicationWarning implications={implications} type={EditionImplicationType.NAME} />
          )}

          <EditionVisibilityControl />

          <HStack spacing={6} alignItems="flex-start">
            <EditionFestivalDateRangeControl />
          </HStack>
        </Stack>

        <Flex mt={10}>
          <Button as={HistoryBackButton}>{t('common:action.abort')}</Button>
          <Spacer />
          <ButtonGroup spacing={4}>
            <ResetButton reset={reset} isDisabled={!isDirty} />
            <SubmitButton loadingText={t('common:action.proof_and_save')} variant="primary" isDisabled={!isDirty}>
              {t('common:action.proof_and_save')}
            </SubmitButton>
          </ButtonGroup>
        </Flex>

        {optimisticLockDialog}
      </Form>

      {implications != null && (
        <EditionImplicationFormDialog
          implications={implications}
          isOpen={isImplicationDialogOpen}
          onClose={onImplicationDialogClose}
        />
      )}
    </FormProvider>
  );
}

function cleanUpEdition(edition: EditionDto): EditionDto {
  return {
    ...edition,
    name: edition.name?.trim(),
    festivalDateRange:
      edition.festivalDateRange?.start && edition.festivalDateRange?.end ? edition.festivalDateRange : undefined,
  };
}

function useEditionImplications() {
  const [implications, setImplications] = React.useState<EditionImplicationDto[]>();
  const [hasConflict, setHasConflict] = React.useState(false);

  const resetImplications = React.useCallback(() => {
    setImplications([]);
    setHasConflict(false);
  }, []);

  const checkImplications = React.useCallback(async (editionDto: EditionDto) => {
    let implications: EditionImplicationDto[];

    try {
      implications = await editionApi.checkEditionImplications({ editionDto }, {
        allowedErrorCodes: [409],
      } as CustomRequestInit);
    } catch (error) {
      if (error instanceof ResponseError && error.response.status === 409) {
        setHasConflict(true);
        setImplications([]);
      }

      return false;
    }

    setHasConflict(false);
    setImplications(implications);

    return implications;
  }, []);

  return { implications, checkImplications, resetImplications, hasConflict };
}

function ConflictAlert() {
  const { t } = useTranslation(['common', 'edition']);
  const id = useId(undefined, 'conflict-alert');

  return (
    <Alert status="error" aria-labelledby={`${id}-title`}>
      <AlertIcon />
      <AlertTitle id={`${id}-title`} mr={2}>
        {t('common:misc.error')}
      </AlertTitle>
      <AlertDescription>{t('edition:editor.implication.conflict_error')}</AlertDescription>
    </Alert>
  );
}
