import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const driveUpListMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.driveUpList" />,
    path: '/drive-up-list',
    necessaryPermission: 'DRIVE_UP_LIST:CAN_SEE_LIST_VIEW',
  },
];

export default driveUpListMenuItems;
