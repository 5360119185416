/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EventCommentDto,
  EventCommentPageDto,
  EventDto,
  EventListPageDto,
  EventReferencePageDto,
  EventStatusCheckDto,
  EventWrapperDto,
  FailureDto,
  HistoryPageDto,
  PageableDto,
} from '../models/index';
import {
    EventCommentDtoFromJSON,
    EventCommentDtoToJSON,
    EventCommentPageDtoFromJSON,
    EventCommentPageDtoToJSON,
    EventDtoFromJSON,
    EventDtoToJSON,
    EventListPageDtoFromJSON,
    EventListPageDtoToJSON,
    EventReferencePageDtoFromJSON,
    EventReferencePageDtoToJSON,
    EventStatusCheckDtoFromJSON,
    EventStatusCheckDtoToJSON,
    EventWrapperDtoFromJSON,
    EventWrapperDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface CheckEventStatusRequest {
    eventId: string;
}

export interface CreateEventRequest {
    eventWrapperDto: EventWrapperDto;
}

export interface CreateEventCommentRequest {
    eventId: string;
    eventCommentDto: EventCommentDto;
}

export interface DeleteEventRequest {
    id: string;
}

export interface DeleteEventCommentRequest {
    eventId: string;
    commentId: string;
}

export interface FetchEventRequest {
    id: string;
}

export interface FetchEventCommentsRequest {
    eventId: string;
    pageable?: PageableDto;
}

export interface FetchEventHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface SearchEventReferencesRequest {
    editionId: string;
    filter?: Array<string>;
    pageable?: PageableDto;
    searchQuery?: string;
}

export interface SearchEventsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchOwnEventReferencesRequest {
    editionId: string;
    filter?: Array<string>;
    pageable?: PageableDto;
    searchQuery?: string;
}

export interface TemplateEventRequest {
    focusedEditionId: string;
}

export interface UpdateEventRequest {
    id: string;
    eventDto: EventDto;
}

export interface UpdateEventCommentRequest {
    eventId: string;
    commentId: string;
    eventCommentDto: EventCommentDto;
}

/**
 * 
 */
export class EventApi extends runtime.BaseAPI {

    /**
     * Get the current status of the event.
     */
    async checkEventStatusRaw(requestParameters: CheckEventStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventStatusCheckDto>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling checkEventStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events/{eventId}/check-status`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventStatusCheckDtoFromJSON(jsonValue));
    }

    /**
     * Get the current status of the event.
     */
    async checkEventStatus(requestParameters: CheckEventStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventStatusCheckDto> {
        const response = await this.checkEventStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new event.
     */
    async createEventRaw(requestParameters: CreateEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventDto>> {
        if (requestParameters['eventWrapperDto'] == null) {
            throw new runtime.RequiredError(
                'eventWrapperDto',
                'Required parameter "eventWrapperDto" was null or undefined when calling createEvent().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EventWrapperDtoToJSON(requestParameters['eventWrapperDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventDtoFromJSON(jsonValue));
    }

    /**
     * Create a new event.
     */
    async createEvent(requestParameters: CreateEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventDto> {
        const response = await this.createEventRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new comment.
     */
    async createEventCommentRaw(requestParameters: CreateEventCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventCommentDto>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling createEventComment().'
            );
        }

        if (requestParameters['eventCommentDto'] == null) {
            throw new runtime.RequiredError(
                'eventCommentDto',
                'Required parameter "eventCommentDto" was null or undefined when calling createEventComment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events/{eventId}/comments`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EventCommentDtoToJSON(requestParameters['eventCommentDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventCommentDtoFromJSON(jsonValue));
    }

    /**
     * Create a new comment.
     */
    async createEventComment(requestParameters: CreateEventCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventCommentDto> {
        const response = await this.createEventCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the event.
     */
    async deleteEventRaw(requestParameters: DeleteEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteEvent().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the event.
     */
    async deleteEvent(requestParameters: DeleteEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteEventRaw(requestParameters, initOverrides);
    }

    /**
     * Delete the comment.
     */
    async deleteEventCommentRaw(requestParameters: DeleteEventCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling deleteEventComment().'
            );
        }

        if (requestParameters['commentId'] == null) {
            throw new runtime.RequiredError(
                'commentId',
                'Required parameter "commentId" was null or undefined when calling deleteEventComment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events/{eventId}/comments/{commentId}`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))).replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters['commentId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the comment.
     */
    async deleteEventComment(requestParameters: DeleteEventCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteEventCommentRaw(requestParameters, initOverrides);
    }

    /**
     * Get the event business object.
     */
    async fetchEventRaw(requestParameters: FetchEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchEvent().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventDtoFromJSON(jsonValue));
    }

    /**
     * Get the event business object.
     */
    async fetchEvent(requestParameters: FetchEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventDto> {
        const response = await this.fetchEventRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the comments for an event
     */
    async fetchEventCommentsRaw(requestParameters: FetchEventCommentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventCommentPageDto>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling fetchEventComments().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events/{eventId}/comments`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventCommentPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the comments for an event
     */
    async fetchEventComments(requestParameters: FetchEventCommentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventCommentPageDto> {
        const response = await this.fetchEventCommentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the event.
     */
    async fetchEventHistoryRaw(requestParameters: FetchEventHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchEventHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the event.
     */
    async fetchEventHistory(requestParameters: FetchEventHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchEventHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for event references.
     */
    async searchEventReferencesRaw(requestParameters: SearchEventReferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventReferencePageDto>> {
        if (requestParameters['editionId'] == null) {
            throw new runtime.RequiredError(
                'editionId',
                'Required parameter "editionId" was null or undefined when calling searchEventReferences().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['editionId'] != null) {
            queryParameters['editionId'] = requestParameters['editionId'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        if (requestParameters['searchQuery'] != null) {
            queryParameters['searchQuery'] = requestParameters['searchQuery'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events/references`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventReferencePageDtoFromJSON(jsonValue));
    }

    /**
     * Search for event references.
     */
    async searchEventReferences(requestParameters: SearchEventReferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventReferencePageDto> {
        const response = await this.searchEventReferencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for events
     */
    async searchEventsRaw(requestParameters: SearchEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventListPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventListPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for events
     */
    async searchEvents(requestParameters: SearchEventsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventListPageDto> {
        const response = await this.searchEventsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for event references which have the current account\'s section as owner or co-owner. Systemadministrators still see all references
     */
    async searchOwnEventReferencesRaw(requestParameters: SearchOwnEventReferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventReferencePageDto>> {
        if (requestParameters['editionId'] == null) {
            throw new runtime.RequiredError(
                'editionId',
                'Required parameter "editionId" was null or undefined when calling searchOwnEventReferences().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['editionId'] != null) {
            queryParameters['editionId'] = requestParameters['editionId'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        if (requestParameters['searchQuery'] != null) {
            queryParameters['searchQuery'] = requestParameters['searchQuery'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events/own-references`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventReferencePageDtoFromJSON(jsonValue));
    }

    /**
     * Search for event references which have the current account\'s section as owner or co-owner. Systemadministrators still see all references
     */
    async searchOwnEventReferences(requestParameters: SearchOwnEventReferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventReferencePageDto> {
        const response = await this.searchOwnEventReferencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new event.
     */
    async templateEventRaw(requestParameters: TemplateEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventDto>> {
        if (requestParameters['focusedEditionId'] == null) {
            throw new runtime.RequiredError(
                'focusedEditionId',
                'Required parameter "focusedEditionId" was null or undefined when calling templateEvent().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['focusedEditionId'] != null) {
            queryParameters['focusedEditionId'] = requestParameters['focusedEditionId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new event.
     */
    async templateEvent(requestParameters: TemplateEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventDto> {
        const response = await this.templateEventRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the existing event.
     */
    async updateEventRaw(requestParameters: UpdateEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateEvent().'
            );
        }

        if (requestParameters['eventDto'] == null) {
            throw new runtime.RequiredError(
                'eventDto',
                'Required parameter "eventDto" was null or undefined when calling updateEvent().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EventDtoToJSON(requestParameters['eventDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventDtoFromJSON(jsonValue));
    }

    /**
     * Update the existing event.
     */
    async updateEvent(requestParameters: UpdateEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventDto> {
        const response = await this.updateEventRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the existing comment.
     */
    async updateEventCommentRaw(requestParameters: UpdateEventCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventCommentDto>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling updateEventComment().'
            );
        }

        if (requestParameters['commentId'] == null) {
            throw new runtime.RequiredError(
                'commentId',
                'Required parameter "commentId" was null or undefined when calling updateEventComment().'
            );
        }

        if (requestParameters['eventCommentDto'] == null) {
            throw new runtime.RequiredError(
                'eventCommentDto',
                'Required parameter "eventCommentDto" was null or undefined when calling updateEventComment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events/{eventId}/comments/{commentId}`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))).replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters['commentId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EventCommentDtoToJSON(requestParameters['eventCommentDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventCommentDtoFromJSON(jsonValue));
    }

    /**
     * Update the existing comment.
     */
    async updateEventComment(requestParameters: UpdateEventCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventCommentDto> {
        const response = await this.updateEventCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
