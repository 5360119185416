import React from 'react';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import VehicleBreadcrumb from './common/vehicle-breadcrumb';

const vehicleRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'vehicles',
    handle: {
      breadcrumb: <Translate ns="vehicle" i18nKey="vehicles" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./vehicle-lister/vehicle-lister-route'),
      },
      {
        path: 'new',
        lazy: () => import('./vehicle-editor/new-vehicle-route'),
      },
      {
        path: ':vehicleId',
        handle: {
          breadcrumb: <VehicleBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./vehicle-page/vehicle-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./vehicle-viewer/vehicle-viewer-route'),
              },
            ],
          },
          {
            path: 'edit',
            lazy: () => import('./vehicle-editor/edit-vehicle-route'),
          },
        ],
      },
    ],
  },
];

export default vehicleRoutes;
