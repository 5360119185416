import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { EditionDto, ImplicationVersionDto } from '../../../api';
import editionApi from '../../../data-access/edition-api';
import { PageContent, PageHeader, PageSpinner } from '../../../ui/page';
import Page from '../../../ui/page/page';
import useToast from '../../../ui/use-toast/use-toast';
import usePromise from '../../../util/use-promise/use-promise';
import useRedirectTo from '../../../util/use-redirect-to/use-redirect-to';
import EditionForm from '../edition-form/edition-form';

export enum EditionEditorAction {
  NEW = 'new',
  EDIT = 'edit',
}

export interface EditionEditorProps {
  editorAction: EditionEditorAction;
}

export default function EditionEditor({ editorAction }: EditionEditorProps) {
  const { t } = useTranslation('edition');
  const navigate = useNavigate();
  const showSuccessToast = useToast({
    id: 'edition-editor-success-toast',
    status: 'success',
  });
  const redirectTo = useRedirectTo();

  const { editionId } = useParams<{ editionId: string }>();
  const edition = useEditorEdition(editorAction, editionId);

  const handleEditionSave = async (editionDto: EditionDto, implicationVersions: ImplicationVersionDto[]) => {
    showSuccessToast.closeAll();

    let saved: EditionDto;

    switch (editorAction) {
      case EditionEditorAction.NEW:
        saved = await editionApi.createEdition({
          upsertEditionDto: {
            edition: editionDto,
            implications: implicationVersions,
          },
        });
        break;
      case EditionEditorAction.EDIT:
        if (editionId != null) {
          saved = await editionApi.updateEdition({
            id: editionId,
            upsertEditionDto: { edition: editionDto, implications: implicationVersions },
          });
        } else {
          return;
        }
        break;
    }

    showSuccessToast({
      title: t('editor.toast.success.title'),
      description: t('editor.toast.success.description', { edition: saved.name }),
    });

    navigate(redirectTo ?? `/editions/${saved.id}`);
  };

  return (
    <Page header={<PageHeader title={t(`action.${editorAction}`)} />}>
      {edition != null ? (
        <PageContent>
          <EditionForm action={editorAction} edition={edition} onEditionSave={handleEditionSave} />
        </PageContent>
      ) : (
        <PageSpinner />
      )}
    </Page>
  );
}

function useEditorEdition(editorAction: EditionEditorAction, editionId?: string) {
  return usePromise(
    (signal) => {
      switch (editorAction) {
        case EditionEditorAction.NEW:
          return editionApi.templateEdition({}, { signal });
        case EditionEditorAction.EDIT:
          if (editionId != null) {
            return editionApi.fetchEdition({ id: editionId }, { signal });
          }
          break;
      }
    },
    [editorAction, editionId],
  );
}
