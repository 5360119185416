import React from 'react';
import { CollectiveOrderConfirmationViewerDto, OrderConfirmationViewerDto } from '../../../api';
import { OrderConfirmationViewerItemExtension } from '../../order-confirmation/order-confirmation-viewer/order-confirmation-viewer-item-extension';
import InvoiceOrderConfirmationDescriptionItem from './invoice-order-confirmation-description-item';

export const orderConfirmationViewerInvoiceItem: OrderConfirmationViewerItemExtension = {
  item: (orderConfirmation: OrderConfirmationViewerDto | CollectiveOrderConfirmationViewerDto, key: number) => (
    <InvoiceOrderConfirmationDescriptionItem orderConfirmation={orderConfirmation} key={key} />
  ),
};
