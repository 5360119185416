/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HotelBookingPermissionDto
 */
export interface HotelBookingPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof HotelBookingPermissionDto
     */
    name: HotelBookingPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof HotelBookingPermissionDto
     */
    action: HotelBookingPermissionDtoActionEnum;
}


/**
 * @export
 */
export const HotelBookingPermissionDtoNameEnum = {
    HOTEL_BOOKING: 'HOTEL_BOOKING'
} as const;
export type HotelBookingPermissionDtoNameEnum = typeof HotelBookingPermissionDtoNameEnum[keyof typeof HotelBookingPermissionDtoNameEnum];

/**
 * @export
 */
export const HotelBookingPermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    CREATE: 'CAN_CREATE'
} as const;
export type HotelBookingPermissionDtoActionEnum = typeof HotelBookingPermissionDtoActionEnum[keyof typeof HotelBookingPermissionDtoActionEnum];


/**
 * Check if a given object implements the HotelBookingPermissionDto interface.
 */
export function instanceOfHotelBookingPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function HotelBookingPermissionDtoFromJSON(json: any): HotelBookingPermissionDto {
    return HotelBookingPermissionDtoFromJSONTyped(json, false);
}

export function HotelBookingPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelBookingPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function HotelBookingPermissionDtoToJSON(value?: HotelBookingPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

