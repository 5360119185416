/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  PageableDto,
  VisaCreateDto,
  VisaCreationCheckDto,
  VisaDto,
  VisaPageDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    VisaCreateDtoFromJSON,
    VisaCreateDtoToJSON,
    VisaCreationCheckDtoFromJSON,
    VisaCreationCheckDtoToJSON,
    VisaDtoFromJSON,
    VisaDtoToJSON,
    VisaPageDtoFromJSON,
    VisaPageDtoToJSON,
} from '../models/index';

export interface CanCreateVisaRequest {
    applicantId?: string;
}

export interface CreateVisaRequest {
    visaCreateDto: VisaCreateDto;
}

export interface DeleteVisaByIdRequest {
    id: string;
}

export interface FetchVisaRequest {
    id: string;
}

export interface SearchVisasRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

/**
 * 
 */
export class VisaApi extends runtime.BaseAPI {

    /**
     */
    async canCreateVisaRaw(requestParameters: CanCreateVisaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VisaCreationCheckDto>> {
        const queryParameters: any = {};

        if (requestParameters['applicantId'] != null) {
            queryParameters['applicantId'] = requestParameters['applicantId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/visas/can-create-visa`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VisaCreationCheckDtoFromJSON(jsonValue));
    }

    /**
     */
    async canCreateVisa(requestParameters: CanCreateVisaRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VisaCreationCheckDto> {
        const response = await this.canCreateVisaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createAllVisasForCurrentEditionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/visas/create-all-visas-for-current-edition`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createAllVisasForCurrentEdition(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createAllVisasForCurrentEditionRaw(initOverrides);
    }

    /**
     * Create a new visa
     */
    async createVisaRaw(requestParameters: CreateVisaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['visaCreateDto'] == null) {
            throw new runtime.RequiredError(
                'visaCreateDto',
                'Required parameter "visaCreateDto" was null or undefined when calling createVisa().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/visas`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VisaCreateDtoToJSON(requestParameters['visaCreateDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new visa
     */
    async createVisa(requestParameters: CreateVisaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.createVisaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete visa by id
     */
    async deleteVisaByIdRaw(requestParameters: DeleteVisaByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteVisaById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/visas/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete visa by id
     */
    async deleteVisaById(requestParameters: DeleteVisaByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteVisaByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Get the visa business object.
     */
    async fetchVisaRaw(requestParameters: FetchVisaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VisaDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchVisa().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/visas/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VisaDtoFromJSON(jsonValue));
    }

    /**
     * Get the visa business object.
     */
    async fetchVisa(requestParameters: FetchVisaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VisaDto> {
        const response = await this.fetchVisaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async isCurrentlyCreatingVisasRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/visas/is-currently-creating-visas`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async isCurrentlyCreatingVisas(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.isCurrentlyCreatingVisasRaw(initOverrides);
        return await response.value();
    }

    /**
     * Search for visas.
     */
    async searchVisasRaw(requestParameters: SearchVisasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VisaPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/visas`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VisaPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for visas.
     */
    async searchVisas(requestParameters: SearchVisasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VisaPageDto> {
        const response = await this.searchVisasRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
