import React from 'react';
import { vehicleBookingIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import { useVehiclePermissionClassGroupExtension } from './vehicle-permissions';
import VehicleSearchResult from './vehicle-search-result';

export default function VehiclePluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, {
    type: 'VEHICLE',
    Component: VehicleSearchResult,
  });
  registry.register(SEARCH_FILTER, {
    key: 'VEHICLE',
    icon: vehicleBookingIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.VEHICLE" />,
    // todo necessaryPermission: 'VEHICLE:CAN_SEE_DETAILS',
  });

  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useVehiclePermissionClassGroupExtension);
}
