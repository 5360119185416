import { ColorProps } from '@chakra-ui/react';
import { PaymentTransactionStatusDto, PaymentTypeDto } from '../../../api';

export const paymemtTypes = [PaymentTypeDto.PAYMENT, PaymentTypeDto.RETURN];

export const paymentTransactionStatuses = [
  PaymentTransactionStatusDto.UNKNOWN,
  PaymentTransactionStatusDto.FAILED,
  PaymentTransactionStatusDto.SUCCESS,
  PaymentTransactionStatusDto.WAITING,
  PaymentTransactionStatusDto.ABORTED,
];

export const statusDisplay: {
  [key in PaymentTransactionStatusDto]?: {
    bgColor: ColorProps['color'];
  };
} = {
  [PaymentTransactionStatusDto.UNKNOWN]: {
    bgColor: 'background.warning',
  },
  [PaymentTransactionStatusDto.FAILED]: {
    bgColor: 'background.error',
  },
  [PaymentTransactionStatusDto.SUCCESS]: {
    bgColor: 'background.success',
  },
  [PaymentTransactionStatusDto.WAITING]: {
    bgColor: 'background.info',
  },
  [PaymentTransactionStatusDto.ABORTED]: {
    bgColor: 'background.info',
  },
};
