/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { StaffAccountReferenceDto } from './StaffAccountReferenceDto';
import {
    StaffAccountReferenceDtoFromJSON,
    StaffAccountReferenceDtoFromJSONTyped,
    StaffAccountReferenceDtoToJSON,
} from './StaffAccountReferenceDto';

/**
 * A notification.
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof NotificationDto
     */
    createdAt: Date;
    /**
     * 
     * @type {StaffAccountReferenceDto}
     * @memberof NotificationDto
     */
    entityModifiedBy?: StaffAccountReferenceDto;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof NotificationDto
     */
    entityId: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    type: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationDto
     */
    read: boolean;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    title: string;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof NotificationDto
     */
    edition?: EditionReferenceDto;
}

/**
 * Check if a given object implements the NotificationDto interface.
 */
export function instanceOfNotificationDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('entityId' in value)) return false;
    if (!('type' in value)) return false;
    if (!('read' in value)) return false;
    if (!('title' in value)) return false;
    return true;
}

export function NotificationDtoFromJSON(json: any): NotificationDto {
    return NotificationDtoFromJSONTyped(json, false);
}

export function NotificationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'entityModifiedBy': json['entityModifiedBy'] == null ? undefined : StaffAccountReferenceDtoFromJSON(json['entityModifiedBy']),
        'entityId': json['entityId'],
        'type': json['type'],
        'read': json['read'],
        'title': json['title'],
        'edition': json['edition'] == null ? undefined : EditionReferenceDtoFromJSON(json['edition']),
    };
}

export function NotificationDtoToJSON(value?: NotificationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'createdAt': ((value['createdAt']).toISOString()),
        'entityModifiedBy': StaffAccountReferenceDtoToJSON(value['entityModifiedBy']),
        'entityId': value['entityId'],
        'type': value['type'],
        'read': value['read'],
        'title': value['title'],
        'edition': EditionReferenceDtoToJSON(value['edition']),
    };
}

