/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CollectionModelDto,
  EdgeModelDto,
  FailureDto,
  PageableDto,
  ReportDto,
  ReportPageDto,
  ReportReferencePageDto,
} from '../models/index';
import {
    CollectionModelDtoFromJSON,
    CollectionModelDtoToJSON,
    EdgeModelDtoFromJSON,
    EdgeModelDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    ReportDtoFromJSON,
    ReportDtoToJSON,
    ReportPageDtoFromJSON,
    ReportPageDtoToJSON,
    ReportReferencePageDtoFromJSON,
    ReportReferencePageDtoToJSON,
} from '../models/index';

export interface CreateReportRequest {
    reportDto: ReportDto;
}

export interface GetReportRequest {
    id: string;
}

export interface SearchReportListItemsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchReportReferencesRequest {
    searchquery: string;
    pageable?: PageableDto;
}

export interface UpdateReportRequest {
    id: string;
    reportDto: ReportDto;
}

/**
 * 
 */
export class ReportingApi extends runtime.BaseAPI {

    /**
     */
    async createReportRaw(requestParameters: CreateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportDto>> {
        if (requestParameters['reportDto'] == null) {
            throw new runtime.RequiredError(
                'reportDto',
                'Required parameter "reportDto" was null or undefined when calling createReport().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reporting`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportDtoToJSON(requestParameters['reportDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportDtoFromJSON(jsonValue));
    }

    /**
     */
    async createReport(requestParameters: CreateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportDto> {
        const response = await this.createReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCollectionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CollectionModelDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reporting/models/collection`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CollectionModelDtoFromJSON));
    }

    /**
     */
    async getCollections(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CollectionModelDto>> {
        const response = await this.getCollectionsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getEdgesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EdgeModelDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reporting/models/edge`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EdgeModelDtoFromJSON));
    }

    /**
     */
    async getEdges(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EdgeModelDto>> {
        const response = await this.getEdgesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getReportRaw(requestParameters: GetReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getReport().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reporting/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportDtoFromJSON(jsonValue));
    }

    /**
     */
    async getReport(requestParameters: GetReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportDto> {
        const response = await this.getReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchReportListItemsRaw(requestParameters: SearchReportListItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reporting`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async searchReportListItems(requestParameters: SearchReportListItemsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportPageDto> {
        const response = await this.searchReportListItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchReportReferencesRaw(requestParameters: SearchReportReferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportReferencePageDto>> {
        if (requestParameters['searchquery'] == null) {
            throw new runtime.RequiredError(
                'searchquery',
                'Required parameter "searchquery" was null or undefined when calling searchReportReferences().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        if (requestParameters['searchquery'] != null) {
            queryParameters['searchquery'] = requestParameters['searchquery'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reporting/references`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportReferencePageDtoFromJSON(jsonValue));
    }

    /**
     */
    async searchReportReferences(requestParameters: SearchReportReferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportReferencePageDto> {
        const response = await this.searchReportReferencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async templateReportRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reporting/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportDtoFromJSON(jsonValue));
    }

    /**
     */
    async templateReport(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportDto> {
        const response = await this.templateReportRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async updateReportRaw(requestParameters: UpdateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateReport().'
            );
        }

        if (requestParameters['reportDto'] == null) {
            throw new runtime.RequiredError(
                'reportDto',
                'Required parameter "reportDto" was null or undefined when calling updateReport().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reporting/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReportDtoToJSON(requestParameters['reportDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateReport(requestParameters: UpdateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportDto> {
        const response = await this.updateReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
