/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Reference of an domain object. Used to send references without knowing the type of reference.
 * @export
 * @interface AbstractReferenceDto
 */
export interface AbstractReferenceDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof AbstractReferenceDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AbstractReferenceDto
     */
    title: string;
}

/**
 * Check if a given object implements the AbstractReferenceDto interface.
 */
export function instanceOfAbstractReferenceDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('title' in value)) return false;
    return true;
}

export function AbstractReferenceDtoFromJSON(json: any): AbstractReferenceDto {
    return AbstractReferenceDtoFromJSONTyped(json, false);
}

export function AbstractReferenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AbstractReferenceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
    };
}

export function AbstractReferenceDtoToJSON(value?: AbstractReferenceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'title': value['title'],
    };
}

