import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const vehicleMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.vehicle" />,
    path: '/vehicles',
    // neccessaryPermission: 'VEHICLE:CAN_SEE_LIST_VIEW',
  },
];

export default vehicleMenuItems;
