import { useCallback, useRef, useState } from 'react';
import invariant from 'tiny-invariant';
import useCallbackRef from '../use-callback-ref/use-callback-ref';

export function useDialogWithInput<TResult = boolean, TInput = any>() {
  const [dialogIsOpen, internalOnDialogClose, internalOpenDialog] = useDialog<TResult>();
  const [dialogInput, setDialogInput] = useState<TInput>();

  const onDialogClose = useCallback(
    (value: TResult) => {
      setDialogInput(undefined);
      internalOnDialogClose(value);
    },
    [internalOnDialogClose],
  );

  const openDialog = useCallback(
    (input: TInput) => {
      setDialogInput(input);
      return internalOpenDialog();
    },
    [internalOpenDialog],
  );

  return [dialogIsOpen, onDialogClose, openDialog, dialogInput] as const;
}

export default function useDialog<T = boolean>() {
  const [isOpen, setIsOpen] = useState(false);
  const callbackRef = useRef<(value: T | false) => void | Promise<void>>();

  const onClose = useCallbackRef(async (value: T | false) => {
    invariant(callbackRef.current != null, 'Dialog has not been opened yet.');
    await callbackRef.current(value);
    setIsOpen(false);
  });

  const openDialog = useCallbackRef(
    (callback?: (value: T) => void | Promise<void>): Promise<T | false> | (() => void) => {
      if (callback != null) {
        return () => {
          callbackRef.current = (confirm: T | false) => {
            if (confirm !== false) {
              return callback(confirm);
            }
          };
          setIsOpen(true);
        };
      }

      setIsOpen(true);

      return new Promise<T | false>((resolve) => {
        callbackRef.current = resolve;
      });
    },
  ) as {
    (callback: (value: T) => void): () => void;
    /**
     * @deprecated
     */
    (): Promise<T | false>;
  };

  return Object.assign([isOpen, onClose, openDialog] as const, { isOpen, onClose, openDialog });
}
