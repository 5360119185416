import React from 'react';
import { Outlet } from 'react-router-dom';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import LocationPage from '../location/location-page/location-page';
import HotelEditionDataHelmet from './common/hotel-edition-data-helmet';

const locationHotelEditionDataRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: '',
    element: <LocationPage />,
    children: [
      {
        path: 'hotel-edition-data',
        handle: {
          helmet: <HotelEditionDataHelmet i18nKey="lister.helmet" />,
        },
        element: <Outlet />,
        children: [
          {
            path: '',
            lazy: () => import('./hotel-edition-data-lister/location-hotel-edition-data-lister-route'),
          },
        ],
      },
    ],
  },
  {
    path: 'hotel-edition-data/:hotelEditionDataId',
    lazy: () => import('./hotel-edition-data-page/hotel-edition-data-page-route'),
    children: [
      {
        path: '',
        lazy: () => import('./hotel-edition-data-viewer/hotel-edition-data-viewer-route'),
      },
      {
        path: 'history',
        lazy: () => import('./hotel-edition-data-history/hotel-edition-data-history-route'),
      },
    ],
  },
  {
    path: 'hotel-edition-data/new',
    lazy: () => import('./hotel-edition-data-editor/new-hotel-edition-data-route'),
  },
  {
    path: 'hotel-edition-data/:hotelEditionDataId/edit',
    lazy: () => import('./hotel-edition-data-editor/edit-hotel-edition-data-route'),
  },
  {
    path: 'hotel-edition-data/:hotelEditionDataId/copy',
    lazy: () => import('./hotel-edition-data-editor/copy-hotel-edition-data-route'),
  },
];

export default locationHotelEditionDataRoutes;
