import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const accessGroupMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.access-group" />,
    path: '/access-groups',
    necessaryPermission: 'ACCESS_GROUP:CAN_SEE_LIST_VIEW',
  },
];

export default accessGroupMenuItems;
