import React from 'react';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import { companyIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import FILM_CHILD_ROUTES from '../film/film-child-routes';
import { FILM_HISTORY_EXTENSION } from '../film/film-history/film-history-extension';
import FilmPageRoute from '../film/film-page/film-page-route';
import { FILM_TAB_ITEM } from '../film/film-page/film-tab-item';
import { NOTIFICATION_TYPE_EXTENSION } from '../notification/notification-type-extension';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import FilmCompaniesRoute, { FilmCompaniesHelmet } from './company-film/film-companies-route';
import { companyFilmHistoryExtension } from './company-film/history/company-film-history';
import { filmCompanyHistoryExtension } from './company-film/history/film-company-history-extension';
import { useCompanyPermissionClassGroupExtension } from './company-permissions';
import { COMPANY_HISTORY_EXTENSION } from './company-relation/company-history-extension';
import CompanySearchResult from './company-search-result';

export default function CompanyPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, { type: 'COMPANY', Component: CompanySearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'COMPANY',
    icon: companyIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.COMPANY" />,
    necessaryPermission: 'COMPANY:CAN_SEE_DETAILS',
  });

  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useCompanyPermissionClassGroupExtension);
  registry.register(FILM_TAB_ITEM, {
    label: <Translate ns="company" i18nKey="relation.film.companies" />,
    icon: companyIcon,
    to: './companies',
  });
  registry.register(FILM_CHILD_ROUTES, filmCompaniesRoutes);
  registry.register(COMPANY_HISTORY_EXTENSION, companyFilmHistoryExtension);
  registry.register(FILM_HISTORY_EXTENSION, filmCompanyHistoryExtension);
  registry.register(NOTIFICATION_TYPE_EXTENSION, {
    type: 'Company',
    icon: companyIcon,
    linkUrl: (entityId) => `companies/${entityId}`,
  });
}

const filmCompaniesRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: '',
    element: <FilmPageRoute />,
    children: [
      {
        path: 'companies',
        element: <FilmCompaniesRoute />,
        handle: {
          helmet: <FilmCompaniesHelmet />,
        },
      },
    ],
  },
];
