import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { vehicleBookingIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function VehicleSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'VEHICLE', `${result.resultType} is no instance of VehicleSearchResult`);

  const vehicle = result.result;
  const { t } = useTranslation(['common', 'vehicle']);

  return (
    <SearchResult
      icon={vehicleBookingIcon}
      title={vehicle.licensePlate}
      link={`/vehicles/${vehicle.id}`}
      score={result.score}
      onClick={onClick}
      // todo BX-4489 necessaryPermission="VEHICLE_BOOKING:CAN_SEE_DETAILS_OWN"
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('vehicle:edition'),
            element: vehicle.edition.name,
          },
          {
            label: t('vehicle:vehicleNumber'),
            element: vehicle.vehicleNumber,
          },
          {
            label: t('vehicle:licensePlate'),
            element: vehicle.licensePlate,
          },
          {
            label: t('vehicle:vehicleModel'),
            element: vehicle.vehicleModel,
          },
          {
            label: t('vehicle:engine'),
            element: vehicle.engine,
          },
          {
            label: t('vehicle:vehicleType'),
            element: vehicle.vehicleType,
          },
          {
            label: t('vehicle:seat'),
            element: vehicle.seat.toString(),
          },
        ]}
      />
    </SearchResult>
  );
}
