import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import ReportingBreadcrumb from './common/reporting-breadcrumb';

const reportingRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'reporting',
    handle: {
      breadcrumb: <Translate ns="reporting" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./reporting-lister/reporting-lister-route'),
      },
      {
        path: 'new',
        lazy: () => import('./reporting-editor/new-report-route'),
      },
      {
        path: 'help',
        lazy: () => import('./help/help-route'),
        children: [
          {
            path: '',
            lazy: () => import('./help/general-help-route'),
          },
          {
            path: 'edges',
            lazy: () => import('./help/edges-route'),
          },
          {
            path: 'collections',
            lazy: () => import('./help/collections-route'),
          },
        ],
      },
      {
        path: ':reportId',
        handle: {
          breadcrumb: <ReportingBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./reporting-page/reporting-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./reporting-viewer/reporting-viewer-route'),
              },
            ],
          },
          {
            path: 'edit',
            lazy: () => import('./reporting-editor/edit-report-route'),
          },
        ],
      },
    ],
  },
];

export default reportingRoutes;
