import React from 'react';
import Translate from '../../../util/translate/translate';

export default function HotelBookingBreadcrumb() {
  return (
    <>
      <Translate ns="hotel_booking" i18nKey="hotelBooking" />
    </>
  );
}
