import { IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import { faEnvelopeOpen } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import notificationApi from '../../../data-access/notification-api';
import ConfirmDialog from '../../../ui/dialog/confirm-dialog';
import useToast from '../../../ui/use-toast/use-toast';
import { notificationFetcher } from '../notification-queries';

export default function MarkAllAsReadButton() {
  const { t } = useTranslation(['notification', 'common']);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast({ status: 'success' });

  return (
    <>
      <Tooltip label={t('notification:readAll')}>
        <IconButton
          variant="ghost"
          onClick={onOpen}
          icon={<FontAwesomeIcon icon={faEnvelopeOpen} />}
          aria-label={t('notification:readAll')}
        />
      </Tooltip>
      <ConfirmDialog
        isOpen={isOpen}
        onClose={async (confirmed) => {
          if (confirmed) {
            await notificationApi.setAllToRead();
            toast({
              title: t('notification:toast.markAllAsReadSuccess.title'),
            });
            notificationFetcher.mutate();
          }
          onClose();
        }}
        title={t('notification:readAll')}
        submitButtonVariant="primary"
        confirmActionLabel={t('common:action.confirm')}
        isCentered
        size="md"
      >
        {t('notification:readAll')}
      </ConfirmDialog>
    </>
  );
}
