import React from 'react';
import { DescriptionGroup, DescriptionItem } from '../../ui/description';
import { orderIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import GROUP_CHILD_ROUTES from '../group/group-child-routes';
import { GROUP_TAB_ITEM, GroupTabItem } from '../group/group-page/group-page-route';
import { MAILING_MODULE_TYPE_EXTENSION } from '../mailing/mailing-module-type-extension';
import { NOTIFICATION_TYPE_EXTENSION } from '../notification/notification-type-extension';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import PERSON_CHILD_ROUTES from '../person/person-child-routes';
import { PERSON_TAB_ITEM, PersonTabItem } from '../person/person-page/person-page';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import groupOrderRoutes from './group-order-routes';
import { useOrderPermissionClassGroupExtension } from './order-permissions';
import OrderSearchResult from './order-search-result';
import personOrderRoutes from './person-order-routes';

export const personOrderTabItem: PersonTabItem = {
  order: 2,
  label: <Translate ns="order" i18nKey="lister.title" />,
  icon: orderIcon,
  to: './orders',
};

export const groupOrderTabItem: GroupTabItem = {
  order: 1,
  label: <Translate ns="order" i18nKey="lister.title" />,
  icon: orderIcon,
  to: './orders',
  isVisible: (isUserInternalGroupAdmin, hasPermission) =>
    hasPermission('GROUP:CAN_SEE_EXTERNAL_PRODUCT_APPLICATIONS') ||
    (hasPermission('GROUP:CAN_SEE_EXTERNAL_PRODUCT_APPLICATIONS_INTERNAL_GROUP_ADMIN') && isUserInternalGroupAdmin),
};

export default function OrderPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, { type: 'ORDER', Component: OrderSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'ORDER',
    icon: orderIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.ORDER" />,
    necessaryPermission: 'ORDER:CAN_SEE_DETAILS',
  });
  registry.register(MAILING_MODULE_TYPE_EXTENSION, {
    type: 'DYNAMIC_TEXT',
    moduleControl() {
      return (
        <DescriptionGroup>
          <DescriptionItem span={2} label={<Translate i18nKey="dynamic_text_module.label" ns="mailing" />}>
            <Translate i18nKey="dynamic_text_module.active" ns="mailing" />
          </DescriptionItem>
        </DescriptionGroup>
      );
    },
  });
  registry.register(PERSON_TAB_ITEM, personOrderTabItem);
  registry.register(PERSON_CHILD_ROUTES, personOrderRoutes);
  registry.register(GROUP_TAB_ITEM, groupOrderTabItem);
  registry.register(GROUP_CHILD_ROUTES, groupOrderRoutes);
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useOrderPermissionClassGroupExtension);
  registry.register(NOTIFICATION_TYPE_EXTENSION, {
    type: 'Order',
    icon: orderIcon,
    linkUrl: (entityId) => `orders/${entityId}`,
  });
}
