import { Box, Button, ButtonGroup, Menu, MenuItem, MenuList, Portal } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCopy, faHistory, faHomeAlt, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderFunctionArgs, Outlet, useNavigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import standApi from '../../../data-access/stand-api';
import { BurgerMenuButton } from '../../../ui/burger-menu-button/burger-menu-button';
import { DisableableRouterLink } from '../../../ui/disableable-button/disableable-router-link';
import { companyIcon, personIcon } from '../../../ui/icons/business-objects';
import { PageHeader, PageTab } from '../../../ui/page';
import Page from '../../../ui/page/page';
import usePlugins, { PluginToken } from '../../../util/plugin/use-plugins';
import useFetcher from '../../../util/swr/use-fetcher';
import usePermission from '../../permission/use-permission';
import { getStandId } from '../common/get-stand-id';
import StandDeleteButton from '../stand-delete/stand-delete-button';
import { fetchStand } from '../stand-queries';
import CopyStandButton from './copy-stand-button';

export interface StandTabItem {
  order: number;
  label: React.ReactNode;
  icon: IconProp;
  to: string;
}

export const STAND_TAB_ITEM = new PluginToken<StandTabItem>('StandTabItem');

export const Component = StandPageRoute;

export const loader = ({ params }: LoaderFunctionArgs) => {
  return fetchStand.preload({ id: getStandId(params) });
};

export default function StandPageRoute() {
  const { t } = useTranslation(['common', 'stand']);
  const stand = useFetcher(fetchStand, { id: getStandId(useParams()) });
  const tabs = <StandPageTabs />;
  const navigate = useNavigate();
  const { hasPermission } = usePermission();
  const [isDeletable, setDeletable] = React.useState<boolean>(false);

  const checkStatus = async () => {
    setDeletable(false);
    invariant(stand.id != null, 'Missing stand id');
    const isDeletableCheck = await standApi.isStandDeletable({ id: stand.id });
    setDeletable(isDeletableCheck);
  };

  return (
    <Page
      header={
        <PageHeader
          title={stand.name}
          tabs={tabs}
          actions={
            <ButtonGroup>
              <Box>
                {(hasPermission('STAND:CAN_COPY') || hasPermission('STAND:CAN_DELETE')) && (
                  <Menu onOpen={checkStatus}>
                    <BurgerMenuButton />
                    <Portal>
                      <MenuList>
                        {hasPermission('STAND:CAN_COPY') && (
                          <MenuItem
                            as={CopyStandButton}
                            stand={stand}
                            icon={<FontAwesomeIcon icon={faCopy} fixedWidth />}
                          >
                            {t('stand:action.copy')}
                          </MenuItem>
                        )}
                        {hasPermission('STAND:CAN_DELETE') && (
                          <MenuItem
                            as={StandDeleteButton}
                            stand={stand}
                            color="text.error"
                            onSuccess={() => navigate(`/stands`)}
                            deletable={isDeletable}
                            icon={<FontAwesomeIcon icon={faTrashAlt} />}
                          >
                            {t('stand:action.delete')}
                          </MenuItem>
                        )}
                      </MenuList>
                    </Portal>
                  </Menu>
                )}
              </Box>
              {hasPermission('STAND:CAN_EDIT') && (
                <Button as={DisableableRouterLink} to="edit" variant="primary">
                  {t('stand:action.edit')}
                </Button>
              )}
            </ButtonGroup>
          }
        />
      }
    >
      <Outlet />
    </Page>
  );
}

function StandPageTabs() {
  const { t } = useTranslation(['stand', 'common']);
  const tabItems = usePlugins(STAND_TAB_ITEM);
  const sortedTabItems = React.useMemo(() => sortBy(tabItems, 'order'), [tabItems]);

  return (
    <>
      <PageTab to="." icon={faHomeAlt} />
      <PageTab to="./persons" icon={personIcon}>
        {t('stand:persons')}
      </PageTab>
      <PageTab to="./companies" icon={companyIcon}>
        {t('stand:companies')}
      </PageTab>
      {sortedTabItems.map((tabItem, index) => (
        <PageTab key={'additionalTab' + index} to={tabItem.to} icon={tabItem.icon}>
          {tabItem.label}
        </PageTab>
      ))}
      <PageTab to="./history" icon={faHistory}>
        {t('common:history.label')}
      </PageTab>
    </>
  );
}
