/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MailingModuleContentDto } from './MailingModuleContentDto';
import {
    MailingModuleContentDtoFromJSON,
    MailingModuleContentDtoFromJSONTyped,
    MailingModuleContentDtoToJSON,
} from './MailingModuleContentDto';
import type { MailingTemplateDataDto } from './MailingTemplateDataDto';
import {
    MailingTemplateDataDtoFromJSON,
    MailingTemplateDataDtoFromJSONTyped,
    MailingTemplateDataDtoToJSON,
} from './MailingTemplateDataDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * A system mailing for the Berlinale festival.
 * @export
 * @interface SystemMailingDto
 */
export interface SystemMailingDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof SystemMailingDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof SystemMailingDto
     */
    version: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof SystemMailingDto
     */
    type: SystemMailingDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SystemMailingDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SystemMailingDto
     */
    titleEnglish: string;
    /**
     * Subject of the email
     * @type {string}
     * @memberof SystemMailingDto
     */
    subject: string;
    /**
     * Subject of the email in english
     * @type {string}
     * @memberof SystemMailingDto
     */
    subjectEnglish: string;
    /**
     * 
     * @type {Array<MailingModuleContentDto>}
     * @memberof SystemMailingDto
     */
    modules: Array<MailingModuleContentDto>;
    /**
     * 
     * @type {MailingTemplateDataDto}
     * @memberof SystemMailingDto
     */
    template: MailingTemplateDataDto;
    /**
     * 
     * @type {string}
     * @memberof SystemMailingDto
     */
    emailAddressSource: SystemMailingDtoEmailAddressSourceEnum;
}


/**
 * @export
 */
export const SystemMailingDtoTypeEnum = {
    ADD_PERSON_TO_GROUP_APPLICATION_DENIED: 'ADD_PERSON_TO_GROUP_APPLICATION_DENIED',
    BASIC_ACCOUNT_UPGRADE_CONFIRMATION: 'BASIC_ACCOUNT_UPGRADE_CONFIRMATION',
    COMPANY_ADMIN_REQUEST_ACCEPTED: 'COMPANY_ADMIN_REQUEST_ACCEPTED',
    COMPANY_ADMIN_REQUEST_DENIED: 'COMPANY_ADMIN_REQUEST_DENIED',
    EXTERNAL_GROUP_ADMIN_ASSIGNMENT_CONFIRMATION: 'EXTERNAL_GROUP_ADMIN_ASSIGNMENT_CONFIRMATION',
    INVITATION_CONFIRMATION: 'INVITATION_CONFIRMATION',
    INVOICE_REJECT_RECIPIENT_UPDATE_APPLICATION: 'INVOICE_REJECT_RECIPIENT_UPDATE_APPLICATION',
    ORDER_CREATED_CONFIRMATION: 'ORDER_CREATED_CONFIRMATION',
    ORDER_REJECTED_CUSTOM_NOTIFICATION: 'ORDER_REJECTED_CUSTOM_NOTIFICATION',
    ORDER_REJECTED_DEFAULT_NOTIFICATION: 'ORDER_REJECTED_DEFAULT_NOTIFICATION',
    ORDER_WITHDRAWN_CONFIRMATION: 'ORDER_WITHDRAWN_CONFIRMATION',
    PROFESSIONAL_ACCOUNT_DELETION_CONFIRMATION: 'PROFESSIONAL_ACCOUNT_DELETION_CONFIRMATION',
    PROFESSIONAL_ACCOUNT_DOWNGRADE_CONFIRMATION: 'PROFESSIONAL_ACCOUNT_DOWNGRADE_CONFIRMATION',
    PROFESSIONAL_ACCOUNT_MERGE_CONFIRMATION: 'PROFESSIONAL_ACCOUNT_MERGE_CONFIRMATION',
    REMOVE_PERSON_FROM_GROUP_APPLICATION_DENIED: 'REMOVE_PERSON_FROM_GROUP_APPLICATION_DENIED',
    SEND_CANCELLATION_INVOICE: 'SEND_CANCELLATION_INVOICE',
    SEND_CODES: 'SEND_CODES',
    SEND_INVOICE: 'SEND_INVOICE',
    SEND_ORDERCONFIRMATION: 'SEND_ORDERCONFIRMATION',
    SEND_COLLECTIVE_ORDER_CONFIRMATION: 'SEND_COLLECTIVE_ORDER_CONFIRMATION'
} as const;
export type SystemMailingDtoTypeEnum = typeof SystemMailingDtoTypeEnum[keyof typeof SystemMailingDtoTypeEnum];

/**
 * @export
 */
export const SystemMailingDtoEmailAddressSourceEnum = {
    PROFESSIONAL_ACCOUNT: 'PROFESSIONAL_ACCOUNT',
    CONTACT: 'CONTACT'
} as const;
export type SystemMailingDtoEmailAddressSourceEnum = typeof SystemMailingDtoEmailAddressSourceEnum[keyof typeof SystemMailingDtoEmailAddressSourceEnum];


/**
 * Check if a given object implements the SystemMailingDto interface.
 */
export function instanceOfSystemMailingDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('version' in value)) return false;
    if (!('type' in value)) return false;
    if (!('title' in value)) return false;
    if (!('titleEnglish' in value)) return false;
    if (!('subject' in value)) return false;
    if (!('subjectEnglish' in value)) return false;
    if (!('modules' in value)) return false;
    if (!('template' in value)) return false;
    if (!('emailAddressSource' in value)) return false;
    return true;
}

export function SystemMailingDtoFromJSON(json: any): SystemMailingDto {
    return SystemMailingDtoFromJSONTyped(json, false);
}

export function SystemMailingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemMailingDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': VersionDtoFromJSON(json['version']),
        'type': json['type'],
        'title': json['title'],
        'titleEnglish': json['titleEnglish'],
        'subject': json['subject'],
        'subjectEnglish': json['subjectEnglish'],
        'modules': ((json['modules'] as Array<any>).map(MailingModuleContentDtoFromJSON)),
        'template': MailingTemplateDataDtoFromJSON(json['template']),
        'emailAddressSource': json['emailAddressSource'],
    };
}

export function SystemMailingDtoToJSON(value?: SystemMailingDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'type': value['type'],
        'title': value['title'],
        'titleEnglish': value['titleEnglish'],
        'subject': value['subject'],
        'subjectEnglish': value['subjectEnglish'],
        'modules': ((value['modules'] as Array<any>).map(MailingModuleContentDtoToJSON)),
        'template': MailingTemplateDataDtoToJSON(value['template']),
        'emailAddressSource': value['emailAddressSource'],
    };
}

