/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DrivingEditionSettingsPermissionDto
 */
export interface DrivingEditionSettingsPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof DrivingEditionSettingsPermissionDto
     */
    name: DrivingEditionSettingsPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof DrivingEditionSettingsPermissionDto
     */
    action: DrivingEditionSettingsPermissionDtoActionEnum;
}


/**
 * @export
 */
export const DrivingEditionSettingsPermissionDtoNameEnum = {
    DRIVING_EDITION_SETTINGS: 'DRIVING_EDITION_SETTINGS'
} as const;
export type DrivingEditionSettingsPermissionDtoNameEnum = typeof DrivingEditionSettingsPermissionDtoNameEnum[keyof typeof DrivingEditionSettingsPermissionDtoNameEnum];

/**
 * @export
 */
export const DrivingEditionSettingsPermissionDtoActionEnum = {
    CAN_SEE_AND_EDIT: 'CAN_SEE_AND_EDIT'
} as const;
export type DrivingEditionSettingsPermissionDtoActionEnum = typeof DrivingEditionSettingsPermissionDtoActionEnum[keyof typeof DrivingEditionSettingsPermissionDtoActionEnum];


/**
 * Check if a given object implements the DrivingEditionSettingsPermissionDto interface.
 */
export function instanceOfDrivingEditionSettingsPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function DrivingEditionSettingsPermissionDtoFromJSON(json: any): DrivingEditionSettingsPermissionDto {
    return DrivingEditionSettingsPermissionDtoFromJSONTyped(json, false);
}

export function DrivingEditionSettingsPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrivingEditionSettingsPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function DrivingEditionSettingsPermissionDtoToJSON(value?: DrivingEditionSettingsPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

