/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CollectiveOrderConfirmationViewerDto } from './CollectiveOrderConfirmationViewerDto';
import {
    CollectiveOrderConfirmationViewerDtoFromJSON,
    CollectiveOrderConfirmationViewerDtoFromJSONTyped,
    CollectiveOrderConfirmationViewerDtoToJSON,
} from './CollectiveOrderConfirmationViewerDto';

/**
 * 
 * @export
 * @interface CollectiveOrderConfirmationSearchResultDto
 */
export interface CollectiveOrderConfirmationSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof CollectiveOrderConfirmationSearchResultDto
     */
    resultType: CollectiveOrderConfirmationSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CollectiveOrderConfirmationSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {CollectiveOrderConfirmationViewerDto}
     * @memberof CollectiveOrderConfirmationSearchResultDto
     */
    result: CollectiveOrderConfirmationViewerDto;
}


/**
 * @export
 */
export const CollectiveOrderConfirmationSearchResultDtoResultTypeEnum = {
    COLLECTIVE_ORDER_CONFIRMATION: 'COLLECTIVE_ORDER_CONFIRMATION'
} as const;
export type CollectiveOrderConfirmationSearchResultDtoResultTypeEnum = typeof CollectiveOrderConfirmationSearchResultDtoResultTypeEnum[keyof typeof CollectiveOrderConfirmationSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the CollectiveOrderConfirmationSearchResultDto interface.
 */
export function instanceOfCollectiveOrderConfirmationSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function CollectiveOrderConfirmationSearchResultDtoFromJSON(json: any): CollectiveOrderConfirmationSearchResultDto {
    return CollectiveOrderConfirmationSearchResultDtoFromJSONTyped(json, false);
}

export function CollectiveOrderConfirmationSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectiveOrderConfirmationSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': CollectiveOrderConfirmationViewerDtoFromJSON(json['result']),
    };
}

export function CollectiveOrderConfirmationSearchResultDtoToJSON(value?: CollectiveOrderConfirmationSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': CollectiveOrderConfirmationViewerDtoToJSON(value['result']),
    };
}

