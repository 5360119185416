/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface EntryListItemDto
 */
export interface EntryListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof EntryListItemDto
     */
    id: string;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof EntryListItemDto
     */
    person?: PersonReferenceDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof EntryListItemDto
     */
    emails: Array<string>;
    /**
     * 
     * @type {VersionDto}
     * @memberof EntryListItemDto
     */
    version: VersionDto;
}

/**
 * Check if a given object implements the EntryListItemDto interface.
 */
export function instanceOfEntryListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('emails' in value)) return false;
    if (!('version' in value)) return false;
    return true;
}

export function EntryListItemDtoFromJSON(json: any): EntryListItemDto {
    return EntryListItemDtoFromJSONTyped(json, false);
}

export function EntryListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntryListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'person': json['person'] == null ? undefined : PersonReferenceDtoFromJSON(json['person']),
        'emails': json['emails'],
        'version': VersionDtoFromJSON(json['version']),
    };
}

export function EntryListItemDtoToJSON(value?: EntryListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'person': PersonReferenceDtoToJSON(value['person']),
        'emails': value['emails'],
        'version': VersionDtoToJSON(value['version']),
    };
}

