/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MailingSenderDto } from './MailingSenderDto';
import {
    MailingSenderDtoFromJSON,
    MailingSenderDtoFromJSONTyped,
    MailingSenderDtoToJSON,
} from './MailingSenderDto';
import type { MailingTemplateReferenceDto } from './MailingTemplateReferenceDto';
import {
    MailingTemplateReferenceDtoFromJSON,
    MailingTemplateReferenceDtoFromJSONTyped,
    MailingTemplateReferenceDtoToJSON,
} from './MailingTemplateReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface MailingListViewDto
 */
export interface MailingListViewDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof MailingListViewDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MailingListViewDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MailingListViewDto
     */
    status: MailingListViewDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MailingListViewDto
     */
    subject?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof MailingListViewDto
     */
    version: VersionDto;
    /**
     * 
     * @type {MailingSenderDto}
     * @memberof MailingListViewDto
     */
    sender: MailingSenderDto;
    /**
     * 
     * @type {string}
     * @memberof MailingListViewDto
     */
    textModulesContentForFilter?: string;
    /**
     * 
     * @type {any}
     * @memberof MailingListViewDto
     */
    connectedEntity?: any;
    /**
     * 
     * @type {MailingTemplateReferenceDto}
     * @memberof MailingListViewDto
     */
    template: MailingTemplateReferenceDto;
}


/**
 * @export
 */
export const MailingListViewDtoStatusEnum = {
    DRAFT: 'DRAFT',
    MAILING_QUEUE: 'MAILING_QUEUE',
    SENT: 'SENT'
} as const;
export type MailingListViewDtoStatusEnum = typeof MailingListViewDtoStatusEnum[keyof typeof MailingListViewDtoStatusEnum];


/**
 * Check if a given object implements the MailingListViewDto interface.
 */
export function instanceOfMailingListViewDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('status' in value)) return false;
    if (!('version' in value)) return false;
    if (!('sender' in value)) return false;
    if (!('template' in value)) return false;
    return true;
}

export function MailingListViewDtoFromJSON(json: any): MailingListViewDto {
    return MailingListViewDtoFromJSONTyped(json, false);
}

export function MailingListViewDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingListViewDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'status': json['status'],
        'subject': json['subject'] == null ? undefined : json['subject'],
        'version': VersionDtoFromJSON(json['version']),
        'sender': MailingSenderDtoFromJSON(json['sender']),
        'textModulesContentForFilter': json['textModulesContentForFilter'] == null ? undefined : json['textModulesContentForFilter'],
        'connectedEntity': json['connectedEntity'] == null ? undefined : json['connectedEntity'],
        'template': MailingTemplateReferenceDtoFromJSON(json['template']),
    };
}

export function MailingListViewDtoToJSON(value?: MailingListViewDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'status': value['status'],
        'subject': value['subject'],
        'version': VersionDtoToJSON(value['version']),
        'sender': MailingSenderDtoToJSON(value['sender']),
        'textModulesContentForFilter': value['textModulesContentForFilter'],
        'connectedEntity': value['connectedEntity'],
        'template': MailingTemplateReferenceDtoToJSON(value['template']),
    };
}

