/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VehicleDto } from './VehicleDto';
import {
    VehicleDtoFromJSON,
    VehicleDtoFromJSONTyped,
    VehicleDtoToJSON,
} from './VehicleDto';

/**
 * 
 * @export
 * @interface VehicleSearchResultDto
 */
export interface VehicleSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleSearchResultDto
     */
    resultType: VehicleSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof VehicleSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {VehicleDto}
     * @memberof VehicleSearchResultDto
     */
    result: VehicleDto;
}


/**
 * @export
 */
export const VehicleSearchResultDtoResultTypeEnum = {
    VEHICLE: 'VEHICLE'
} as const;
export type VehicleSearchResultDtoResultTypeEnum = typeof VehicleSearchResultDtoResultTypeEnum[keyof typeof VehicleSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the VehicleSearchResultDto interface.
 */
export function instanceOfVehicleSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function VehicleSearchResultDtoFromJSON(json: any): VehicleSearchResultDto {
    return VehicleSearchResultDtoFromJSONTyped(json, false);
}

export function VehicleSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': VehicleDtoFromJSON(json['result']),
    };
}

export function VehicleSearchResultDtoToJSON(value?: VehicleSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': VehicleDtoToJSON(value['result']),
    };
}

