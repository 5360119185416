import { useMemo } from 'react';
import invariant from 'tiny-invariant';
import { EventDto, EventReferenceDto } from '../../../api';

export default function useEventReference(event: EventDto) {
  return useMemo<EventReferenceDto>(() => {
    invariant(event.id != null, 'event id must not be nullish');
    return {
      id: event.id,
      title: event.title,
      englishTitle: event.englishTitle,
      dateTimeRange: event.dateTimeRange,
      status: event.status,
      sectionIdsRequiredToSeeEvent: event.owners.map((owner) => owner.section.id),
    };
  }, [event]);
}
