import { Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { DescriptionItem } from '../../../ui/description';
import EventReference from '../event-reference/event-reference';
import VenueViewer from '../event-viewer/venue-viewer';
import useEvent from '../use-event/use-event';
import useEventReference from '../use-event/use-event-reference';

interface EventMailingInfoAddonProps {
  eventId: string;
}

export function EventMailingInfoAddon({ eventId }: EventMailingInfoAddonProps) {
  const event = useEvent(eventId);
  const { t } = useTranslation(['common', 'event']);
  invariant(event.id != null);

  return (
    <DescriptionItem label={t('event:event')} span={2}>
      <EventReference event={useEventReference(event)} />
      <Text fontSize="sm">{t('common:format.datetime_range', { dateTimeRange: event.dateTimeRange })}</Text>
      <VenueViewer venue={event.venue} size="sm" />
    </DescriptionItem>
  );
}
