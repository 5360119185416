import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageContent, PageHeader } from '../../../ui/page';
import Page from '../../../ui/page/page';
import HelpViewer from './help-viewer';

export default function HelpPage() {
  const { t } = useTranslation('app');

  return (
    <Page header={<PageHeader title={t('help.label')} />}>
      <PageContent>
        <HelpViewer />
      </PageContent>
    </Page>
  );
}
