import vehicleBookingApi from '../../data-access/vehicle-booking-api';
import { createFetcherScope } from '../../util/swr/fetcher-scope';

export const vehicleBookingFetcher = createFetcherScope(vehicleBookingApi);
export const fetchVehicleBooking = vehicleBookingFetcher.createFetcher(vehicleBookingApi.fetchVehicleBooking);
export const searchVehicleBookingsListItems = vehicleBookingFetcher.createFetcher(
  vehicleBookingApi.searchVehicleBookingsListItems,
);
export const fetchVehicleBookingHistory = vehicleBookingFetcher.createFetcher(
  vehicleBookingApi.fetchVehicleBookingHistory,
);
