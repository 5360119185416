/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  CancelInfoInnerDto,
  FailureDto,
  HistoryPageDto,
  ManualPaymentDto,
  OrderConfirmationDto,
  OrderConfirmationPageDto,
  OrderConfirmationReferencePageDto,
  OrderConfirmationSendRequestDto,
  OrderConfirmationViewerDto,
  OrderConfirmationsExportRequestDto,
  PageableDto,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    CancelInfoInnerDtoFromJSON,
    CancelInfoInnerDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    ManualPaymentDtoFromJSON,
    ManualPaymentDtoToJSON,
    OrderConfirmationDtoFromJSON,
    OrderConfirmationDtoToJSON,
    OrderConfirmationPageDtoFromJSON,
    OrderConfirmationPageDtoToJSON,
    OrderConfirmationReferencePageDtoFromJSON,
    OrderConfirmationReferencePageDtoToJSON,
    OrderConfirmationSendRequestDtoFromJSON,
    OrderConfirmationSendRequestDtoToJSON,
    OrderConfirmationViewerDtoFromJSON,
    OrderConfirmationViewerDtoToJSON,
    OrderConfirmationsExportRequestDtoFromJSON,
    OrderConfirmationsExportRequestDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface CreateOrderConfirmationRequest {
    orderConfirmationDto: OrderConfirmationDto;
}

export interface DownloadOrderConfirmationRequest {
    id: string;
}

export interface ExportOrderConfirmationsRequest {
    orderConfirmationsExportRequestDto: OrderConfirmationsExportRequestDto;
    pageable?: PageableDto;
}

export interface FetchOrderConfirmationRequest {
    id: string;
}

export interface FetchOrderConfirmmationHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface GetOrderConfirmationCancelInfoRequest {
    id: string;
}

export interface RecordManualPaymentRequest {
    id: string;
    manualPaymentDto: ManualPaymentDto;
}

export interface SearchOrderConfirmationReferencesRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchOrderConfirmationsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SendOrderConfirmationRequest {
    id: string;
    orderConfirmationSendRequestDto: OrderConfirmationSendRequestDto;
}

export interface UpdateOrderConfirmationRequest {
    id: string;
    orderConfirmationDto: OrderConfirmationDto;
}

export interface UpdateTemplateOrderConfirmationRequest {
    id: string;
}

/**
 * 
 */
export class OrderConfirmationApi extends runtime.BaseAPI {

    /**
     * Create a new order confirmation.
     */
    async createOrderConfirmationRaw(requestParameters: CreateOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['orderConfirmationDto'] == null) {
            throw new runtime.RequiredError(
                'orderConfirmationDto',
                'Required parameter "orderConfirmationDto" was null or undefined when calling createOrderConfirmation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order-confirmations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderConfirmationDtoToJSON(requestParameters['orderConfirmationDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new order confirmation.
     */
    async createOrderConfirmation(requestParameters: CreateOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createOrderConfirmationRaw(requestParameters, initOverrides);
    }

    /**
     * Download the order confirmation.
     */
    async downloadOrderConfirmationRaw(requestParameters: DownloadOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling downloadOrderConfirmation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order-confirmations/{id}/download`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download the order confirmation.
     */
    async downloadOrderConfirmation(requestParameters: DownloadOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadOrderConfirmationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Export filtered order confirmations.
     */
    async exportOrderConfirmationsRaw(requestParameters: ExportOrderConfirmationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['orderConfirmationsExportRequestDto'] == null) {
            throw new runtime.RequiredError(
                'orderConfirmationsExportRequestDto',
                'Required parameter "orderConfirmationsExportRequestDto" was null or undefined when calling exportOrderConfirmations().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order-confirmations/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderConfirmationsExportRequestDtoToJSON(requestParameters['orderConfirmationsExportRequestDto']),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Export filtered order confirmations.
     */
    async exportOrderConfirmations(requestParameters: ExportOrderConfirmationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportOrderConfirmationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the order confirmation.
     */
    async fetchOrderConfirmationRaw(requestParameters: FetchOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderConfirmationViewerDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchOrderConfirmation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order-confirmations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderConfirmationViewerDtoFromJSON(jsonValue));
    }

    /**
     * Get the order confirmation.
     */
    async fetchOrderConfirmation(requestParameters: FetchOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderConfirmationViewerDto> {
        const response = await this.fetchOrderConfirmationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the order confirmation.
     */
    async fetchOrderConfirmmationHistoryRaw(requestParameters: FetchOrderConfirmmationHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchOrderConfirmmationHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order-confirmations/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the order confirmation.
     */
    async fetchOrderConfirmmationHistory(requestParameters: FetchOrderConfirmmationHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchOrderConfirmmationHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets weather the products can be cancelled
     */
    async getOrderConfirmationCancelInfoRaw(requestParameters: GetOrderConfirmationCancelInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CancelInfoInnerDto>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getOrderConfirmationCancelInfo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order-confirmations/{id}/cancel-info`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CancelInfoInnerDtoFromJSON));
    }

    /**
     * Gets weather the products can be cancelled
     */
    async getOrderConfirmationCancelInfo(requestParameters: GetOrderConfirmationCancelInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CancelInfoInnerDto>> {
        const response = await this.getOrderConfirmationCancelInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async recordManualPaymentRaw(requestParameters: RecordManualPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling recordManualPayment().'
            );
        }

        if (requestParameters['manualPaymentDto'] == null) {
            throw new runtime.RequiredError(
                'manualPaymentDto',
                'Required parameter "manualPaymentDto" was null or undefined when calling recordManualPayment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order-confirmations/{id}/record-manual-payment`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ManualPaymentDtoToJSON(requestParameters['manualPaymentDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async recordManualPayment(requestParameters: RecordManualPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.recordManualPaymentRaw(requestParameters, initOverrides);
    }

    /**
     * Search for order confirmations.
     */
    async searchOrderConfirmationReferencesRaw(requestParameters: SearchOrderConfirmationReferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderConfirmationReferencePageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order-confirmations/references`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderConfirmationReferencePageDtoFromJSON(jsonValue));
    }

    /**
     * Search for order confirmations.
     */
    async searchOrderConfirmationReferences(requestParameters: SearchOrderConfirmationReferencesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderConfirmationReferencePageDto> {
        const response = await this.searchOrderConfirmationReferencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for order confirmations.
     */
    async searchOrderConfirmationsRaw(requestParameters: SearchOrderConfirmationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderConfirmationPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order-confirmations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderConfirmationPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for order confirmations.
     */
    async searchOrderConfirmations(requestParameters: SearchOrderConfirmationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderConfirmationPageDto> {
        const response = await this.searchOrderConfirmationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send the order confirmation to given recipients.
     */
    async sendOrderConfirmationRaw(requestParameters: SendOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling sendOrderConfirmation().'
            );
        }

        if (requestParameters['orderConfirmationSendRequestDto'] == null) {
            throw new runtime.RequiredError(
                'orderConfirmationSendRequestDto',
                'Required parameter "orderConfirmationSendRequestDto" was null or undefined when calling sendOrderConfirmation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order-confirmations/{id}/send`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderConfirmationSendRequestDtoToJSON(requestParameters['orderConfirmationSendRequestDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send the order confirmation to given recipients.
     */
    async sendOrderConfirmation(requestParameters: SendOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendOrderConfirmationRaw(requestParameters, initOverrides);
    }

    /**
     * Fetch the template for creating a new order confirmation.
     */
    async templateOrderConfirmationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderConfirmationDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order-confirmations/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderConfirmationDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new order confirmation.
     */
    async templateOrderConfirmation(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderConfirmationDto> {
        const response = await this.templateOrderConfirmationRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update the existing order confirmation.
     */
    async updateOrderConfirmationRaw(requestParameters: UpdateOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateOrderConfirmation().'
            );
        }

        if (requestParameters['orderConfirmationDto'] == null) {
            throw new runtime.RequiredError(
                'orderConfirmationDto',
                'Required parameter "orderConfirmationDto" was null or undefined when calling updateOrderConfirmation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order-confirmations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrderConfirmationDtoToJSON(requestParameters['orderConfirmationDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     * Update the existing order confirmation.
     */
    async updateOrderConfirmation(requestParameters: UpdateOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.updateOrderConfirmationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the update template for creating a copied confirmation.
     */
    async updateTemplateOrderConfirmationRaw(requestParameters: UpdateTemplateOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderConfirmationDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateTemplateOrderConfirmation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order-confirmations/{id}/updateTemplate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderConfirmationDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the update template for creating a copied confirmation.
     */
    async updateTemplateOrderConfirmation(requestParameters: UpdateTemplateOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderConfirmationDto> {
        const response = await this.updateTemplateOrderConfirmationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
