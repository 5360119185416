import React from 'react';
import { DEBOUNCE_TIME } from '../constants';
import useDebounce from './use-debounce';

export default function useDebouncedState<T>(
  value: T,
  setValue: (value: T) => void,
  wait: number = DEBOUNCE_TIME,
): [T, (value: T) => void] {
  const [state, setState] = React.useState(value);
  const debounceState = useDebounce(state, wait);
  const valueRef = React.useRef<T>();
  valueRef.current = value;
  const setValueRef = React.useRef<(value: T) => void>();
  setValueRef.current = setValue;

  React.useEffect(() => {
    setState(value);
  }, [value]);

  React.useEffect(() => {
    if (valueRef.current !== debounceState) {
      setValueRef.current!(debounceState);
    }
  }, [debounceState]);

  return [state, setState];
}
