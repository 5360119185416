/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosedDateRangeDto } from './ClosedDateRangeDto';
import {
    ClosedDateRangeDtoFromJSON,
    ClosedDateRangeDtoFromJSONTyped,
    ClosedDateRangeDtoToJSON,
} from './ClosedDateRangeDto';

/**
 * 
 * @export
 * @interface WorkingPeriodsLimitationDto
 */
export interface WorkingPeriodsLimitationDto {
    /**
     * 
     * @type {ClosedDateRangeDto}
     * @memberof WorkingPeriodsLimitationDto
     */
    limitation?: ClosedDateRangeDto;
}

/**
 * Check if a given object implements the WorkingPeriodsLimitationDto interface.
 */
export function instanceOfWorkingPeriodsLimitationDto(value: object): boolean {
    return true;
}

export function WorkingPeriodsLimitationDtoFromJSON(json: any): WorkingPeriodsLimitationDto {
    return WorkingPeriodsLimitationDtoFromJSONTyped(json, false);
}

export function WorkingPeriodsLimitationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkingPeriodsLimitationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'limitation': json['limitation'] == null ? undefined : ClosedDateRangeDtoFromJSON(json['limitation']),
    };
}

export function WorkingPeriodsLimitationDtoToJSON(value?: WorkingPeriodsLimitationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'limitation': ClosedDateRangeDtoToJSON(value['limitation']),
    };
}

