import { Grid, GridProps } from '@chakra-ui/react';
import { ReactNode, Suspense } from 'react';
import PageSpinner from './page-spinner';

interface GridPageProps extends GridProps {
  children: ReactNode;
  header: ReactNode;
}

/**
 * Page Layout based on a CSS Grid. Defaults to two rows spanning the entire height.
 * (Header and scrollable Content)
 */
export default function Page({
  children,
  header,
  gridTemplateRows = 'auto 1fr',
  height = '100vh',
  ...props
}: GridPageProps) {
  return (
    <Grid gridTemplateRows={gridTemplateRows} height={height} {...props}>
      {header}
      <Suspense fallback={<PageSpinner />}>{children}</Suspense>
    </Grid>
  );
}
