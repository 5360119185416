/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const StaffTypeDto = {
    DRIVER: 'DRIVER',
    DRIVING_SCHEDULER: 'DRIVING_SCHEDULER',
    ADMISSION: 'ADMISSION',
    CONSTRUCTION: 'CONSTRUCTION',
    TECHNIC: 'TECHNIC',
    SUPPLIER: 'SUPPLIER',
    SECURITY: 'SECURITY',
    TRAFFIC_CONTROL: 'TRAFFIC_CONTROL',
    OTHER: 'OTHER'
} as const;
export type StaffTypeDto = typeof StaffTypeDto[keyof typeof StaffTypeDto];


export function StaffTypeDtoFromJSON(json: any): StaffTypeDto {
    return StaffTypeDtoFromJSONTyped(json, false);
}

export function StaffTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffTypeDto {
    return json as StaffTypeDto;
}

export function StaffTypeDtoToJSON(value?: StaffTypeDto | null): any {
    return value as any;
}

