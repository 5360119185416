/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DrivingAssignmentReferenceDto
 */
export interface DrivingAssignmentReferenceDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof DrivingAssignmentReferenceDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DrivingAssignmentReferenceDto
     */
    drivingAssignmentNumber?: string;
}

/**
 * Check if a given object implements the DrivingAssignmentReferenceDto interface.
 */
export function instanceOfDrivingAssignmentReferenceDto(value: object): boolean {
    if (!('id' in value)) return false;
    return true;
}

export function DrivingAssignmentReferenceDtoFromJSON(json: any): DrivingAssignmentReferenceDto {
    return DrivingAssignmentReferenceDtoFromJSONTyped(json, false);
}

export function DrivingAssignmentReferenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrivingAssignmentReferenceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'drivingAssignmentNumber': json['drivingAssignmentNumber'] == null ? undefined : json['drivingAssignmentNumber'],
    };
}

export function DrivingAssignmentReferenceDtoToJSON(value?: DrivingAssignmentReferenceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'drivingAssignmentNumber': value['drivingAssignmentNumber'],
    };
}

