import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const filmMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.film" />,
    path: '/films',
    necessaryPermission: 'FILM:CAN_SEE_LIST_VIEW',
  },
];

export default filmMenuItems;
