import React from 'react';
import { OfferReferenceDto } from '../../../api';
import MailingTypeExtension from '../../mailing/mailing-type-extension';
import OfferReference from '../offer-reference/offer-reference';
import { OfferMailingConnectionSelect } from './offer-mailing-connection-select';
import { OfferMailingInfoAddon } from './offer-mailing-info-addon';
import OfferMailingRecipientEditor from './offer-mailing-recipient-editor';
import OfferMailingRecipientLister from './offer-mailing-recipient-lister';

export function createOfferMailingExtension(): MailingTypeExtension {
  return {
    type: 'OFFER',
    recipientEditorAddon(props: { connectionId?: string; mailingId: string }): React.ReactNode {
      return <OfferMailingRecipientEditor {...props} />;
    },
    recipientListerAddon(props: { connectionId?: string; mailingId: string }): React.ReactNode {
      return <OfferMailingRecipientLister {...props} />;
    },
    connectionSelect: <OfferMailingConnectionSelect />,
    reference: (offer: OfferReferenceDto) => <OfferReference offer={offer} />,
    infoAddon: (offerId: string) => <OfferMailingInfoAddon offerId={offerId} />,
  };
}
