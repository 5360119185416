import { Box, Button, ButtonGroup, GridItem } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHistory, faHomeAlt } from '@fortawesome/pro-regular-svg-icons';
import { sortBy } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderFunctionArgs, Outlet, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { ProductStatusDto } from '../../../api';
import { DisableableRouterLink } from '../../../ui/disableable-button/disableable-router-link';
import Page, { PageHeader, PageTab } from '../../../ui/page';
import usePlugins, { PluginToken } from '../../../util/plugin/use-plugins';
import useFetcher from '../../../util/swr/use-fetcher';
import EditionHeader from '../../edition/focused-edition-header/edition-header';
import SubscribeButton from '../../notification/subscribe-button/subscribe-button';
import usePermission from '../../permission/use-permission';
import { ProductMenuList } from '../product-menu-list/product-menu-list';
import { fetchProduct } from '../product-queries';

export interface ProductTabItem {
  order: number;
  label: React.ReactNode;
  icon: IconProp;
  to: string;
}

export const PRODUCT_TAB_ITEM = new PluginToken<ProductTabItem>('ProductTabItem');

export const Component = ProductPageRoute;

export const loader = ({ params }: LoaderFunctionArgs) => {
  return fetchProduct.mutate({ id: String(params.productId) });
};

export default function ProductPageRoute() {
  const { t } = useTranslation(['common', 'product']);
  const { productId } = useParams<{ productId: string }>();
  const { hasPermission, hasPermissionFromSection } = usePermission();

  invariant(productId, 'Empty productId');
  const product = useFetcher(fetchProduct, { id: productId });
  const tabs = <ProductPageTabs />;

  const isArchived = product.status === ProductStatusDto.ARCHIVED;
  const ownerSectionIds = product.owners.map((owner) => owner.section.id);
  const canEdit =
    hasPermission('PRODUCT:CAN_EDIT') ||
    ownerSectionIds.some((sectionId) => hasPermissionFromSection(sectionId, 'PRODUCT:CAN_EDIT_OWN'));

  return (
    <Page
      header={
        <GridItem>
          <EditionHeader editionId={product.edition.id} navigationTarget="products" />
          <PageHeader
            title={product.title}
            tabs={tabs}
            actions={
              <ButtonGroup>
                <Box>
                  <ProductMenuList product={product} belongsToSections={ownerSectionIds} size="md" />
                </Box>
                <SubscribeButton type="Product" entityId={product.id} />
                {(hasPermission('PRODUCT:CAN_EDIT') || hasPermission('PRODUCT:CAN_EDIT_OWN')) && (
                  <Button
                    as={DisableableRouterLink}
                    to="edit"
                    variant="primary"
                    isDisabled={isArchived || !canEdit}
                    disableReason={
                      !canEdit ? t('common:validation_error.incomplete_permission') : t('product:editForbiddenMessage')
                    }
                  >
                    {t('product:action.edit')}
                  </Button>
                )}
              </ButtonGroup>
            }
          />
        </GridItem>
      }
    >
      <Outlet />
    </Page>
  );
}

function ProductPageTabs() {
  const { t } = useTranslation('common');
  const tabItems = usePlugins(PRODUCT_TAB_ITEM);
  const sortedTabItems = React.useMemo(() => sortBy(tabItems, 'order'), [tabItems]);

  return (
    <>
      <PageTab to="." icon={faHomeAlt} />
      {sortedTabItems.map((tabItem, index) => (
        <PageTab key={'additionalTab' + index} to={tabItem.to} icon={tabItem.icon}>
          {tabItem.label}
        </PageTab>
      ))}
      <PageTab to="./history" icon={faHistory}>
        {t('history.label')}
      </PageTab>
    </>
  );
}
