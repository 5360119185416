import React from 'react';
import { paymentIcon } from '../../../ui/icons/business-objects';
import { PluginRegistry } from '../../../util/plugin/use-plugins';
import Translate from '../../../util/translate/translate';
import COLLECTIVE_ORDER_CONFIRMATION_CHILD_ROUTES from '../../order-confirmation/collective-order-confirmation/collective-order-confirmation-child-routes';
import {
  COLLECTIVE_ORDER_CONFIRMATION_TAB_ITEM,
  CollectiveOrderConfirmationTabItem,
} from '../../order-confirmation/collective-order-confirmation/collective-order-confirmation-page/collective-order-confirmation-page-route';
import ORDER_CONFIRMATION_CHILD_ROUTES from '../../order-confirmation/order-confirmation-child-routes';
import {
  ORDER_CONFIRMATION_TAB_ITEM,
  OrderConfirmationTabItem,
} from '../../order-confirmation/order-confirmation-page/order-confirmation-page-route';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../../permission-class-group/permission-class-group-extension';
import collectiveOrderConfirmationPaymentRoutes from './collective-order-confirmation-payment-routes';
import orderConfirmationPaymentRoutes from './order-confirmation-payment-routes';

import { usePaymentPermissionClassGroupExtension } from './payment-permissions';

export const orderConfirmationPaymentTabItem: OrderConfirmationTabItem = {
  order: 2,
  label: <Translate ns="payment" i18nKey="lister.title" />,
  icon: paymentIcon,
  to: './payments',
};

export const collectiveOrderConfirmationPaymentTabItem: CollectiveOrderConfirmationTabItem = {
  order: 2,
  label: <Translate ns="payment" i18nKey="lister.title" />,
  icon: paymentIcon,
  to: './payments',
};

export default function PaymentPluginProvider(registry: PluginRegistry) {
  registry.register(ORDER_CONFIRMATION_TAB_ITEM, orderConfirmationPaymentTabItem);
  registry.register(ORDER_CONFIRMATION_CHILD_ROUTES, orderConfirmationPaymentRoutes);
  registry.register(COLLECTIVE_ORDER_CONFIRMATION_TAB_ITEM, collectiveOrderConfirmationPaymentTabItem);
  registry.register(COLLECTIVE_ORDER_CONFIRMATION_CHILD_ROUTES, collectiveOrderConfirmationPaymentRoutes);
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, usePaymentPermissionClassGroupExtension);
}
