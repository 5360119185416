import { faCalendarCheck } from '@fortawesome/pro-regular-svg-icons';
import { PluginRegistry } from '../../../util/plugin/use-plugins';
import Translate from '../../../util/translate/translate';
import LOCATION_CHILD_ROUTES from '../../location/location-child-routes';
import { LOCATION_TAB_ITEM, LocationTabItem } from '../../location/location-page/location-page';
import locationAppointmentRoutes from './location-appointment-routes';

export const appointmentLocationTabItem: LocationTabItem = {
  order: 4,
  label: <Translate ns="appointment" i18nKey="lister.title" />,
  icon: faCalendarCheck,
  to: './appointments',
};

export default function LocationAppointmentProvider(registry: PluginRegistry) {
  registry.register(LOCATION_TAB_ITEM, appointmentLocationTabItem);
  registry.register(LOCATION_CHILD_ROUTES, locationAppointmentRoutes);
}
