import { useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CollectiveOrderConfirmationViewerDto,
  OrderConfirmationListItemDto,
  OrderConfirmationSendRequestDto,
  OrderConfirmationStatusDto,
  OrderConfirmationViewerDto,
} from '../../../api';
import { DisableableButton } from '../../../ui/disableable-button/disableable-button';
import { OrderConfirmationSendingModal } from './order-confirmation-sending-modal';

interface OrderConfirmationSendingButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  orderConfirmation: OrderConfirmationViewerDto | OrderConfirmationListItemDto | CollectiveOrderConfirmationViewerDto;
  number: string;
  onSend: (data: OrderConfirmationSendRequestDto) => void;
}

export const OrderConfirmationSendingButton = React.forwardRef<HTMLButtonElement, OrderConfirmationSendingButtonProps>(
  ({ orderConfirmation, number, onSend, children, ...props }, ref) => {
    const { t } = useTranslation(['order_confirmation']);
    const { isOpen, onClose, onOpen } = useDisclosure();

    const canSend =
      orderConfirmation.status === OrderConfirmationStatusDto.SEND ||
      orderConfirmation.status === OrderConfirmationStatusDto.NOT_SEND;

    const isPaymentRequired = orderConfirmation.sumTotal.netTotal > 0;
    const isCollective = number.startsWith('S-AU');

    return (
      <>
        <DisableableButton
          {...props}
          ref={ref}
          onClick={onOpen}
          aria-label={
            isCollective
              ? t('order_confirmation:collectiveOrderConfirmation.action.send.label')
              : t('order_confirmation:action.send.label')
          }
          isDisabled={!canSend || !isPaymentRequired}
          disableReason={t('order_confirmation:action.send.disabled')}
        >
          {children}
        </DisableableButton>
        <OrderConfirmationSendingModal
          number={number}
          isCollective={isCollective}
          isOpen={isOpen}
          onClose={onClose}
          onSend={onSend}
        />
      </>
    );
  },
);
