import { Stack } from '@chakra-ui/react';
import React from 'react';
import { Trans } from 'react-i18next';
import { LayoutType } from '../../common/layout-type';
import OwnersControl from '../../common/owner/owners-control';

interface EventOwnersControlProps {
  layout: LayoutType;
}

export default function EventOwnersControl({ layout }: EventOwnersControlProps) {
  //TODO BX-5339 commented out until visibility permissions are implemented
  // const { t } = useTranslation('event');
  // const account = useStaffAccount();
  //
  // const addedOwners = useWatch<EventDto, 'owners'>({
  //   name: 'owners',
  // });
  //
  // const isMemberOfOwner = (owners: OwnerDto[]): boolean => {
  //   const nonCoOwner = owners.filter((owner) => owner.type === OwnerTypeDto.OWNER);
  //   const memberOwner = nonCoOwner.filter((owner) =>
  //     account.memberships?.find((memberOf) => memberOf.id === owner.section.id),
  //   );
  //   return memberOwner.length > 0;
  // };

  return (
    <Stack>
      <OwnersControl
        layout={layout}
        ownerTypeHelperText={
          <Stack shouldWrapChildren={true} spacing={0}>
            <Trans ns="event" i18nKey="owners.helper_text_owner" />
            <Trans ns="event" i18nKey="owners.helper_text_coowner" />
          </Stack>
        }
      />
      {/* TODO Wird in BX-5339 umgesetzt/angepasst */}
      {/*{!isMemberOfOwner(addedOwners) && !account.permissions.includes('EVENT:FULL-EDIT') && (*/}
      {/*  <HStack spacing={6} alignItems="flex-start">*/}
      {/*    <Alert status="warning">*/}
      {/*      <AlertIcon />*/}
      {/*      {t('owners.hint_not_owner', {*/}
      {/*        sections: account.memberships?.map((section) => section.name),*/}
      {/*        count: account.memberships?.length,*/}
      {/*      })}*/}
      {/*    </Alert>*/}
      {/*  </HStack>*/}
      {/*)}*/}
    </Stack>
  );
}
