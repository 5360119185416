import React from 'react';
import { useTranslation } from 'react-i18next';
import { DisableableButton } from '../../../ui/disableable-button/disableable-button';
import useToast from '../../../ui/use-toast/use-toast';
import useDialog from '../../../util/use-dialog/use-dialog';
import AccountDeleteDialog from './account-delete-dialog';

export interface AccountDeleteButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  displayName: string;
  isDeletable: boolean;
  onSuccess?(): Promise<void>;
  onDelete(): Promise<void>;
}

const AccountDeleteButton = React.forwardRef<HTMLButtonElement, AccountDeleteButtonProps>(
  ({ children, displayName, isDeletable, onSuccess, onDelete, onClick, disabled, ...props }, ref) => {
    const { t } = useTranslation('account');
    const [deleteDialogIsOpen, onDeleteDialogClose, openDeleteDialog] = useDialog<false>();
    const showDeleteSuccessToast = useToast({
      id: 'account-deletion-success-toast',
      status: 'success',
    });

    const deleteAccount = async (event: React.MouseEvent<HTMLButtonElement>) => {
      onClick?.(event);
      if (event.defaultPrevented) {
        return;
      }

      const checked = await openDeleteDialog();
      if (checked) {
        await onDelete();
        showDeleteSuccessToast({
          title: t('deleteToast.delete'),
          description: t('deleteToast.deleteMessage', {
            name: displayName,
          }),
        });
        await onSuccess?.();
      }
    };

    return (
      <>
        <DisableableButton
          {...props}
          ref={ref}
          onClick={deleteAccount}
          disableReason={t('delete_failed.description')}
          isDisabled={!isDeletable || disabled}
        >
          {children}
        </DisableableButton>

        <AccountDeleteDialog isOpen={deleteDialogIsOpen} displayName={displayName} onClose={onDeleteDialogClose} />
      </>
    );
  },
);

export default AccountDeleteButton;
