import { Checkbox } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CollectiveOrderConfirmationViewerDto, OrderConfirmationListItemDto } from '../../../../api';
import ConfirmDialog from '../../../../ui/dialog/confirm-dialog';

export interface CollectiveOrderConfirmationEditDialogProps {
  collectiveOrderConfirmation: CollectiveOrderConfirmationViewerDto | OrderConfirmationListItemDto;
  isOpen: boolean;
  onClose(confirm: boolean): void;
}

export default function CollectiveOrderConfirmationEditDialog({
  collectiveOrderConfirmation,
  isOpen,
  onClose,
}: CollectiveOrderConfirmationEditDialogProps) {
  const { t } = useTranslation(['common', 'order_confirmation']);
  const initialFocusRef = React.useRef<HTMLInputElement>(null);
  const [confirmed, setConfirmed] = React.useState(false);

  const handleCloseWithReset = React.useCallback(
    (confirm: boolean) => {
      onClose(confirm);
      setConfirmed(false);
    },
    [onClose],
  );

  return (
    <ConfirmDialog
      isOpen={isOpen}
      initialFocusRef={initialFocusRef}
      confirmActionLabel={t('order_confirmation:collectiveOrderConfirmation.action.edit.label')}
      onClose={handleCloseWithReset}
      isConfirmActionDisabled={!confirmed}
      submitButtonColorScheme="red"
    >
      <Checkbox ref={initialFocusRef} isRequired isChecked={confirmed} onChange={(e) => setConfirmed(e.target.checked)}>
        {t('order_confirmation:collectiveOrderConfirmation.action.edit.confirm', {
          collectiveOrderConfirmationNumber:
            'collectiveOrderConfirmationNumber' in collectiveOrderConfirmation
              ? collectiveOrderConfirmation.collectiveOrderConfirmationNumber
              : collectiveOrderConfirmation.orderConfirmationNumber,
        })}
      </Checkbox>
    </ConfirmDialog>
  );
}
