/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MailTrackingStatusDto = {
    TO_BE_SENT: 'TO_BE_SENT',
    SENT: 'SENT',
    SOFTBOUNCE: 'SOFTBOUNCE',
    HARDBOUNCE: 'HARDBOUNCE',
    ON_BLOCKLIST: 'ON_BLOCKLIST',
    MISSING_EMAIL: 'MISSING_EMAIL'
} as const;
export type MailTrackingStatusDto = typeof MailTrackingStatusDto[keyof typeof MailTrackingStatusDto];


export function MailTrackingStatusDtoFromJSON(json: any): MailTrackingStatusDto {
    return MailTrackingStatusDtoFromJSONTyped(json, false);
}

export function MailTrackingStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailTrackingStatusDto {
    return json as MailTrackingStatusDto;
}

export function MailTrackingStatusDtoToJSON(value?: MailTrackingStatusDto | null): any {
    return value as any;
}

