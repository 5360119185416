import { DrivingAssignmentReferenceDto } from '../../../api';
import { drivingAssignmentIcon } from '../../../ui/icons/business-objects';
import Reference, { ReferenceProps } from '../../../ui/reference/reference';

export interface DrivingAssignmentReferenceProps extends Omit<ReferenceProps, 'icon' | 'to' | 'children'> {
  drivingAssignmentReference: DrivingAssignmentReferenceDto;
}

export default function DrivingAssignmentReference({
  drivingAssignmentReference,
  ...props
}: DrivingAssignmentReferenceProps) {
  //TODO: persmissions
  return (
    <Reference icon={drivingAssignmentIcon} to={`/driving-assignments/${drivingAssignmentReference.id}`} {...props}>
      {drivingAssignmentReference.drivingAssignmentNumber}
    </Reference>
  );
}
