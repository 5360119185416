import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const mailingMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.mailing" />,
    path: '/mailings',
    necessaryPermission: 'MAILING:CAN_SEE_LIST_VIEW',
  },
  {
    name: <Translate ns="app" i18nKey="main_navigation.mailing-template" />,
    path: '/mailing-templates',
    necessaryPermission: 'MAILING_TEMPLATE:CAN_SEE_LIST_VIEW',
  },
];

export default mailingMenuItems;
