/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosedDateTimeRangeDto } from './ClosedDateTimeRangeDto';
import {
    ClosedDateTimeRangeDtoFromJSON,
    ClosedDateTimeRangeDtoFromJSONTyped,
    ClosedDateTimeRangeDtoToJSON,
} from './ClosedDateTimeRangeDto';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { VehicleAccessibilityDto } from './VehicleAccessibilityDto';
import {
    VehicleAccessibilityDtoFromJSON,
    VehicleAccessibilityDtoFromJSONTyped,
    VehicleAccessibilityDtoToJSON,
} from './VehicleAccessibilityDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * Vehicle for the vehicle booking
 * @export
 * @interface VehicleDto
 */
export interface VehicleDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof VehicleDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof VehicleDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof VehicleDto
     */
    edition: EditionReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleDto
     */
    vehicleNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDto
     */
    licensePlate: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDto
     */
    manufacturer: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDto
     */
    vehicleModel: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDto
     */
    vehicleType: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDto
     */
    color: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDto
     */
    engine: string;
    /**
     * 
     * @type {number}
     * @memberof VehicleDto
     */
    seat: number;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleDto
     */
    isBrandingVisible: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleDto
     */
    isSponsorBrandingVisible: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleDto
     */
    isNumberingVisible: boolean;
    /**
     * 
     * @type {Array<ClosedDateTimeRangeDto>}
     * @memberof VehicleDto
     */
    availabilities: Array<ClosedDateTimeRangeDto>;
    /**
     * 
     * @type {VehicleAccessibilityDto}
     * @memberof VehicleDto
     */
    accessibility: VehicleAccessibilityDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleDto
     */
    comment?: string;
}

/**
 * Check if a given object implements the VehicleDto interface.
 */
export function instanceOfVehicleDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('edition' in value)) return false;
    if (!('licensePlate' in value)) return false;
    if (!('manufacturer' in value)) return false;
    if (!('vehicleModel' in value)) return false;
    if (!('vehicleType' in value)) return false;
    if (!('color' in value)) return false;
    if (!('engine' in value)) return false;
    if (!('seat' in value)) return false;
    if (!('isBrandingVisible' in value)) return false;
    if (!('isSponsorBrandingVisible' in value)) return false;
    if (!('isNumberingVisible' in value)) return false;
    if (!('availabilities' in value)) return false;
    if (!('accessibility' in value)) return false;
    return true;
}

export function VehicleDtoFromJSON(json: any): VehicleDto {
    return VehicleDtoFromJSONTyped(json, false);
}

export function VehicleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'vehicleNumber': json['vehicleNumber'] == null ? undefined : json['vehicleNumber'],
        'licensePlate': json['licensePlate'],
        'manufacturer': json['manufacturer'],
        'vehicleModel': json['vehicleModel'],
        'vehicleType': json['vehicleType'],
        'color': json['color'],
        'engine': json['engine'],
        'seat': json['seat'],
        'isBrandingVisible': json['isBrandingVisible'],
        'isSponsorBrandingVisible': json['isSponsorBrandingVisible'],
        'isNumberingVisible': json['isNumberingVisible'],
        'availabilities': ((json['availabilities'] as Array<any>).map(ClosedDateTimeRangeDtoFromJSON)),
        'accessibility': VehicleAccessibilityDtoFromJSON(json['accessibility']),
        'comment': json['comment'] == null ? undefined : json['comment'],
    };
}

export function VehicleDtoToJSON(value?: VehicleDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'vehicleNumber': value['vehicleNumber'],
        'licensePlate': value['licensePlate'],
        'manufacturer': value['manufacturer'],
        'vehicleModel': value['vehicleModel'],
        'vehicleType': value['vehicleType'],
        'color': value['color'],
        'engine': value['engine'],
        'seat': value['seat'],
        'isBrandingVisible': value['isBrandingVisible'],
        'isSponsorBrandingVisible': value['isSponsorBrandingVisible'],
        'isNumberingVisible': value['isNumberingVisible'],
        'availabilities': ((value['availabilities'] as Array<any>).map(ClosedDateTimeRangeDtoToJSON)),
        'accessibility': VehicleAccessibilityDtoToJSON(value['accessibility']),
        'comment': value['comment'],
    };
}

