import React from 'react';
import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const groupTemplateMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.group_template" />,
    path: '/group-templates',
    necessaryPermission: 'GROUP_TEMPLATE:CAN_SEE_LIST_VIEW',
  },
];

export default groupTemplateMenuItems;
