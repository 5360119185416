import {
  CollectiveOrderConfirmationViewerDto,
  OrderConfirmationListItemDto,
  OrderConfirmationTypeDto,
} from '../../api';

type CollectiveOrderConfirmation = OrderConfirmationListItemDto | CollectiveOrderConfirmationViewerDto;

export function isCollectiveOrderConfirmation(listItem: OrderConfirmationListItemDto): boolean {
  return listItem.orderConfirmationType == OrderConfirmationTypeDto.COLLECTIVE;
}

export function getCollectiveOrderConfirmationNumber(collective: CollectiveOrderConfirmation): string {
  return 'collectiveOrderConfirmationNumber' in collective
    ? collective.collectiveOrderConfirmationNumber
    : collective.orderConfirmationNumber;
}
