import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const visaTemplateMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.visa_template" />,
    path: '/visa-templates',
    necessaryPermission: 'VISA_TEMPLATE:CAN_SEE_LIST_VIEW',
  },
];

export default visaTemplateMenuItems;
