import React from 'react';
import { useParams } from 'react-router-dom';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchVehicle } from '../vehicle-queries';
import { getVehicleId } from './get-vehicle-id';

export default function VehicleBreadcrumb() {
  const vehicle = useFetcher(fetchVehicle, { id: getVehicleId(useParams()) });

  return <>{vehicle.licensePlate}</>;
}
