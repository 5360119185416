/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const VehicleBookingStatusDto = {
    NEW: 'NEW',
    IN_PROGRESS: 'IN_PROGRESS',
    CONFIRMED: 'CONFIRMED',
    CHANGE: 'CHANGE',
    WITHDRAWN: 'WITHDRAWN',
    CANCELLED: 'CANCELLED'
} as const;
export type VehicleBookingStatusDto = typeof VehicleBookingStatusDto[keyof typeof VehicleBookingStatusDto];


export function VehicleBookingStatusDtoFromJSON(json: any): VehicleBookingStatusDto {
    return VehicleBookingStatusDtoFromJSONTyped(json, false);
}

export function VehicleBookingStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleBookingStatusDto {
    return json as VehicleBookingStatusDto;
}

export function VehicleBookingStatusDtoToJSON(value?: VehicleBookingStatusDto | null): any {
    return value as any;
}

