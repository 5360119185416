import { hotelBookingIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { LocationTabItem } from '../location/location-page/location-page';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { useHotelBookingPermissionClassGroupExtension } from './hotel-booking-permissions';

export const hotelBookingLocationTabItem: LocationTabItem = {
  order: 2,
  label: <Translate ns="hotel_edition_data" i18nKey="lister.title" />,
  icon: hotelBookingIcon,
  to: './hotel-edition-data',
  isVisible: (hasPermission) => hasPermission('HOTEL_BOOKING:CAN_SEE_LIST_VIEW'),
};

export default function HotelBookingPluginProvider(registry: PluginRegistry) {
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useHotelBookingPermissionClassGroupExtension);
}
