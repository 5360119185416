import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { BreadcrumbRouteObject } from '../../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../../ui/helmet/helmet-outlet';
import useFetcher from '../../../util/swr/use-fetcher';
import { Component } from '../../order-confirmation/collective-order-confirmation/collective-order-confirmation-page/collective-order-confirmation-page-route';
import { fetchCollectiveOrderConfirmation } from '../../order-confirmation/collective-order-confirmation/collective-order-confirmation-queries';
import { OrderConfirmationPaymentPageContent } from './payment-lister/order-confirmation-payment-page-content';

const collectiveOrderConfirmationPaymentRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: '',
    element: <Component />,
    children: [
      {
        path: 'payments',
        element: <OrderConfirmationPaymentPageContent />,
        handle: {
          helmet: <CollectiveOrderConfirmationPaymentsHelmet />,
        },
      },
    ],
  },
];

export default collectiveOrderConfirmationPaymentRoutes;

function CollectiveOrderConfirmationPaymentsHelmet() {
  const { t } = useTranslation('payment');
  const params = useParams<{ collectiveOrderConfirmationId: string }>();
  invariant(params.collectiveOrderConfirmationId, 'Empty collectiveOrderConfirmationId');
  const collectiveOrderConfirmation = useFetcher(fetchCollectiveOrderConfirmation, {
    id: params.collectiveOrderConfirmationId,
  });

  return (
    <Helmet
      title={t('connections.collectiveOrderConfirmationHelmet', {
        orderConfirmationNumber: collectiveOrderConfirmation.collectiveOrderConfirmationNumber,
      })}
    />
  );
}
