import { Flex, GridItem, Stack } from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import Page from '../../../ui/page/page';
import PageContent from '../../../ui/page/page-content';
import PagePublicHeader from '../../../ui/page/page-public-header';
import PageSpinner from '../../../ui/page/page-spinner';
import { LightModeBox } from '../../../util/color-mode/color-mode-box';
import PublicRundownEventHeader from './public-rundown-event-header';
import { usePublishedRundownSettings } from './use-public-rundown';

const PublicRundownCalendar = React.lazy(() => import('./public-rundown-calendar'));

export default function PublicRundownViewer() {
  const { publicationId } = useParams<{ publicationId: string }>();
  invariant(publicationId != null, 'Missing publicationId');

  const settings = usePublishedRundownSettings(publicationId);

  return (
    <LightModeBox>
      <Page
        header={
          <GridItem>
            <PagePublicHeader />
          </GridItem>
        }
      >
        <PageContent useFullWidth fixedHeight>
          <React.Suspense fallback={<PageSpinner />}>
            <Stack spacing={6} height="full">
              <Flex as="header">
                <PublicRundownEventHeader
                  title={settings.eventSettings.eventReference.title}
                  dateTimeRange={settings.eventSettings.eventReference.dateTimeRange}
                  location={settings.eventSettings.locationReference}
                  connectedEvents={settings.connectedEvents}
                />
              </Flex>
              <PublicRundownCalendar publicationId={publicationId} settings={settings} />
            </Stack>
          </React.Suspense>
        </PageContent>
      </Page>
    </LightModeBox>
  );
}
