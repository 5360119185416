import drivingAssignmentApi from '../../data-access/driving-assignment-api';
import { createFetcherScope } from '../../util/swr/fetcher-scope';

export const drivingAssignmentFetcher = createFetcherScope(drivingAssignmentApi);
export const fetchDrivingAssignment = drivingAssignmentFetcher.createFetcher(
  drivingAssignmentApi.fetchDrivingAssignment,
);
export const fetchDrivingAssignmentHistory = drivingAssignmentFetcher.createFetcher(
  drivingAssignmentApi.fetchDrivingAssignmentHistory,
);
