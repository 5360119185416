import { collectiveOrderConfirmationIcon } from '../../../ui/icons/business-objects';
import { PluginRegistry } from '../../../util/plugin/use-plugins';
import Translate from '../../../util/translate/translate';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../../search/search-plugin';
import { useCollectiveOrderConfirmationPermissionClassGroupExtension } from './collective-order-confirmation-permissions';
import CollectiveOrderConfirmationSearchResult from './collective-order-confirmation-search-result';

export default function CollectiveOrderConfirmationPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, {
    type: 'COLLECTIVE_ORDER_CONFIRMATION',
    Component: CollectiveOrderConfirmationSearchResult,
  });
  registry.register(SEARCH_FILTER, {
    key: 'COLLECTIVE_ORDER_CONFIRMATION',
    icon: collectiveOrderConfirmationIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.COLLECTIVE_ORDER_CONFIRMATION" />,
    necessaryPermission: 'COLLECTIVE_ORDER_CONFIRMATION:CAN_SEE_DETAILS',
  });
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useCollectiveOrderConfirmationPermissionClassGroupExtension);
}
