import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const reportingMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.reporting" />,
    path: '/reporting',
    necessaryPermission: 'REPORTING:CAN_SEE_LIST_VIEW',
  },
];

export default reportingMenuItems;
