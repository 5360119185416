import {
  GenderIdentityDto,
  LanguagesDto,
  OccupationDtoStatusEnum,
  OfficiallyRegisteredGenderDto,
  PersonCommentDtoPurposeEnum,
  PersonDtoPostNominalEnum,
  PersonDtoPublishStageNameEnum,
  PersonDtoSalutationEnum,
  PersonMarkerDto,
  PersonStatusDto,
  PersonTypeDto,
  ProfessionDto,
  TitleDto,
  WorkingSectorDto,
} from '../../api';
// markers need to be sorted alphabetically
export const markers = [
  PersonMarkerDto.HOUSE_OF_REPRESENTATIVES_FOR_ENGAGEMENT_FEDERAL_AFFAIRS_AND_MEDIA,
  PersonMarkerDto.HOUSE_OF_REPRESENTATIVES_FOR_CULTURE_AND_EUROPE,
  PersonMarkerDto.HOUSE_OF_REPRESENTATIVES_COMMITTEE,
  PersonMarkerDto.BUND,
  PersonMarkerDto.BUNDESTAG_BUDGET_COMMITTEE,
  PersonMarkerDto.BUNDESTAG_CULTURE_COMMITTEE,
  PersonMarkerDto.BUNDESTAG_COMMITTEE,
  PersonMarkerDto.SERVICE_PROVIDER,
  PersonMarkerDto.SPONSOR,
  PersonMarkerDto.IFB_SELECTION_COMMITTEE_BERLINALE_SHORTS,
  PersonMarkerDto.IFB_SELECTION_COMMITTEE_FORUM,
  PersonMarkerDto.IFB_SELECTION_COMMITTEE_GENERATION,
  PersonMarkerDto.IFB_SELECTION_COMMITTEE_GERMAN_CINEMA_LOLA,
  PersonMarkerDto.IFB_SELECTION_COMMITTEE_PANORAMA,
  PersonMarkerDto.IFB_SELECTION_COMMITTEE_PERSPECTIVE_GERMAN_CINEMA,
  PersonMarkerDto.IFB_SELECTION_COMMITTEE,
  PersonMarkerDto.IFB_CONSULTANT,
  PersonMarkerDto.IFB_DELEGATES,
  PersonMarkerDto.KBB_WITHOUT_IFB,
  PersonMarkerDto.KBB_SUPERVISORY_BOARD,
  PersonMarkerDto.KBB_FORMER_EMPLOYEES,
  PersonMarkerDto.KBB_MANAGEMENT,
  PersonMarkerDto.MEDIENBOARD_BERLIN_BRANDENBURG,
  PersonMarkerDto.POLITICS_INTERNATIONAL,
  PersonMarkerDto.POLITICS_NATIONAL,
  PersonMarkerDto.PRESS,
  PersonMarkerDto.VIP_ARTISTIC_DIRECTOR,
];

export const types = [PersonTypeDto.STAFF, PersonTypeDto.SERVICE_PROVIDER, PersonTypeDto.PROFESSIONAL];

export const salutationOptions = [
  PersonDtoSalutationEnum.DEFAULT,
  PersonDtoSalutationEnum.INVERTED,
  PersonDtoSalutationEnum.INDIVIDUAL,
];

export const titleOptions = [
  TitleDto.AMBASSADOR,
  TitleDto.CHANCELLOR,
  TitleDto.FEDERAL_MINISTER,
  TitleDto.FEDERAL_PRESIDENT,
  TitleDto.PRESIDENT_OF_THE_BUNDESTAG,
  TitleDto.MAYOR_MAYORESS,
  TitleDto.ENVOY,
  TitleDto.MINISTER,
  TitleDto.MINISTERIAL_DIRECTOR,
  TitleDto.MINISTERIAL_DIRECTOR_RETIRED,
  TitleDto.SENIOR_MINISTERIAL_COUNSELLOR,
  TitleDto.MINISTER_PRESIDENT,
  TitleDto.GOVERNING_MAYOR,
  TitleDto.GOVERNING_MAYOR_OF_BERLIN,
  TitleDto.SENATOR,
  TitleDto.SENATOR_RETIRED,
  TitleDto.MINISTER_OF_STATE,
  TitleDto.MINISTER_OF_STATE_RETIRED,
  TitleDto.STATE_SECRETARY,
  TitleDto.DEPUTY_MINISTER,
  TitleDto.SENATE_DIRECTOR,
  TitleDto.GOVERNING_MAYOR_RETIRED,
  TitleDto.DIPL_ING,
  TitleDto.DR,
  TitleDto.DR_H_C,
  TitleDto.DR_MED,
  TitleDto.PROF,
  TitleDto.PROF_DR,
  TitleDto.PROF_DR_DR_H_C_MULT,
  TitleDto.PROF_DR_MED,
  TitleDto.A_D_PROF_DR_H_C,
];

export const postNominalOptions = [
  PersonDtoPostNominalEnum.MDA,
  PersonDtoPostNominalEnum.MDB,
  PersonDtoPostNominalEnum.MDL,
  PersonDtoPostNominalEnum.MEP,
  PersonDtoPostNominalEnum.MP,
];

export const genderIdentityOptions = [
  GenderIdentityDto.MALE,
  GenderIdentityDto.FEMALE,
  GenderIdentityDto.NON_BINARY,
  GenderIdentityDto.NOT_SPECIFIED,
  GenderIdentityDto.FREE_HAND_TEXT,
];

export const officiallyRegisteredGenderOptions = [
  OfficiallyRegisteredGenderDto.FEMALE,
  OfficiallyRegisteredGenderDto.MALE,
  OfficiallyRegisteredGenderDto.NON_BINARY,
  OfficiallyRegisteredGenderDto.X,
];

export const publishStageNamesOptions = [
  PersonDtoPublishStageNameEnum.ONLY_NAME,
  PersonDtoPublishStageNameEnum.ONLY_STAGE_NAME,
  PersonDtoPublishStageNameEnum.NAME_AND_STAGE_NAME,
];

export const contactLanguageOptions = [LanguagesDto.GERMAN, LanguagesDto.ENGLISH];

export const commentPurposeOptions = [
  PersonCommentDtoPurposeEnum.GLOBAL,
  PersonCommentDtoPurposeEnum.GUEST_MANAGEMENT,
  PersonCommentDtoPurposeEnum.EVENT_PROTOCOL,
  PersonCommentDtoPurposeEnum.INTENDANZ,
];

export const editableStatus: PersonStatusDto[] = [
  PersonStatusDto.ACTIVE,
  PersonStatusDto.INACTIVE,
  PersonStatusDto.HISTORIC,
];

export const PROFESSION_OPTIONS = [
  ProfessionDto.ARCHIVE_PRODUCER_RESEARCHER,
  ProfessionDto.AUDIENCE_DESIGN,
  ProfessionDto.CASTING_DIRECTOR,
  ProfessionDto.SCREENWRITER,
  ProfessionDto.ACQUISITION_BUYER,
  ProfessionDto.DEVELOPMENT,
  ProfessionDto.SCORE,
  ProfessionDto.COMMISSIONING_EDITOR,
  ProfessionDto.CINEMATOGRAPHY,
  ProfessionDto.COSTUME_MAKEUP,
  ProfessionDto.ARTIST,
  ProfessionDto.CURATOR_FILM_PROGRAMMER,
  ProfessionDto.MANAGEMENT,
  ProfessionDto.MARKETING_COMMUNICATION_PROMOTION,
  ProfessionDto.POLITICIAN,
  ProfessionDto.PRESS_PHOTOGRAPHER,
  ProfessionDto.PRESS_JOURNALIST,
  ProfessionDto.PRESS_PR_AGENT,
  ProfessionDto.PRESS_TV_TECHNICIAN,
  ProfessionDto.PRODUCTION_SET_DESIGNER_ART_DIRECTOR,
  ProfessionDto.PRODUCTION_TEAM,
  ProfessionDto.PRODUCER,
  ProfessionDto.LEGAL_FINANCE,
  ProfessionDto.ASSISTANT_DIRECTOR,
  ProfessionDto.DIRECTOR,
  ProfessionDto.ACTOR_ACTRESS,
  ProfessionDto.EDITING,
  ProfessionDto.STUDENT,
  ProfessionDto.SOUND,
  ProfessionDto.SALES,
  ProfessionDto.ACADEMIC_TEACHING_STAFF,

  ProfessionDto.OTHER,
];

export const WORKING_SECTOR_OPTIONS = [
  WorkingSectorDto.ANIMATION,
  WorkingSectorDto.DOCUMENTARY_FILM,
  WorkingSectorDto.EXPERIMENTAL_FILM,
  WorkingSectorDto.TV,
  WorkingSectorDto.GAMING,
  WorkingSectorDto.IMMERSIVE_MEDIA_VR_XR_AR,
  WorkingSectorDto.INFLIGHT,
  WorkingSectorDto.CHILDREN_YOUTH,
  WorkingSectorDto.THEATRICAL,
  WorkingSectorDto.SHORT_FILM,
  WorkingSectorDto.LGBTQIA_PLUS,
  WorkingSectorDto.LITERATURE,
  WorkingSectorDto.MUSIC,
  WorkingSectorDto.PODCAST,
  WorkingSectorDto.PRESS_ONLINE,
  WorkingSectorDto.PRESS_PODCAST,
  WorkingSectorDto.PRESS_PR,
  WorkingSectorDto.PRESS_PRINT,
  WorkingSectorDto.PRESS_RADIO_AUDIO,
  WorkingSectorDto.PRESS_SOCIAL_MEDIA,
  WorkingSectorDto.PRESS_SOCIAL_MEDIA_VIDEO,
  WorkingSectorDto.PRESS_TV_VIDEO,
  WorkingSectorDto.PRESS_WEB_TV,
  WorkingSectorDto.SERIES,
  WorkingSectorDto.FEATURE_FILM,
  WorkingSectorDto.VIDEO_ON_DEMAND,
  WorkingSectorDto.OTHER,
];

export const OCCUPATION_STATUS_OPTIONS = [
  OccupationDtoStatusEnum.CONFIRMED,
  OccupationDtoStatusEnum.UNCONFIRMED,
  OccupationDtoStatusEnum.TERMINATED,
];
