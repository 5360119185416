import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const myAccountMenuItem: SubMenuItem = {
  name: <Translate ns="app" i18nKey="main_navigation.my-account" />,
  path: '/my-account',
  necessaryPermission: 'MY_ACCOUNT:CAN_EDIT',
};

export default myAccountMenuItem;
