import { HStack } from '@chakra-ui/react';
import React from 'react';
import { FieldPath, FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ClosedDateRangeDto, type ClosedDateTimeRangeDto } from '../../../api';
import dateMax from '../../../util/date/date-max';
import validDate from '../../../util/date/valid-date';
import now from '../../../util/now';
import DateInputFormControl from './date-input-form-control';

interface DateRangeControlProps<T extends FieldValues> {
  initialFocusRef?: React.RefObject<HTMLInputElement>;
  showTimeSelect?: boolean;
  startLabel: string;
  endLabel: string;
  path?: FieldPath<T>;
  minNow?: boolean;
}

export default function DateRangeControl<T extends FieldValues>({
  initialFocusRef,
  showTimeSelect,
  startLabel,
  endLabel,
  path,
  minNow = true,
}: DateRangeControlProps<T>) {
  const prefixWithPath = <TPath extends string>(name: TPath) => (path != null ? (`${path}.${name}` as TPath) : name);
  const { t } = useTranslation('common');
  const { watch } = useFormContext<ClosedDateTimeRangeDto | ClosedDateRangeDto>();
  const start = validDate(watch(prefixWithPath('start')));
  const end = validDate(watch(prefixWithPath('end')));
  const minStart = minNow ? new Date(now()) : undefined;
  const [defaultStart] = React.useState(start);
  const [defaultEnd] = React.useState(end);
  const defaultStartInThePast = defaultStart != null && defaultStart.valueOf() < now();
  const defaultEndInThePast = defaultEnd != null && defaultEnd.valueOf() < now();

  return (
    <HStack alignItems="start">
      <DateInputFormControl<ClosedDateTimeRangeDto | ClosedDateRangeDto>
        isDisabled={defaultStartInThePast}
        deps={[prefixWithPath('end')]}
        name={prefixWithPath('start')}
        isRequired
        label={startLabel}
        referenceDate={minStart}
        showTimeSelect={showTimeSelect}
        min={
          !defaultStartInThePast && minStart != null
            ? {
                value: minStart,
                message: t('dateRange.validationError.start.min'),
              }
            : undefined
        }
        max={
          !defaultStartInThePast
            ? {
                value: end,
                message: t('dateRange.validationError.start.max'),
              }
            : undefined
        }
        ref={initialFocusRef}
      />

      <DateInputFormControl<ClosedDateTimeRangeDto | ClosedDateRangeDto>
        isDisabled={defaultEnd != null && defaultEnd.valueOf() < now()}
        deps={[prefixWithPath('start')]}
        name={prefixWithPath('end')}
        referenceDate={dateMax(start, minStart)}
        showTimeSelect={showTimeSelect}
        isRequired
        min={
          !defaultEndInThePast
            ? {
                value: dateMax(start, minStart),
                message: t('dateRange.validationError.end.min'),
              }
            : undefined
        }
        label={endLabel}
      />
    </HStack>
  );
}
