import { ReactNode } from 'react';
import { VehicleBookingDto } from '../../../api';
import { PluginToken } from '../../../util/plugin/use-plugins';

export interface VehicleBookingViewerItemExtension {
  item: (vehicleBooking: VehicleBookingDto, key: number) => ReactNode;
}

export const VEHICLE_BOOKING_VIEWER_ITEM_EXTENSION = new PluginToken<VehicleBookingViewerItemExtension>(
  'VehicleBookingViewerItemExtension',
);
