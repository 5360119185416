import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MailingListPersonEntryDto } from '../../../api';
import HistoryDisplaySettings from '../../../ui/history/history-display-settings';

export default function useMailingListHistorySettings(): HistoryDisplaySettings<MailingListPersonEntryDto> {
  const { t } = useTranslation('mailing_list');

  return useMemo<HistoryDisplaySettings<MailingListPersonEntryDto>>(() => {
    return {
      attributeLabels: {
        'mailingList.title': t('title'),
      },
      valueFormatter: {
        'mailingList.title': (title, entry) =>
          entry == null
            ? undefined
            : entry.mailingList == null
              ? t('history.deletedMailingListTitle')
              : title == null
                ? t('history.forbiddenMailingListTitle')
                : title,
      },
      name: (entry, _) => {
        return entry.displayName;
      },
      keyAttribute: 'id',
      showEditDescription: true,
    };
  }, [t]);
}
