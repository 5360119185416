import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { DisableableButton, DisableableButtonProps } from './disableable-button';

interface DisableableRouterLinkProps
  extends Omit<DisableableButtonProps, 'as'>,
    Pick<LinkProps, 'to' | 'replace' | 'target'> {}

export const DisableableRouterLink = React.forwardRef(
  ({ ...props }: DisableableRouterLinkProps, ref: React.ForwardedRef<any>) => {
    return <DisableableButton as={Link} ref={ref} {...props} />;
  },
);
