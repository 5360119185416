import { Input, InputGroup, InputRightAddon } from '@chakra-ui/react';
import { ReactNode, useMemo } from 'react';
import { FieldValues, Path, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PERCENTAGE_AMOUNT } from '../../../util/constants';
import FormControl from '../form-control';
import useFormattedNumberInput from '../use-formatted-number-input';

interface PercentageFormControlProps<TFieldValues extends FieldValues> {
  name: Path<TFieldValues>;
  label: ReactNode;
  isRequired?: boolean;
  max?: number;
  min?: number;
}

export default function PercentageFormControl<TFieldValues extends FieldValues>({
  name,
  isRequired,
  label,
  max,
  min,
}: PercentageFormControlProps<TFieldValues>) {
  const { t } = useTranslation('common');
  const formatter = useMemo(() => {
    return new Intl.NumberFormat('de-DE', {
      style: 'percent',
      maximumFractionDigits: 2,
    });
  }, []);
  const { field } = useController({
    name,
    rules: {
      required: isRequired ? t('validation_error.required', { field: label }) : undefined,
      pattern: {
        value: PERCENTAGE_AMOUNT,
        message: t('validation_error.invalidPercentageAmount'),
      },
      validate: {
        min: (value: string | number) => {
          if (min != null && typeof value === 'number' && value < min) {
            return t('validation_error.min_number', { field: label, min: formatter.format(min) });
          }

          return true;
        },
        max: (value: string | number) => {
          if (max != null && typeof value === 'number' && value > max) {
            return t('validation_error.max_number', { field: label, max: formatter.format(max) });
          }

          return true;
        },
      },
    },
  });

  const { getInputProps } = useFormattedNumberInput({
    value: typeof (field.value as string | number) === 'number' ? field.value * 100 : field.value,
    onChange(value) {
      field.onChange(typeof value === 'number' ? percentageWithFixedPrecision(value, 2) : value);
    },
    useGrouping: false,
    maxFractionDigits: 2,
  });

  return (
    <FormControl name={name} label={label} isRequired={isRequired}>
      <InputGroup>
        <Input ref={field.ref} {...getInputProps({ onBlur: field.onBlur })} />
        <InputRightAddon>%</InputRightAddon>
      </InputGroup>
    </FormControl>
  );
}

function percentageWithFixedPrecision(value: number, fractionDigits: number) {
  return Number((value / 100).toFixed(fractionDigits + 2));
}
