import { Link, Stack, Text } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { AbstractReferenceDto, LocationDto, LocationStatusCheckDto } from '../../../api';
import locationApi from '../../../data-access/location-api';
import { DisableableButton } from '../../../ui/disableable-button/disableable-button';
import useToast from '../../../ui/use-toast/use-toast';
import useDialog from '../../../util/use-dialog/use-dialog';
import LocationDeleteDialog from './location-delete-dialog';

export interface LocationDeleteButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  location: LocationDto;
  isDeletable: boolean;
  locationStatusCheck: LocationStatusCheckDto;

  onSuccess?(): void;
}

const LocationDeleteButton = React.forwardRef<HTMLButtonElement, LocationDeleteButtonProps>(
  ({ children, location, isDeletable, locationStatusCheck, onSuccess, onClick, disabled, ...props }, ref) => {
    const { t } = useTranslation('location');
    const [deleteDialogIsOpen, onDeleteDialogClose, openDeleteDialog] = useDialog<false>();

    const showDeleteSuccessToast = useToast({
      id: 'location-editor-success-toast',
      status: 'success',
    });

    const showDeleteFailedToast = useToast({
      id: 'location-editor-failed-toast',
      status: 'error',
    });

    const deleteLocation = async (event: React.MouseEvent<HTMLButtonElement>) => {
      onClick?.(event);

      if (event.defaultPrevented) {
        return;
      }

      invariant(location.id != null, 'Missing location id');

      const checked = await openDeleteDialog();
      if (checked) {
        try {
          await locationApi.deleteLocation(
            {
              id: location.id,
            },
            { allowedErrorCodes: [409] } as RequestInit,
          );

          showDeleteSuccessToast({
            title: t('lister.toast.delete_success.title'),
            description: t('lister.toast.delete_success.description', {
              name: location.name,
              abbreviation: location.abbreviation,
            }),
          });
          onSuccess?.();
        } catch (_) {
          showDeleteFailedToast({
            title: t('lister.toast.delete_failed.title'),
            description: t('lister.toast.delete_failed.description', {
              name: location.name,
              abbreviation: location.abbreviation,
            }),
          });
        }
      }
    };

    return (
      <>
        <DisableableButton
          {...props}
          ref={ref}
          onClick={deleteLocation}
          disableReason={<LocationNotDeletableReason location={location} locationStatusCheck={locationStatusCheck} />}
          isDisabled={!isDeletable || disabled}
        >
          {children}
        </DisableableButton>
        <LocationDeleteDialog location={location} isOpen={deleteDialogIsOpen} onClose={onDeleteDialogClose} />
      </>
    );
  },
);

export default LocationDeleteButton;

interface LocationNotDeletableReasonProps {
  location: LocationDto;
  locationStatusCheck: LocationStatusCheckDto;
}

function LocationNotDeletableReason({ location, locationStatusCheck }: LocationNotDeletableReasonProps) {
  const { t } = useTranslation('location');
  const renderReferences = (references: Array<AbstractReferenceDto>, label: string, getUrl: (id: string) => string) =>
    !isEmpty(references) && (
      <>
        <Text fontWeight="medium">{label}</Text>
        {references.map(({ id, title }) => (
          <Link key={id} variant="link" as={RouterLink} to={getUrl(id)}>
            <Text noOfLines={1} fontWeight="medium" maxWidth="fit-content">
              {title}
            </Text>
          </Link>
        ))}
      </>
    );

  return (
    <Stack>
      <Text>
        {t('lister.toast.delete_failed.description', {
          name: location.name,
          abbreviation: location.abbreviation,
        })}
      </Text>
      {renderReferences(
        locationStatusCheck.eventReferences,
        t('lister.toast.delete_failed.descriptionEvent'),
        (id) => `/events/${id}`,
      )}
      {renderReferences(
        locationStatusCheck.tagReferences,
        t('lister.toast.delete_failed.descriptionTag'),
        (id) => `/tags/${id}`,
      )}
      {renderReferences(
        locationStatusCheck.rundownReferences,
        t('lister.toast.delete_failed.descriptionRundown'),
        (id) => `/events/${id}/rundown`,
      )}
      {renderReferences(
        locationStatusCheck.hotelEditionDataReferences,
        t('lister.toast.delete_failed.descriptionHotelEditionData'),
        (id) => `/locations/${location.id}/hotel-edition-data/${id}`,
      )}
    </Stack>
  );
}
