/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyReferenceDto } from './CompanyReferenceDto';
import {
    CompanyReferenceDtoFromJSON,
    CompanyReferenceDtoFromJSONTyped,
    CompanyReferenceDtoToJSON,
} from './CompanyReferenceDto';
import type { DefaultPostalAddressDto } from './DefaultPostalAddressDto';
import {
    DefaultPostalAddressDtoFromJSON,
    DefaultPostalAddressDtoFromJSONTyped,
    DefaultPostalAddressDtoToJSON,
} from './DefaultPostalAddressDto';

/**
 * 
 * @export
 * @interface CollectiveOrderConfirmationRecipientDto
 */
export interface CollectiveOrderConfirmationRecipientDto {
    /**
     * 
     * @type {CompanyReferenceDto}
     * @memberof CollectiveOrderConfirmationRecipientDto
     */
    company: CompanyReferenceDto;
    /**
     * 
     * @type {DefaultPostalAddressDto}
     * @memberof CollectiveOrderConfirmationRecipientDto
     */
    postalAddress: DefaultPostalAddressDto;
}

/**
 * Check if a given object implements the CollectiveOrderConfirmationRecipientDto interface.
 */
export function instanceOfCollectiveOrderConfirmationRecipientDto(value: object): boolean {
    if (!('company' in value)) return false;
    if (!('postalAddress' in value)) return false;
    return true;
}

export function CollectiveOrderConfirmationRecipientDtoFromJSON(json: any): CollectiveOrderConfirmationRecipientDto {
    return CollectiveOrderConfirmationRecipientDtoFromJSONTyped(json, false);
}

export function CollectiveOrderConfirmationRecipientDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectiveOrderConfirmationRecipientDto {
    if (json == null) {
        return json;
    }
    return {
        
        'company': CompanyReferenceDtoFromJSON(json['company']),
        'postalAddress': DefaultPostalAddressDtoFromJSON(json['postalAddress']),
    };
}

export function CollectiveOrderConfirmationRecipientDtoToJSON(value?: CollectiveOrderConfirmationRecipientDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'company': CompanyReferenceDtoToJSON(value['company']),
        'postalAddress': DefaultPostalAddressDtoToJSON(value['postalAddress']),
    };
}

