import { HStack } from '@chakra-ui/react';
import React from 'react';
import { DrivingAssignmentReferenceDto, DrivingAssignmentReferenceDtoFromJSON, VehicleBookingDto } from '../../../api';
import { DescriptionItem } from '../../../ui/description';
import Translate from '../../../util/translate/translate';
import DrivingAssignmentReference from '../driving-assignment-reference/driving-assignment-reference';

interface DrivingAssignmentVehicleBookingDescriptionItemProps {
  vehicleBooking: VehicleBookingDto;
}

export default function DrivingAssignmentVehicleBookingDescriptionItem({
  vehicleBooking,
}: DrivingAssignmentVehicleBookingDescriptionItemProps) {
  const drivingAssignment = useVehicleBooking(vehicleBooking);

  return drivingAssignment != null ? (
    <DescriptionItem label={<Translate ns="driving" i18nKey="assignment.label" />} span={2}>
      <HStack>
        <DrivingAssignmentReference drivingAssignmentReference={drivingAssignment} />
      </HStack>
    </DescriptionItem>
  ) : undefined;
}

function useVehicleBooking(vehicleBooking: VehicleBookingDto): DrivingAssignmentReferenceDto {
  return DrivingAssignmentReferenceDtoFromJSON(vehicleBooking.extensions.drivingAssignment);
}
