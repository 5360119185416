import { Menu, MenuItem, MenuList, Portal } from '@chakra-ui/react';
import { faCircleXmark, faDownload, faMoneyBill, faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CollectiveOrderConfirmationViewerDto,
  OrderConfirmationListItemDto,
  OrderConfirmationSendRequestDto,
} from '../../../../api';
import collectiveOrderConfirmationApi from '../../../../data-access/collective-order-confirmation-api';
import { BurgerMenuButton, BurgerMenuButtonProps } from '../../../../ui/burger-menu-button/burger-menu-button';
import usePermission from '../../../permission/use-permission';
import { OrderConfirmationDownloadButton } from '../../order-confirmation-download-button/order-confirmation-download-button';
import { OrderConfirmationSendingButton } from '../../order-confirmation-sending-button/order-confirmation-sending-button';
import { getCollectiveOrderConfirmationNumber } from '../../order-confirmation-utils';
import { RecordManualPaymentButton } from '../../record-manual-payment-button/record-manual-payment-button';
import { CollectiveOrderConfirmationCancelButton } from '../collective-oder-confirmation-cancel-button/collective-order-confirmation-cancel-button';

interface CollectiveOrderConfirmationMenuProps extends BurgerMenuButtonProps {
  collectiveOrderConfirmation: CollectiveOrderConfirmationViewerDto | OrderConfirmationListItemDto;
}

export default function CollectiveOrderConfirmationMenu({
  collectiveOrderConfirmation,
  ...props
}: CollectiveOrderConfirmationMenuProps) {
  const actions = useCollectiveOrderConfirmationActions()(collectiveOrderConfirmation);

  return (
    actions.length > 0 && (
      <Menu>
        <BurgerMenuButton {...props} />
        <Portal>
          <MenuList>{actions}</MenuList>
        </Portal>
      </Menu>
    )
  );
}

function useCollectiveOrderConfirmationActions() {
  const { t } = useTranslation(['order_confirmation']);
  const { hasPermission } = usePermission();

  return useCallback(
    (collectiveOrderConfirmation: OrderConfirmationListItemDto | CollectiveOrderConfirmationViewerDto) => {
      const menuItems: ReactNode[] = [];

      const number = getCollectiveOrderConfirmationNumber(collectiveOrderConfirmation);

      if (hasPermission('COLLECTIVE_ORDER_CONFIRMATION:CAN_SEND')) {
        menuItems.push(
          <MenuItem
            as={OrderConfirmationSendingButton}
            orderConfirmation={collectiveOrderConfirmation}
            key="send"
            number={number}
            icon={<FontAwesomeIcon icon={faPaperPlane} fixedWidth />}
            onSend={async (data: OrderConfirmationSendRequestDto) => {
              await collectiveOrderConfirmationApi.sendCollectiveOrderConfirmation({
                id: collectiveOrderConfirmation.id,
                orderConfirmationSendRequestDto: data,
              });
            }}
          >
            {t('order_confirmation:collectiveOrderConfirmation.action.send.label')}
          </MenuItem>,
        );
      }

      if (hasPermission('ORDER_CONFIRMATION:CAN_DOWNLOAD')) {
        menuItems.push(
          <MenuItem
            as={OrderConfirmationDownloadButton}
            orderConfirmation={collectiveOrderConfirmation}
            key="download"
            number={number}
            icon={<FontAwesomeIcon icon={faDownload} fixedWidth />}
          >
            {t('order_confirmation:collectiveOrderConfirmation.action.download.label')}
          </MenuItem>,
        );
      }

      if (hasPermission('COLLECTIVE_ORDER_CONFIRMATION:CAN_PAY')) {
        menuItems.push(
          <MenuItem
            as={RecordManualPaymentButton}
            orderConfirmation={collectiveOrderConfirmation}
            key="payment"
            number={number}
            icon={<FontAwesomeIcon icon={faMoneyBill} fixedWidth />}
          >
            {t('order_confirmation:action.recordManualPayment.label')}
          </MenuItem>,
        );
      }

      if (hasPermission('COLLECTIVE_ORDER_CONFIRMATION:CAN_CANCEL')) {
        menuItems.push(
          <MenuItem
            as={CollectiveOrderConfirmationCancelButton}
            collectiveOrderConfirmation={collectiveOrderConfirmation}
            key="cancel"
            number={number}
            textColor="text.error"
            icon={<FontAwesomeIcon icon={faCircleXmark} fixedWidth />}
          >
            {t('order_confirmation:collectiveOrderConfirmation.action.cancel.label')}
          </MenuItem>,
        );
      }

      return menuItems;
    },
    [hasPermission, t],
  );
}
