import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';

const drivingEditionSettingsRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'driving-edition-settings',
    lazy: () => import('./driving-edition-settings-page/driving-edition-settings-page-route'),
    children: [
      {
        path: '',
        lazy: () => import('./driving-edition-settings-editor/driving-edition-settings-editor-route'),
      },
      {
        path: 'history',
        lazy: () => import('./driving-edition-settings-history/driving-edition-settings-history-route'),
      },
    ],
  },
];

export default drivingEditionSettingsRoutes;
