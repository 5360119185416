/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MailTrackingListItemRecipientDto } from './MailTrackingListItemRecipientDto';
import {
    MailTrackingListItemRecipientDtoFromJSON,
    MailTrackingListItemRecipientDtoFromJSONTyped,
    MailTrackingListItemRecipientDtoToJSON,
} from './MailTrackingListItemRecipientDto';
import type { MailTrackingStatusDto } from './MailTrackingStatusDto';
import {
    MailTrackingStatusDtoFromJSON,
    MailTrackingStatusDtoFromJSONTyped,
    MailTrackingStatusDtoToJSON,
} from './MailTrackingStatusDto';

/**
 * 
 * @export
 * @interface MailTrackingListItemDto
 */
export interface MailTrackingListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof MailTrackingListItemDto
     */
    id: string;
    /**
     * 
     * @type {MailTrackingListItemRecipientDto}
     * @memberof MailTrackingListItemDto
     */
    recipient: MailTrackingListItemRecipientDto;
    /**
     * 
     * @type {string}
     * @memberof MailTrackingListItemDto
     */
    recipientEmail: string;
    /**
     * 
     * @type {MailTrackingStatusDto}
     * @memberof MailTrackingListItemDto
     */
    status: MailTrackingStatusDto;
    /**
     * 
     * @type {Date}
     * @memberof MailTrackingListItemDto
     */
    sentTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof MailTrackingListItemDto
     */
    bounceReason?: string;
    /**
     * 
     * @type {Date}
     * @memberof MailTrackingListItemDto
     */
    bounceTimestamp?: Date;
}

/**
 * Check if a given object implements the MailTrackingListItemDto interface.
 */
export function instanceOfMailTrackingListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('recipient' in value)) return false;
    if (!('recipientEmail' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function MailTrackingListItemDtoFromJSON(json: any): MailTrackingListItemDto {
    return MailTrackingListItemDtoFromJSONTyped(json, false);
}

export function MailTrackingListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailTrackingListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'recipient': MailTrackingListItemRecipientDtoFromJSON(json['recipient']),
        'recipientEmail': json['recipientEmail'],
        'status': MailTrackingStatusDtoFromJSON(json['status']),
        'sentTime': json['sentTime'] == null ? undefined : (new Date(json['sentTime'])),
        'bounceReason': json['bounceReason'] == null ? undefined : json['bounceReason'],
        'bounceTimestamp': json['bounceTimestamp'] == null ? undefined : (new Date(json['bounceTimestamp'])),
    };
}

export function MailTrackingListItemDtoToJSON(value?: MailTrackingListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'recipient': MailTrackingListItemRecipientDtoToJSON(value['recipient']),
        'recipientEmail': value['recipientEmail'],
        'status': MailTrackingStatusDtoToJSON(value['status']),
        'sentTime': value['sentTime'] == null ? undefined : ((value['sentTime']).toISOString()),
        'bounceReason': value['bounceReason'],
        'bounceTimestamp': value['bounceTimestamp'] == null ? undefined : ((value['bounceTimestamp']).toISOString()),
    };
}

