import { useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CollectiveOrderConfirmationStatusDto,
  CollectiveOrderConfirmationViewerDto,
  OrderConfirmationListItemDto,
} from '../../../../api';
import collectiveOrderConfirmationApi from '../../../../data-access/collective-order-confirmation-api';
import { DisableableButton } from '../../../../ui/disableable-button/disableable-button';
import useToast from '../../../../ui/use-toast/use-toast';
import { orderConfirmationFetcher } from '../../order-confirmation-queries';
import { collectiveOrderConfirmationFetcher } from '../collective-order-confirmation-queries';
import CollectiveOrderConfirmationCancelConfirmationDialog from './collective-order-confirmation-cancel-dialog';

interface CollectiveOrderConfirmationCancelButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  collectiveOrderConfirmation: CollectiveOrderConfirmationViewerDto | OrderConfirmationListItemDto;
  number: string;
}

export const CollectiveOrderConfirmationCancelButton = React.forwardRef<
  HTMLButtonElement,
  CollectiveOrderConfirmationCancelButtonProps
>(({ collectiveOrderConfirmation, number, children, ...props }, ref) => {
  const { t } = useTranslation(['order_confirmation']);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const showCancellationSuccessToast = useToast({
    id: 'cancel-success-toast',
    status: 'success',
  });

  const alreadyCancelled = collectiveOrderConfirmation.status === CollectiveOrderConfirmationStatusDto.CANCELLED;

  const invalidStatus =
    collectiveOrderConfirmation.status !== CollectiveOrderConfirmationStatusDto.SEND &&
    collectiveOrderConfirmation.status !== CollectiveOrderConfirmationStatusDto.NOT_SEND;

  const handleSubmit = async (confirm: boolean) => {
    if (!confirm) {
      onClose();
      return;
    }

    await collectiveOrderConfirmationApi.cancel({ id: collectiveOrderConfirmation.id });

    showCancellationSuccessToast({
      title: t('order_confirmation:collectiveOrderConfirmation.action.cancel.title'),
      description: t('order_confirmation:collectiveOrderConfirmation.action.cancel.description', {
        collectiveOrderConfirmationNumber: number,
      }),
    });

    await collectiveOrderConfirmationFetcher.mutate();
    await orderConfirmationFetcher.mutate();

    onClose();
  };

  return (
    <>
      <DisableableButton
        {...props}
        ref={ref}
        onClick={onOpen}
        aria-label={t('order_confirmation:collectiveOrderConfirmation.action.cancel.label')}
        isDisabled={invalidStatus}
        disableReason={
          alreadyCancelled
            ? t('order_confirmation:collectiveOrderConfirmation.action.cancel.alreadyCancelled', {
                collectiveOrderConfirmationNumber: number,
              })
            : t('order_confirmation:collectiveOrderConfirmation.action.cancel.disabled', {
                collectiveOrderConfirmationNumber: number,
              })
        }
      >
        {children}
      </DisableableButton>
      <CollectiveOrderConfirmationCancelConfirmationDialog number={number} onClose={handleSubmit} isOpen={isOpen} />
    </>
  );
});
