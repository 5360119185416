/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AllCompanyDuplicatesPageDto,
  BooleanDto,
  CompanyCommentDto,
  CompanyCommentPageDto,
  CompanyCompanyRelationDto,
  CompanyCompanyRelationPageDto,
  CompanyDto,
  CompanyFilmRelationPageDto,
  CompanyPageDto,
  CompanyReferenceDto,
  CompanyReferencePageDto,
  CompanyStatusCheckDto,
  CreateCompanyFilmRelationsDto,
  FailureDto,
  HistoryPageDto,
  ImportErrorDto,
  ImportSuccessDto,
  MergeCompany409ResponseDto,
  MergeCompanyIdsDto,
  MergedCompanyDto,
  MergedHistoryObjectDto,
  PageableDto,
} from '../models/index';
import {
    AllCompanyDuplicatesPageDtoFromJSON,
    AllCompanyDuplicatesPageDtoToJSON,
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    CompanyCommentDtoFromJSON,
    CompanyCommentDtoToJSON,
    CompanyCommentPageDtoFromJSON,
    CompanyCommentPageDtoToJSON,
    CompanyCompanyRelationDtoFromJSON,
    CompanyCompanyRelationDtoToJSON,
    CompanyCompanyRelationPageDtoFromJSON,
    CompanyCompanyRelationPageDtoToJSON,
    CompanyDtoFromJSON,
    CompanyDtoToJSON,
    CompanyFilmRelationPageDtoFromJSON,
    CompanyFilmRelationPageDtoToJSON,
    CompanyPageDtoFromJSON,
    CompanyPageDtoToJSON,
    CompanyReferenceDtoFromJSON,
    CompanyReferenceDtoToJSON,
    CompanyReferencePageDtoFromJSON,
    CompanyReferencePageDtoToJSON,
    CompanyStatusCheckDtoFromJSON,
    CompanyStatusCheckDtoToJSON,
    CreateCompanyFilmRelationsDtoFromJSON,
    CreateCompanyFilmRelationsDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    ImportErrorDtoFromJSON,
    ImportErrorDtoToJSON,
    ImportSuccessDtoFromJSON,
    ImportSuccessDtoToJSON,
    MergeCompany409ResponseDtoFromJSON,
    MergeCompany409ResponseDtoToJSON,
    MergeCompanyIdsDtoFromJSON,
    MergeCompanyIdsDtoToJSON,
    MergedCompanyDtoFromJSON,
    MergedCompanyDtoToJSON,
    MergedHistoryObjectDtoFromJSON,
    MergedHistoryObjectDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface CheckCompanyStatusRequest {
    companyId: string;
}

export interface CompanyCompanyRelationExistsRequest {
    from: string;
    to: string;
}

export interface CompanyFilmRelationsExistRequest {
    companyId: string;
    filmIds: Array<string>;
}

export interface CreateCompanyRequest {
    companyDto: CompanyDto;
}

export interface CreateCompanyCommentRequest {
    id: string;
    companyCommentDto: CompanyCommentDto;
}

export interface CreateCompanyCompanyRelationRequest {
    id: string;
    companyCompanyRelationDto: CompanyCompanyRelationDto;
}

export interface CreateCompanyFilmRelationsRequest {
    id: string;
    createCompanyFilmRelationsDto: CreateCompanyFilmRelationsDto;
}

export interface DeleteCompanyRequest {
    id: string;
}

export interface DeleteCompanyCommentRequest {
    id: string;
    commentId: string;
}

export interface DeleteCompanyFilmRelationRequest {
    id: string;
    relationId: string;
}

export interface FetchCompanyRequest {
    id: string;
}

export interface FetchCompanyCommentsRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FetchCompanyFilmHistoryRequest {
    companyId?: string;
    pageable?: PageableDto;
}

export interface FetchCompanyHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FetchFilmCompanyHistoryRequest {
    filmId?: string;
    pageable?: PageableDto;
}

export interface FetchMergedHistoryCompanyRequest {
    id: string;
}

export interface FetchRelatedCompaniesForCompanyRequest {
    id: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface GetAllPossibleCompanyDuplicatesRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface GetExistingPossibleCompanyDuplicatesRequest {
    id: string;
}

export interface GetPossibleCompanyDuplicatesRequest {
    name: string;
    id?: string;
    synonyms?: Array<string>;
    publicationNames?: Array<string>;
}

export interface HasTaxExemptionRequest {
    id: string;
}

export interface ImportCompanyRequest {
    file: Blob;
}

export interface MergeCompanyRequest {
    mergedCompanyDto: MergedCompanyDto;
}

export interface NoCompanyDuplicateRequest {
    mergeCompanyIdsDto: MergeCompanyIdsDto;
}

export interface SearchActiveCompanyReferencesRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchCompaniesRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchCompanyFilmRelationListItemsRequest {
    id: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchFilmCompanyRelationListItemsRequest {
    filmId: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface TerminateCompanyCompanyRelationRequest {
    relationId: string;
}

export interface UpdateCompanyRequest {
    id: string;
    companyDto: CompanyDto;
}

export interface UpdateCompanyCommentRequest {
    id: string;
    commentId: string;
    companyCommentDto: CompanyCommentDto;
}

/**
 * 
 */
export class CompanyApi extends runtime.BaseAPI {

    /**
     * Get the current status of the company.
     */
    async checkCompanyStatusRaw(requestParameters: CheckCompanyStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyStatusCheckDto>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling checkCompanyStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/{companyId}/check-status`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters['companyId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyStatusCheckDtoFromJSON(jsonValue));
    }

    /**
     * Get the current status of the company.
     */
    async checkCompanyStatus(requestParameters: CheckCompanyStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyStatusCheckDto> {
        const response = await this.checkCompanyStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check whether there is already a relation between two companies. The direction of the relation is not considered. 
     */
    async companyCompanyRelationExistsRaw(requestParameters: CompanyCompanyRelationExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['from'] == null) {
            throw new runtime.RequiredError(
                'from',
                'Required parameter "from" was null or undefined when calling companyCompanyRelationExists().'
            );
        }

        if (requestParameters['to'] == null) {
            throw new runtime.RequiredError(
                'to',
                'Required parameter "to" was null or undefined when calling companyCompanyRelationExists().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['from'] != null) {
            queryParameters['from'] = requestParameters['from'];
        }

        if (requestParameters['to'] != null) {
            queryParameters['to'] = requestParameters['to'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/company-relation-exists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Check whether there is already a relation between two companies. The direction of the relation is not considered. 
     */
    async companyCompanyRelationExists(requestParameters: CompanyCompanyRelationExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.companyCompanyRelationExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check for existing relations between company and given film ids
     */
    async companyFilmRelationsExistRaw(requestParameters: CompanyFilmRelationsExistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling companyFilmRelationsExist().'
            );
        }

        if (requestParameters['filmIds'] == null) {
            throw new runtime.RequiredError(
                'filmIds',
                'Required parameter "filmIds" was null or undefined when calling companyFilmRelationsExist().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['filmIds'] != null) {
            queryParameters['filmIds'] = requestParameters['filmIds']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/film-relation-exists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Check for existing relations between company and given film ids
     */
    async companyFilmRelationsExist(requestParameters: CompanyFilmRelationsExistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.companyFilmRelationsExistRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new company. New company will be active.
     */
    async createCompanyRaw(requestParameters: CreateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyDto>> {
        if (requestParameters['companyDto'] == null) {
            throw new runtime.RequiredError(
                'companyDto',
                'Required parameter "companyDto" was null or undefined when calling createCompany().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyDtoToJSON(requestParameters['companyDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDtoFromJSON(jsonValue));
    }

    /**
     * Create a new company. New company will be active.
     */
    async createCompany(requestParameters: CreateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyDto> {
        const response = await this.createCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new comment.
     */
    async createCompanyCommentRaw(requestParameters: CreateCompanyCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyCommentDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling createCompanyComment().'
            );
        }

        if (requestParameters['companyCommentDto'] == null) {
            throw new runtime.RequiredError(
                'companyCommentDto',
                'Required parameter "companyCommentDto" was null or undefined when calling createCompanyComment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/{id}/comments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyCommentDtoToJSON(requestParameters['companyCommentDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyCommentDtoFromJSON(jsonValue));
    }

    /**
     * Create a new comment.
     */
    async createCompanyComment(requestParameters: CreateCompanyCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyCommentDto> {
        const response = await this.createCompanyCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add a relation between two companies
     */
    async createCompanyCompanyRelationRaw(requestParameters: CreateCompanyCompanyRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling createCompanyCompanyRelation().'
            );
        }

        if (requestParameters['companyCompanyRelationDto'] == null) {
            throw new runtime.RequiredError(
                'companyCompanyRelationDto',
                'Required parameter "companyCompanyRelationDto" was null or undefined when calling createCompanyCompanyRelation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/{id}/create-company-company-relation`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyCompanyRelationDtoToJSON(requestParameters['companyCompanyRelationDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add a relation between two companies
     */
    async createCompanyCompanyRelation(requestParameters: CreateCompanyCompanyRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createCompanyCompanyRelationRaw(requestParameters, initOverrides);
    }

    /**
     * Create multiple film relations for the company.
     */
    async createCompanyFilmRelationsRaw(requestParameters: CreateCompanyFilmRelationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling createCompanyFilmRelations().'
            );
        }

        if (requestParameters['createCompanyFilmRelationsDto'] == null) {
            throw new runtime.RequiredError(
                'createCompanyFilmRelationsDto',
                'Required parameter "createCompanyFilmRelationsDto" was null or undefined when calling createCompanyFilmRelations().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/{id}/films`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCompanyFilmRelationsDtoToJSON(requestParameters['createCompanyFilmRelationsDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create multiple film relations for the company.
     */
    async createCompanyFilmRelations(requestParameters: CreateCompanyFilmRelationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createCompanyFilmRelationsRaw(requestParameters, initOverrides);
    }

    /**
     * delete company
     */
    async deleteCompanyRaw(requestParameters: DeleteCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteCompany().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete company
     */
    async deleteCompany(requestParameters: DeleteCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCompanyRaw(requestParameters, initOverrides);
    }

    /**
     * Delete the comment.
     */
    async deleteCompanyCommentRaw(requestParameters: DeleteCompanyCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteCompanyComment().'
            );
        }

        if (requestParameters['commentId'] == null) {
            throw new runtime.RequiredError(
                'commentId',
                'Required parameter "commentId" was null or undefined when calling deleteCompanyComment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/{id}/comments/{commentId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters['commentId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the comment.
     */
    async deleteCompanyComment(requestParameters: DeleteCompanyCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCompanyCommentRaw(requestParameters, initOverrides);
    }

    /**
     * Delete company-film relation.
     */
    async deleteCompanyFilmRelationRaw(requestParameters: DeleteCompanyFilmRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteCompanyFilmRelation().'
            );
        }

        if (requestParameters['relationId'] == null) {
            throw new runtime.RequiredError(
                'relationId',
                'Required parameter "relationId" was null or undefined when calling deleteCompanyFilmRelation().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['relationId'] != null) {
            queryParameters['relationId'] = requestParameters['relationId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/{id}/films`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete company-film relation.
     */
    async deleteCompanyFilmRelation(requestParameters: DeleteCompanyFilmRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCompanyFilmRelationRaw(requestParameters, initOverrides);
    }

    /**
     * Get the company business object.
     */
    async fetchCompanyRaw(requestParameters: FetchCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchCompany().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDtoFromJSON(jsonValue));
    }

    /**
     * Get the company business object.
     */
    async fetchCompany(requestParameters: FetchCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyDto> {
        const response = await this.fetchCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the comments for a company
     */
    async fetchCompanyCommentsRaw(requestParameters: FetchCompanyCommentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyCommentPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchCompanyComments().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/{id}/comments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyCommentPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the comments for a company
     */
    async fetchCompanyComments(requestParameters: FetchCompanyCommentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyCommentPageDto> {
        const response = await this.fetchCompanyCommentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fetchCompanyFilmHistoryRaw(requestParameters: FetchCompanyFilmHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/company-film-relation-versions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchCompanyFilmHistory(requestParameters: FetchCompanyFilmHistoryRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchCompanyFilmHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the company.
     */
    async fetchCompanyHistoryRaw(requestParameters: FetchCompanyHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchCompanyHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the company.
     */
    async fetchCompanyHistory(requestParameters: FetchCompanyHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchCompanyHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fetchFilmCompanyHistoryRaw(requestParameters: FetchFilmCompanyHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filmId'] != null) {
            queryParameters['filmId'] = requestParameters['filmId'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/film-company-relation-versions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchFilmCompanyHistory(requestParameters: FetchFilmCompanyHistoryRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchFilmCompanyHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a company reference
     */
    async fetchMergedHistoryCompanyRaw(requestParameters: FetchMergedHistoryCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MergedHistoryObjectDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchMergedHistoryCompany().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/{id}/merge-history-company`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MergedHistoryObjectDtoFromJSON(jsonValue));
    }

    /**
     * Get a company reference
     */
    async fetchMergedHistoryCompany(requestParameters: FetchMergedHistoryCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MergedHistoryObjectDto> {
        const response = await this.fetchMergedHistoryCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List related companies for a company.
     */
    async fetchRelatedCompaniesForCompanyRaw(requestParameters: FetchRelatedCompaniesForCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyCompanyRelationPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchRelatedCompaniesForCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/{id}/related-companies`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyCompanyRelationPageDtoFromJSON(jsonValue));
    }

    /**
     * List related companies for a company.
     */
    async fetchRelatedCompaniesForCompany(requestParameters: FetchRelatedCompaniesForCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyCompanyRelationPageDto> {
        const response = await this.fetchRelatedCompaniesForCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all possible company duplicates sorted and paginated.
     */
    async getAllPossibleCompanyDuplicatesRaw(requestParameters: GetAllPossibleCompanyDuplicatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AllCompanyDuplicatesPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/all-duplicates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AllCompanyDuplicatesPageDtoFromJSON(jsonValue));
    }

    /**
     * Get all possible company duplicates sorted and paginated.
     */
    async getAllPossibleCompanyDuplicates(requestParameters: GetAllPossibleCompanyDuplicatesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AllCompanyDuplicatesPageDto> {
        const response = await this.getAllPossibleCompanyDuplicatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all possible duplicates of the company
     */
    async getExistingPossibleCompanyDuplicatesRaw(requestParameters: GetExistingPossibleCompanyDuplicatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CompanyReferenceDto>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getExistingPossibleCompanyDuplicates().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/{id}/existing-possible-duplicates`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyReferenceDtoFromJSON));
    }

    /**
     * Get all possible duplicates of the company
     */
    async getExistingPossibleCompanyDuplicates(requestParameters: GetExistingPossibleCompanyDuplicatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CompanyReferenceDto>> {
        const response = await this.getExistingPossibleCompanyDuplicatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all possible duplicates of the company using name and optionally an address
     */
    async getPossibleCompanyDuplicatesRaw(requestParameters: GetPossibleCompanyDuplicatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CompanyReferenceDto>>> {
        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling getPossibleCompanyDuplicates().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['synonyms'] != null) {
            queryParameters['synonyms'] = requestParameters['synonyms'];
        }

        if (requestParameters['publicationNames'] != null) {
            queryParameters['publicationNames'] = requestParameters['publicationNames'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/possible-duplicates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyReferenceDtoFromJSON));
    }

    /**
     * Get all possible duplicates of the company using name and optionally an address
     */
    async getPossibleCompanyDuplicates(requestParameters: GetPossibleCompanyDuplicatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CompanyReferenceDto>> {
        const response = await this.getPossibleCompanyDuplicatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get weather the company has a tax exemption in the current edition
     */
    async hasTaxExemptionRaw(requestParameters: HasTaxExemptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling hasTaxExemption().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/{id}/has-tax-exemption`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     * Get weather the company has a tax exemption in the current edition
     */
    async hasTaxExemption(requestParameters: HasTaxExemptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.hasTaxExemptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Import company of the CSV
     */
    async importCompanyRaw(requestParameters: ImportCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImportSuccessDto>> {
        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling importCompany().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/companies/import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImportSuccessDtoFromJSON(jsonValue));
    }

    /**
     * Import company of the CSV
     */
    async importCompany(requestParameters: ImportCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImportSuccessDto> {
        const response = await this.importCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Merge two companies and create a new one.
     */
    async mergeCompanyRaw(requestParameters: MergeCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyDto>> {
        if (requestParameters['mergedCompanyDto'] == null) {
            throw new runtime.RequiredError(
                'mergedCompanyDto',
                'Required parameter "mergedCompanyDto" was null or undefined when calling mergeCompany().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/merge`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MergedCompanyDtoToJSON(requestParameters['mergedCompanyDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDtoFromJSON(jsonValue));
    }

    /**
     * Merge two companies and create a new one.
     */
    async mergeCompany(requestParameters: MergeCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyDto> {
        const response = await this.mergeCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mark two companies as no duplicates
     */
    async noCompanyDuplicateRaw(requestParameters: NoCompanyDuplicateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['mergeCompanyIdsDto'] == null) {
            throw new runtime.RequiredError(
                'mergeCompanyIdsDto',
                'Required parameter "mergeCompanyIdsDto" was null or undefined when calling noCompanyDuplicate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/no-duplicate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MergeCompanyIdsDtoToJSON(requestParameters['mergeCompanyIdsDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark two companies as no duplicates
     */
    async noCompanyDuplicate(requestParameters: NoCompanyDuplicateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.noCompanyDuplicateRaw(requestParameters, initOverrides);
    }

    /**
     * Search for active company references.
     */
    async searchActiveCompanyReferencesRaw(requestParameters: SearchActiveCompanyReferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyReferencePageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/company-references`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyReferencePageDtoFromJSON(jsonValue));
    }

    /**
     * Search for active company references.
     */
    async searchActiveCompanyReferences(requestParameters: SearchActiveCompanyReferencesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyReferencePageDto> {
        const response = await this.searchActiveCompanyReferencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for companies.
     */
    async searchCompaniesRaw(requestParameters: SearchCompaniesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for companies.
     */
    async searchCompanies(requestParameters: SearchCompaniesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyPageDto> {
        const response = await this.searchCompaniesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for company-film connection list items.
     */
    async searchCompanyFilmRelationListItemsRaw(requestParameters: SearchCompanyFilmRelationListItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyFilmRelationPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling searchCompanyFilmRelationListItems().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/{id}/films`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyFilmRelationPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for company-film connection list items.
     */
    async searchCompanyFilmRelationListItems(requestParameters: SearchCompanyFilmRelationListItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyFilmRelationPageDto> {
        const response = await this.searchCompanyFilmRelationListItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for company-film connection list items by film.
     */
    async searchFilmCompanyRelationListItemsRaw(requestParameters: SearchFilmCompanyRelationListItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyFilmRelationPageDto>> {
        if (requestParameters['filmId'] == null) {
            throw new runtime.RequiredError(
                'filmId',
                'Required parameter "filmId" was null or undefined when calling searchFilmCompanyRelationListItems().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filmId'] != null) {
            queryParameters['filmId'] = requestParameters['filmId'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/films`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyFilmRelationPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for company-film connection list items by film.
     */
    async searchFilmCompanyRelationListItems(requestParameters: SearchFilmCompanyRelationListItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyFilmRelationPageDto> {
        const response = await this.searchFilmCompanyRelationListItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new company.
     */
    async templateCompanyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new company.
     */
    async templateCompany(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyDto> {
        const response = await this.templateCompanyRaw(initOverrides);
        return await response.value();
    }

    /**
     * Delete a relation between two companies
     */
    async terminateCompanyCompanyRelationRaw(requestParameters: TerminateCompanyCompanyRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['relationId'] == null) {
            throw new runtime.RequiredError(
                'relationId',
                'Required parameter "relationId" was null or undefined when calling terminateCompanyCompanyRelation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/company-relation/{relationId}`.replace(`{${"relationId"}}`, encodeURIComponent(String(requestParameters['relationId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a relation between two companies
     */
    async terminateCompanyCompanyRelation(requestParameters: TerminateCompanyCompanyRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.terminateCompanyCompanyRelationRaw(requestParameters, initOverrides);
    }

    /**
     * Update a company.
     */
    async updateCompanyRaw(requestParameters: UpdateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateCompany().'
            );
        }

        if (requestParameters['companyDto'] == null) {
            throw new runtime.RequiredError(
                'companyDto',
                'Required parameter "companyDto" was null or undefined when calling updateCompany().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyDtoToJSON(requestParameters['companyDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDtoFromJSON(jsonValue));
    }

    /**
     * Update a company.
     */
    async updateCompany(requestParameters: UpdateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyDto> {
        const response = await this.updateCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the existing comment.
     */
    async updateCompanyCommentRaw(requestParameters: UpdateCompanyCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyCommentDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateCompanyComment().'
            );
        }

        if (requestParameters['commentId'] == null) {
            throw new runtime.RequiredError(
                'commentId',
                'Required parameter "commentId" was null or undefined when calling updateCompanyComment().'
            );
        }

        if (requestParameters['companyCommentDto'] == null) {
            throw new runtime.RequiredError(
                'companyCommentDto',
                'Required parameter "companyCommentDto" was null or undefined when calling updateCompanyComment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/{id}/comments/{commentId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters['commentId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyCommentDtoToJSON(requestParameters['companyCommentDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyCommentDtoFromJSON(jsonValue));
    }

    /**
     * Update the existing comment.
     */
    async updateCompanyComment(requestParameters: UpdateCompanyCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyCommentDto> {
        const response = await this.updateCompanyCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
