import { useMemo } from 'react';
import invariant from 'tiny-invariant';
import useFormattedValue from './use-formatted-value';

interface UseFormattedNumberInputProps {
  value: string | number;
  onChange(value: string | number): void;
  minFractionDigits?: number;
  maxFractionDigits?: number;
  useGrouping?: boolean;
}

export default function useFormattedNumberInput({
  value,
  onChange,
  minFractionDigits,
  maxFractionDigits,
  useGrouping,
}: UseFormattedNumberInputProps) {
  // Number formatter to format the field value.
  const formatter = useMemo(() => {
    const formatter = new Intl.NumberFormat('de-DE', {
      style: 'decimal',
      minimumFractionDigits: minFractionDigits,
      maximumFractionDigits: maxFractionDigits,
      useGrouping,
    });

    return (value: number) => formatter.format(value);
  }, [minFractionDigits, maxFractionDigits, useGrouping]);

  // Number parser to parse the input value.
  const parser = useMemo(() => createNumberParser('de-DE'), []);

  return useFormattedValue({
    value,
    onChange,
    format: (value) =>
      typeof value === 'number'
        ? // If the field value is a number, format it with the formatter.
          formatter(value)
        : // Otherwise, display the field value as is (must be invalid).
          value,
    parse: (value) => {
      const number = parser(value);
      return isNaN(number) ? value : number;
    },
  });
}

/**
 * @see https://observablehq.com/@mbostock/localized-number-parsing
 */
function createNumberParser(locale: 'de-DE') {
  const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
  const numerals = [...Intl.NumberFormat(locale, { useGrouping: false }).format(9876543210)].reverse();
  const index = new Map(numerals.map((d, i) => [d, String(i)]));

  const groupRegExp = new RegExp(`[${parts.find((d) => d.type === 'group')?.value}]`, 'g');
  const decimalRegExp = new RegExp(`[${parts.find((d) => d.type === 'decimal')?.value}]`);
  const numeralRegExp = new RegExp(`[${numerals.join('')}]`, 'g');

  return (numberString: string) => {
    let parsedNumberString = numberString.trim();

    if (parsedNumberString === '') {
      return NaN;
    }

    parsedNumberString = parsedNumberString
      .replace(groupRegExp, '')
      .replace(decimalRegExp, '.')
      .replace(numeralRegExp, (d) => {
        const numeral = index.get(d);
        invariant(numeral != null, 'Invalid numeral');

        return numeral;
      });

    return +parsedNumberString;
  };
}
