/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehiclePermissionDto
 */
export interface VehiclePermissionDto {
    /**
     * 
     * @type {string}
     * @memberof VehiclePermissionDto
     */
    name: VehiclePermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof VehiclePermissionDto
     */
    action: VehiclePermissionDtoActionEnum;
}


/**
 * @export
 */
export const VehiclePermissionDtoNameEnum = {
    VEHICLE: 'VEHICLE'
} as const;
export type VehiclePermissionDtoNameEnum = typeof VehiclePermissionDtoNameEnum[keyof typeof VehiclePermissionDtoNameEnum];

/**
 * @export
 */
export const VehiclePermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_LIST_VIEW_OWN: 'CAN_SEE_LIST_VIEW_OWN',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    SEE_DETAILS_OWN: 'CAN_SEE_DETAILS_OWN',
    EDIT: 'CAN_EDIT',
    CREATE: 'CAN_CREATE',
    WITHDRAW: 'CAN_WITHDRAW'
} as const;
export type VehiclePermissionDtoActionEnum = typeof VehiclePermissionDtoActionEnum[keyof typeof VehiclePermissionDtoActionEnum];


/**
 * Check if a given object implements the VehiclePermissionDto interface.
 */
export function instanceOfVehiclePermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function VehiclePermissionDtoFromJSON(json: any): VehiclePermissionDto {
    return VehiclePermissionDtoFromJSONTyped(json, false);
}

export function VehiclePermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehiclePermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function VehiclePermissionDtoToJSON(value?: VehiclePermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

