import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useDrivingEditionSettingsPermissionClassGroupExtension(): PermissionClassGroupExtension<'DRIVING_EDITION_SETTINGS'> {
  const { t } = useTranslation(['driving']);

  return {
    name: 'DRIVING_EDITION_SETTINGS',
    label: t('driving:globalSettings.label'),
    getActionLabel: (action) => t(`driving:globalSettings.permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`driving:globalSettings.permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`driving:globalSettings.permissionInfo.${action}`, { defaultValue: '' }),
  };
}
