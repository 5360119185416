import { Box, Button, ButtonGroup, Menu, MenuItem, MenuList, Portal } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload, faHistory, faHomeAlt, faMoneyBill, faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderFunctionArgs, Outlet, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { OrderConfirmationSendRequestDto, OrderConfirmationStatusDto } from '../../../api';
import orderConfirmationApi from '../../../data-access/order-confirmation-api';
import { BurgerMenuButton } from '../../../ui/burger-menu-button/burger-menu-button';
import { DisableableRouterLink } from '../../../ui/disableable-button/disableable-router-link';
import { PageHeader, PageTab } from '../../../ui/page';
import Page from '../../../ui/page/page';
import usePlugins, { PluginToken } from '../../../util/plugin/use-plugins';
import useFetcher from '../../../util/swr/use-fetcher';
import usePermission from '../../permission/use-permission';
import { OrderConfirmationDownloadButton } from '../order-confirmation-download-button/order-confirmation-download-button';
import { fetchOrderConfirmation } from '../order-confirmation-queries';
import { OrderConfirmationSendingButton } from '../order-confirmation-sending-button/order-confirmation-sending-button';
import { RecordManualPaymentButton } from '../record-manual-payment-button/record-manual-payment-button';

export interface OrderConfirmationTabItem {
  order: number;
  label: React.ReactNode;
  icon: IconProp;
  to: string;
}

export const ORDER_CONFIRMATION_TAB_ITEM = new PluginToken<OrderConfirmationTabItem>('OrderConfirmationTabItem');

export const Component = OrderConfirmationPageRoute;

export const loader = ({ params }: LoaderFunctionArgs) => {
  return fetchOrderConfirmation.mutate({ id: String(params.orderConfirmationId) });
};

function OrderConfirmationPageRoute() {
  const { t } = useTranslation(['order_confirmation', 'common']);
  const { hasPermission } = usePermission();
  const { orderConfirmationId } = useParams<{ orderConfirmationId: string }>();

  invariant(orderConfirmationId, 'Empty orderConfirmationId');
  const orderConfirmation = useFetcher(fetchOrderConfirmation, { id: orderConfirmationId });
  const tabs = <OrderConfirmationPageTabs />;
  return (
    <Page
      header={
        <PageHeader
          title={orderConfirmation.orderConfirmationNumber}
          tabs={tabs}
          actions={
            <ButtonGroup>
              {(hasPermission('ORDER_CONFIRMATION:CAN_SEND') ||
                hasPermission('ORDER_CONFIRMATION:CAN_DOWNLOAD') ||
                hasPermission('ORDER_CONFIRMATION:CAN_PAY')) && (
                <Box>
                  <Menu>
                    <BurgerMenuButton />
                    <Portal>
                      <MenuList>
                        {hasPermission('ORDER_CONFIRMATION:CAN_SEND') && (
                          <MenuItem
                            as={OrderConfirmationSendingButton}
                            orderConfirmation={orderConfirmation}
                            number={orderConfirmation.orderConfirmationNumber}
                            icon={<FontAwesomeIcon icon={faPaperPlane} fixedWidth />}
                            onSend={async (data: OrderConfirmationSendRequestDto) => {
                              await orderConfirmationApi.sendOrderConfirmation({
                                id: orderConfirmation.id,
                                orderConfirmationSendRequestDto: data,
                              });
                            }}
                          >
                            {t('order_confirmation:action.send.label')}
                          </MenuItem>
                        )}
                        {hasPermission('ORDER_CONFIRMATION:CAN_DOWNLOAD') && (
                          <MenuItem
                            as={OrderConfirmationDownloadButton}
                            orderConfirmation={orderConfirmation}
                            number={orderConfirmation.orderConfirmationNumber}
                            icon={<FontAwesomeIcon icon={faDownload} fixedWidth />}
                          >
                            {t('order_confirmation:action.download.label')}
                          </MenuItem>
                        )}
                        {hasPermission('ORDER_CONFIRMATION:CAN_PAY') && (
                          <MenuItem
                            as={RecordManualPaymentButton}
                            orderConfirmation={orderConfirmation}
                            number={orderConfirmation.orderConfirmationNumber}
                            icon={<FontAwesomeIcon icon={faMoneyBill} fixedWidth />}
                          >
                            {t('order_confirmation:action.recordManualPayment.label')}
                          </MenuItem>
                        )}
                      </MenuList>
                    </Portal>
                  </Menu>
                </Box>
              )}
              {hasPermission('ORDER_CONFIRMATION:CAN_EDIT') && (
                <Button
                  as={DisableableRouterLink}
                  to="edit"
                  variant="primary"
                  isDisabled={
                    !(
                      orderConfirmation.status === OrderConfirmationStatusDto.NOT_SEND ||
                      orderConfirmation.status === OrderConfirmationStatusDto.SEND ||
                      (orderConfirmation.status === OrderConfirmationStatusDto.PAID &&
                        orderConfirmation.paymentResult != null &&
                        !orderConfirmation.paymentResult.generateInvoice)
                    )
                  }
                  disableReason={
                    orderConfirmation.status === OrderConfirmationStatusDto.INVOICE_GENERATED
                      ? t('order_confirmation:editor.invoiceGeneratedMessage')
                      : t('common:toast.status_conflict.description')
                  }
                >
                  {t('order_confirmation:action.edit')}
                </Button>
              )}
            </ButtonGroup>
          }
        />
      }
    >
      <Outlet />
    </Page>
  );
}

function OrderConfirmationPageTabs() {
  const { t } = useTranslation(['common']);
  const tabItems = usePlugins(ORDER_CONFIRMATION_TAB_ITEM);
  const sortedTabItems = React.useMemo(() => sortBy(tabItems, 'order'), [tabItems]);
  return (
    <>
      <PageTab to="." icon={faHomeAlt} />
      {sortedTabItems.map((tabItem, index) => (
        <PageTab key={'additionalTab' + index} to={tabItem.to} icon={tabItem.icon}>
          {tabItem.label}
        </PageTab>
      ))}
      <PageTab to="./history" icon={faHistory}>
        {t('common:history.label')}
      </PageTab>
    </>
  );
}
