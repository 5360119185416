import { Center } from '@chakra-ui/react';
import React from 'react';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import DrivingAssignmentBreadcrumb from './common/driving-assignment-breadcrumb';

const drivingAssignmentRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'driving-assignments',
    handle: {
      breadcrumb: <Translate ns="driving" i18nKey="assignment.label" />,
    },
    children: [
      {
        path: '',
        // lister
        element: <Center>Lister folgt</Center>,
      },
      {
        path: ':drivingAssignmentId',
        handle: {
          breadcrumb: <DrivingAssignmentBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./driving-assignment-page/driving-assignment-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./driving-assignment-viewer/driving-assignment-viewer-route'),
              },
              {
                path: 'history',
                lazy: () => import('./driving-assignment-history/driving-assignment-history-route'),
              },
            ],
          },
        ],
      },
    ],
  },
];

export default drivingAssignmentRoutes;
