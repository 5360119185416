import React from 'react';
import { placementIcon } from '../../../ui/icons/business-objects';
import { PluginRegistry } from '../../../util/plugin/use-plugins';
import Translate from '../../../util/translate/translate';
import EVENT_CHILD_ROUTES from '../../event/event-child-routes';
import EVENT_CHILD_ROUTES_MOBILE from '../../event/event-child-routes-mobile';
import { EVENT_MENU_ITEM, EVENT_MOBILE_MENU_ITEM, EventMenuItem } from '../../event/event-page/event-page-menu';
import { GUEST_LIST_DELETE_ALLOCATION_EXTENSION } from '../../guest-list/guest-list-form/delete-allocation-extension';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../../permission-class-group/permission-class-group-extension';
import { deleteAllocationExtension } from './guest-list-extensions/delete-allocation-extension';
import { usePlacementPermissionClassGroupExtension } from './placement-permissions';
import placementRoutes, { placementRoutesMobile } from './placement-routes';

function createPlacementEventMenuItem(isMobile: boolean = false): EventMenuItem {
  return {
    order: 3,
    label: <Translate ns="placement" i18nKey="placement" />,
    icon: placementIcon,
    path: (eventId) => `${isMobile ? '/mobile' : ''}/events/${eventId}/placement`,
    isVisible: (ownerSectionIds, permissions) =>
      permissions.hasPermission('PLACEMENT:CAN_SEE_DETAILS') ||
      ownerSectionIds.some((sectionId) =>
        permissions.hasPermissionFromSection(sectionId, 'PLACEMENT:CAN_SEE_DETAILS_OWN'),
      ),
  };
}

export default function PlacementPluginProvider(registry: PluginRegistry) {
  registry.register(EVENT_MENU_ITEM, createPlacementEventMenuItem());
  registry.register(EVENT_MOBILE_MENU_ITEM, createPlacementEventMenuItem(true));
  registry.register(EVENT_CHILD_ROUTES, placementRoutes);
  registry.register(EVENT_CHILD_ROUTES_MOBILE, placementRoutesMobile);
  registry.register(GUEST_LIST_DELETE_ALLOCATION_EXTENSION, deleteAllocationExtension);
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, usePlacementPermissionClassGroupExtension);
}
