import React from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchCollectiveOrderConfirmation } from './collective-order-confirmation-queries';

export default function CollectiveOrderConfirmationBreadcrumb() {
  const { collectiveOrderConfirmationId: collectiveOrderConfirmationId } = useParams<{
    collectiveOrderConfirmationId: string;
  }>();
  invariant(collectiveOrderConfirmationId, 'Empty collectiveOrderConfirmationId');
  const collective = useFetcher(fetchCollectiveOrderConfirmation, { id: collectiveOrderConfirmationId });

  return <>{collective.collectiveOrderConfirmationNumber}</>;
}
