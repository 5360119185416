/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccessibilityDto } from './AccessibilityDto';
import {
    AccessibilityDtoFromJSON,
    AccessibilityDtoFromJSONTyped,
    AccessibilityDtoToJSON,
} from './AccessibilityDto';
import type { AccessoryItemDto } from './AccessoryItemDto';
import {
    AccessoryItemDtoFromJSON,
    AccessoryItemDtoFromJSONTyped,
    AccessoryItemDtoToJSON,
} from './AccessoryItemDto';
import type { DriverStatusDto } from './DriverStatusDto';
import {
    DriverStatusDtoFromJSON,
    DriverStatusDtoFromJSONTyped,
    DriverStatusDtoToJSON,
} from './DriverStatusDto';
import type { DrivingAssignmentStatusDto } from './DrivingAssignmentStatusDto';
import {
    DrivingAssignmentStatusDtoFromJSON,
    DrivingAssignmentStatusDtoFromJSONTyped,
    DrivingAssignmentStatusDtoToJSON,
} from './DrivingAssignmentStatusDto';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { EventReferenceDto } from './EventReferenceDto';
import {
    EventReferenceDtoFromJSON,
    EventReferenceDtoFromJSONTyped,
    EventReferenceDtoToJSON,
} from './EventReferenceDto';
import type { FavouredVehicleItemDto } from './FavouredVehicleItemDto';
import {
    FavouredVehicleItemDtoFromJSON,
    FavouredVehicleItemDtoFromJSONTyped,
    FavouredVehicleItemDtoToJSON,
} from './FavouredVehicleItemDto';
import type { GroupReferenceDto } from './GroupReferenceDto';
import {
    GroupReferenceDtoFromJSON,
    GroupReferenceDtoFromJSONTyped,
    GroupReferenceDtoToJSON,
} from './GroupReferenceDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { ReasonForJourneyDto } from './ReasonForJourneyDto';
import {
    ReasonForJourneyDtoFromJSON,
    ReasonForJourneyDtoFromJSONTyped,
    ReasonForJourneyDtoToJSON,
} from './ReasonForJourneyDto';
import type { StaffReferenceDto } from './StaffReferenceDto';
import {
    StaffReferenceDtoFromJSON,
    StaffReferenceDtoFromJSONTyped,
    StaffReferenceDtoToJSON,
} from './StaffReferenceDto';
import type { StopoverDto } from './StopoverDto';
import {
    StopoverDtoFromJSON,
    StopoverDtoFromJSONTyped,
    StopoverDtoToJSON,
} from './StopoverDto';
import type { VehicleBookingReferenceDto } from './VehicleBookingReferenceDto';
import {
    VehicleBookingReferenceDtoFromJSON,
    VehicleBookingReferenceDtoFromJSONTyped,
    VehicleBookingReferenceDtoToJSON,
} from './VehicleBookingReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * Next step after booking a vehicle for the transportation of guests or film teams
 * @export
 * @interface DrivingAssignmentDto
 */
export interface DrivingAssignmentDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof DrivingAssignmentDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof DrivingAssignmentDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof DrivingAssignmentDto
     */
    drivingAssignmentNumber: string;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof DrivingAssignmentDto
     */
    edition: EditionReferenceDto;
    /**
     * 
     * @type {VehicleBookingReferenceDto}
     * @memberof DrivingAssignmentDto
     */
    vehicleBooking?: VehicleBookingReferenceDto;
    /**
     * 
     * @type {StaffReferenceDto}
     * @memberof DrivingAssignmentDto
     */
    principal: StaffReferenceDto;
    /**
     * Guest relation managers who deal with the guest.
     * @type {Array<StaffReferenceDto>}
     * @memberof DrivingAssignmentDto
     */
    involvedParties?: Array<StaffReferenceDto>;
    /**
     * 
     * @type {StaffReferenceDto}
     * @memberof DrivingAssignmentDto
     */
    localContact?: StaffReferenceDto;
    /**
     * 
     * @type {Date}
     * @memberof DrivingAssignmentDto
     */
    pickUpTime: Date;
    /**
     * 
     * @type {string}
     * @memberof DrivingAssignmentDto
     */
    startingPoint?: string;
    /**
     * 
     * @type {Array<StopoverDto>}
     * @memberof DrivingAssignmentDto
     */
    stopovers?: Array<StopoverDto>;
    /**
     * 
     * @type {Date}
     * @memberof DrivingAssignmentDto
     */
    dropOffTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof DrivingAssignmentDto
     */
    destination?: string;
    /**
     * 
     * @type {ReasonForJourneyDto}
     * @memberof DrivingAssignmentDto
     */
    reasonForJourney: ReasonForJourneyDto;
    /**
     * 
     * @type {EventReferenceDto}
     * @memberof DrivingAssignmentDto
     */
    event?: EventReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof DrivingAssignmentDto
     */
    travelDetails?: string;
    /**
     * 
     * @type {AccessibilityDto}
     * @memberof DrivingAssignmentDto
     */
    accessibility: AccessibilityDto;
    /**
     * 
     * @type {Array<AccessoryItemDto>}
     * @memberof DrivingAssignmentDto
     */
    accessories: Array<AccessoryItemDto>;
    /**
     * 
     * @type {Array<FavouredVehicleItemDto>}
     * @memberof DrivingAssignmentDto
     */
    favouredVehicles: Array<FavouredVehicleItemDto>;
    /**
     * 
     * @type {Array<PersonReferenceDto>}
     * @memberof DrivingAssignmentDto
     */
    personPassengers?: Array<PersonReferenceDto>;
    /**
     * 
     * @type {string}
     * @memberof DrivingAssignmentDto
     */
    customPassengers?: string;
    /**
     * 
     * @type {Array<GroupReferenceDto>}
     * @memberof DrivingAssignmentDto
     */
    groups?: Array<GroupReferenceDto>;
    /**
     * 
     * @type {number}
     * @memberof DrivingAssignmentDto
     */
    numberOfPassengers: number;
    /**
     * 
     * @type {string}
     * @memberof DrivingAssignmentDto
     */
    commentGuestManagement?: string;
    /**
     * 
     * @type {string}
     * @memberof DrivingAssignmentDto
     */
    commentDriver?: string;
    /**
     * 
     * @type {string}
     * @memberof DrivingAssignmentDto
     */
    commentInternal?: string;
    /**
     * 
     * @type {DrivingAssignmentStatusDto}
     * @memberof DrivingAssignmentDto
     */
    status: DrivingAssignmentStatusDto;
    /**
     * 
     * @type {DriverStatusDto}
     * @memberof DrivingAssignmentDto
     */
    driverStatus: DriverStatusDto;
}

/**
 * Check if a given object implements the DrivingAssignmentDto interface.
 */
export function instanceOfDrivingAssignmentDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('drivingAssignmentNumber' in value)) return false;
    if (!('edition' in value)) return false;
    if (!('principal' in value)) return false;
    if (!('pickUpTime' in value)) return false;
    if (!('reasonForJourney' in value)) return false;
    if (!('accessibility' in value)) return false;
    if (!('accessories' in value)) return false;
    if (!('favouredVehicles' in value)) return false;
    if (!('numberOfPassengers' in value)) return false;
    if (!('status' in value)) return false;
    if (!('driverStatus' in value)) return false;
    return true;
}

export function DrivingAssignmentDtoFromJSON(json: any): DrivingAssignmentDto {
    return DrivingAssignmentDtoFromJSONTyped(json, false);
}

export function DrivingAssignmentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrivingAssignmentDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'drivingAssignmentNumber': json['drivingAssignmentNumber'],
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'vehicleBooking': json['vehicleBooking'] == null ? undefined : VehicleBookingReferenceDtoFromJSON(json['vehicleBooking']),
        'principal': StaffReferenceDtoFromJSON(json['principal']),
        'involvedParties': json['involvedParties'] == null ? undefined : ((json['involvedParties'] as Array<any>).map(StaffReferenceDtoFromJSON)),
        'localContact': json['localContact'] == null ? undefined : StaffReferenceDtoFromJSON(json['localContact']),
        'pickUpTime': (new Date(json['pickUpTime'])),
        'startingPoint': json['startingPoint'] == null ? undefined : json['startingPoint'],
        'stopovers': json['stopovers'] == null ? undefined : ((json['stopovers'] as Array<any>).map(StopoverDtoFromJSON)),
        'dropOffTime': json['dropOffTime'] == null ? undefined : (new Date(json['dropOffTime'])),
        'destination': json['destination'] == null ? undefined : json['destination'],
        'reasonForJourney': ReasonForJourneyDtoFromJSON(json['reasonForJourney']),
        'event': json['event'] == null ? undefined : EventReferenceDtoFromJSON(json['event']),
        'travelDetails': json['travelDetails'] == null ? undefined : json['travelDetails'],
        'accessibility': AccessibilityDtoFromJSON(json['accessibility']),
        'accessories': ((json['accessories'] as Array<any>).map(AccessoryItemDtoFromJSON)),
        'favouredVehicles': ((json['favouredVehicles'] as Array<any>).map(FavouredVehicleItemDtoFromJSON)),
        'personPassengers': json['personPassengers'] == null ? undefined : ((json['personPassengers'] as Array<any>).map(PersonReferenceDtoFromJSON)),
        'customPassengers': json['customPassengers'] == null ? undefined : json['customPassengers'],
        'groups': json['groups'] == null ? undefined : ((json['groups'] as Array<any>).map(GroupReferenceDtoFromJSON)),
        'numberOfPassengers': json['numberOfPassengers'],
        'commentGuestManagement': json['commentGuestManagement'] == null ? undefined : json['commentGuestManagement'],
        'commentDriver': json['commentDriver'] == null ? undefined : json['commentDriver'],
        'commentInternal': json['commentInternal'] == null ? undefined : json['commentInternal'],
        'status': DrivingAssignmentStatusDtoFromJSON(json['status']),
        'driverStatus': DriverStatusDtoFromJSON(json['driverStatus']),
    };
}

export function DrivingAssignmentDtoToJSON(value?: DrivingAssignmentDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'drivingAssignmentNumber': value['drivingAssignmentNumber'],
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'vehicleBooking': VehicleBookingReferenceDtoToJSON(value['vehicleBooking']),
        'principal': StaffReferenceDtoToJSON(value['principal']),
        'involvedParties': value['involvedParties'] == null ? undefined : ((value['involvedParties'] as Array<any>).map(StaffReferenceDtoToJSON)),
        'localContact': StaffReferenceDtoToJSON(value['localContact']),
        'pickUpTime': ((value['pickUpTime']).toISOString()),
        'startingPoint': value['startingPoint'],
        'stopovers': value['stopovers'] == null ? undefined : ((value['stopovers'] as Array<any>).map(StopoverDtoToJSON)),
        'dropOffTime': value['dropOffTime'] == null ? undefined : ((value['dropOffTime']).toISOString()),
        'destination': value['destination'],
        'reasonForJourney': ReasonForJourneyDtoToJSON(value['reasonForJourney']),
        'event': EventReferenceDtoToJSON(value['event']),
        'travelDetails': value['travelDetails'],
        'accessibility': AccessibilityDtoToJSON(value['accessibility']),
        'accessories': ((value['accessories'] as Array<any>).map(AccessoryItemDtoToJSON)),
        'favouredVehicles': ((value['favouredVehicles'] as Array<any>).map(FavouredVehicleItemDtoToJSON)),
        'personPassengers': value['personPassengers'] == null ? undefined : ((value['personPassengers'] as Array<any>).map(PersonReferenceDtoToJSON)),
        'customPassengers': value['customPassengers'],
        'groups': value['groups'] == null ? undefined : ((value['groups'] as Array<any>).map(GroupReferenceDtoToJSON)),
        'numberOfPassengers': value['numberOfPassengers'],
        'commentGuestManagement': value['commentGuestManagement'],
        'commentDriver': value['commentDriver'],
        'commentInternal': value['commentInternal'],
        'status': DrivingAssignmentStatusDtoToJSON(value['status']),
        'driverStatus': DriverStatusDtoToJSON(value['driverStatus']),
    };
}

