import { Menu, MenuItem, MenuList, Portal } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyDto } from '../../../api';
import { BurgerMenuButton } from '../../../ui/burger-menu-button/burger-menu-button';
import { filmIcon } from '../../../ui/icons/business-objects';
import usePlugins from '../../../util/plugin/use-plugins';
import usePermission from '../../permission/use-permission';
import { COMPANY_ACTION_EXTENSION } from '../company-action-extension';
import { AddFilmToCompanyButton } from '../company-film/add-film-to-company-button';
import DeleteCompanyMenuItem from './delete-company-menu-item';

export default function CompanyMenu({ company, onDelete }: { company: CompanyDto; onDelete: () => void }) {
  const { t } = useTranslation('company');

  const { hasPermission } = usePermission();
  const actionExtensions = usePlugins(COMPANY_ACTION_EXTENSION);

  const actions = actionExtensions.map((extension) => extension.item(company, hasPermission)).filter(Boolean);

  if (hasPermission('COMPANY:CAN_LINK_COMPANY_WITH_FILM')) {
    actions.push(
      <MenuItem
        as={AddFilmToCompanyButton}
        key="AddFilmToCompanyButton"
        icon={<FontAwesomeIcon fixedWidth icon={filmIcon} />}
        company={company}
      >
        {t('relation.film.action.add')}
      </MenuItem>,
    );
  }

  if (hasPermission('COMPANY:CAN_DELETE')) {
    actions.push(<DeleteCompanyMenuItem company={company} onDelete={onDelete} />);
  }

  return (
    actions.length > 0 && (
      <Menu isLazy={true} lazyBehavior="keepMounted">
        <BurgerMenuButton />
        <Portal>
          <MenuList>
            <Suspense>{actions}</Suspense>
          </MenuList>
        </Portal>
      </Menu>
    )
  );
}
