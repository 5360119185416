import React from 'react';
import invariant from 'tiny-invariant';
import { OrderConfirmationTypeDto, PaymentListItemDto } from '../../../../api';
import DataTable, { DataTableState, useDataTableState } from '../../../../ui/data-table';
import useRequestParams from '../../../../ui/data-table/use-request-params';
import fallbackMiddleware from '../../../../util/swr/fallback-middleware';
import useFetcher from '../../../../util/swr/use-fetcher';
import { statusDisplay } from '../payment-enum-constants';
import { fetchCollectiveOrderConfirmationPayments, fetchOrderConfirmationPayments } from '../payment-queries';
import usePaymentTableColumns from './use-order-confirmation-payment-table-columns';

function useOrderConfirmationPaymentListItems(
  state: DataTableState,
  orderConfirmationId: string,
  orderConfirmationType: OrderConfirmationTypeDto,
) {
  const requestParams = useRequestParams(state, [{ property: 'txndatetime', direction: 'DESC' }]);
  return useFetcher(
    orderConfirmationType == OrderConfirmationTypeDto.SINGLE
      ? fetchOrderConfirmationPayments
      : fetchCollectiveOrderConfirmationPayments,
    {
      ...requestParams,
      id: orderConfirmationId,
    },
    { use: [fallbackMiddleware] },
  );
}

export default function OrderConfirmationPaymentDataTable({
  orderConfirmationId,
  orderConfirmationType,
}: {
  orderConfirmationId: string;
  orderConfirmationType: OrderConfirmationTypeDto;
}) {
  const [state, setState] = useDataTableState();
  const page = useOrderConfirmationPaymentListItems(state, orderConfirmationId, orderConfirmationType);

  const columns = usePaymentTableColumns('../../../payments/');

  const rowKey = React.useCallback((payment: PaymentListItemDto) => {
    invariant(payment.id != null, 'Missing payment id');

    return payment.id;
  }, []);

  return (
    <DataTable
      page={page == null ? { content: [] } : page}
      state={state}
      columns={columns}
      rowKey={rowKey}
      onStateChange={setState}
      isPageable
      rowStyle={(data) => ({
        bg: statusDisplay[data.status]?.bgColor,
      })}
    />
  );
}
