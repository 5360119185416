import { Checkbox } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyDto } from '../../../api';
import ConfirmDialog from '../../../ui/dialog/confirm-dialog';

export interface InvoiceRefundTaxDialogProps {
  company: CompanyDto;
  isOpen: boolean;

  onClose(confirm: boolean): void;
}

export default function InvoiceRefundTaxDialog({ company, isOpen, onClose, ...props }: InvoiceRefundTaxDialogProps) {
  const { t } = useTranslation(['invoice']);
  const initialFocusRef = useRef<HTMLInputElement>(null);
  const [confirmed, setConfirmed] = useState<boolean>(false);

  return (
    <ConfirmDialog
      {...props}
      isOpen={isOpen}
      initialFocusRef={initialFocusRef}
      confirmActionLabel={t('invoice:action.refundTax.label')}
      onClose={(confirm) => {
        setConfirmed(false);
        onClose(confirm);
      }}
      isConfirmActionDisabled={!confirmed}
      submitButtonVariant="primary"
    >
      <Checkbox ref={initialFocusRef} isRequired isChecked={confirmed} onChange={(e) => setConfirmed(e.target.checked)}>
        {t('invoice:action.refundTax.confirm', { companyName: company.name })}
      </Checkbox>
    </ConfirmDialog>
  );
}
