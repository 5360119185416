import React from 'react';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import HotelBookingBreadcrumb from './common/hotel-booking-breadcrumb';

const hotelBookingRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'hotel-bookings',
    handle: {
      breadcrumb: <Translate ns="hotel_booking" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./hotel-booking-lister/hotel-booking-lister-route'),
      },
      {
        path: 'new',
        lazy: () => import('./hotel-booking-editor/new-hotel-booking-route'),
      },
      {
        path: ':hotelBookingId',
        handle: {
          breadcrumb: <HotelBookingBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./hotel-booking-page/hotel-booking-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./hotel-booking-viewer/hotel-booking-viewer-route'),
              },
            ],
          },
        ],
      },
    ],
  },
];

export default hotelBookingRoutes;
