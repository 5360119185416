import { hotelEditionDataIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import LOCATION_CHILD_ROUTES from '../location/location-child-routes';
import { LOCATION_TAB_ITEM, LocationTabItem } from '../location/location-page/location-page';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { useHotelEditionDataPermissionClassGroupExtension } from './hotel-edition-data-permissions';
import locationHotelEditionDataRoutes from './location-hotel-edition-data-routes';

export const hotelEditionDataLocationTabItem: LocationTabItem = {
  order: 2,
  label: <Translate ns="hotel_edition_data" i18nKey="lister.title" />,
  icon: hotelEditionDataIcon,
  to: './hotel-edition-data',
  isVisible: (hasPermission) => hasPermission('HOTEL_EDITION_DATA:CAN_SEE_LIST_VIEW'),
};

export default function HotelEditionDataPluginProvider(registry: PluginRegistry) {
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useHotelEditionDataPermissionClassGroupExtension);
  registry.register(LOCATION_TAB_ITEM, hotelEditionDataLocationTabItem);
  registry.register(LOCATION_CHILD_ROUTES, locationHotelEditionDataRoutes);
}
