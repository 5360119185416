/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DrivingAssignmentDto,
  FailureDto,
  HistoryPageDto,
  PageableDto,
} from '../models/index';
import {
    DrivingAssignmentDtoFromJSON,
    DrivingAssignmentDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface FetchDrivingAssignmentRequest {
    id: string;
}

export interface FetchDrivingAssignmentHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

/**
 * 
 */
export class DrivingAssignmentApi extends runtime.BaseAPI {

    /**
     * Get the driving assignment business object.
     */
    async fetchDrivingAssignmentRaw(requestParameters: FetchDrivingAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DrivingAssignmentDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchDrivingAssignment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/driving-assignments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DrivingAssignmentDtoFromJSON(jsonValue));
    }

    /**
     * Get the driving assignment business object.
     */
    async fetchDrivingAssignment(requestParameters: FetchDrivingAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DrivingAssignmentDto> {
        const response = await this.fetchDrivingAssignmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the driving assignment.
     */
    async fetchDrivingAssignmentHistoryRaw(requestParameters: FetchDrivingAssignmentHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchDrivingAssignmentHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/driving-assignments/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the driving assignment.
     */
    async fetchDrivingAssignmentHistory(requestParameters: FetchDrivingAssignmentHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchDrivingAssignmentHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
