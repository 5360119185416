/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccessoryItemDto
 */
export interface AccessoryItemDto {
    /**
     * 
     * @type {string}
     * @memberof AccessoryItemDto
     */
    accessory: string;
    /**
     * 
     * @type {number}
     * @memberof AccessoryItemDto
     */
    quantity: number;
    /**
     * 
     * @type {Date}
     * @memberof AccessoryItemDto
     */
    timestamp: Date;
}

/**
 * Check if a given object implements the AccessoryItemDto interface.
 */
export function instanceOfAccessoryItemDto(value: object): boolean {
    if (!('accessory' in value)) return false;
    if (!('quantity' in value)) return false;
    if (!('timestamp' in value)) return false;
    return true;
}

export function AccessoryItemDtoFromJSON(json: any): AccessoryItemDto {
    return AccessoryItemDtoFromJSONTyped(json, false);
}

export function AccessoryItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessoryItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'accessory': json['accessory'],
        'quantity': json['quantity'],
        'timestamp': (new Date(json['timestamp'])),
    };
}

export function AccessoryItemDtoToJSON(value?: AccessoryItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'accessory': value['accessory'],
        'quantity': value['quantity'],
        'timestamp': ((value['timestamp']).toISOString()),
    };
}

