import React from 'react';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import MailingListBreadcrumb from './mailing-list-breadcrumb';

const mailingListRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'mailing-lists',
    handle: {
      breadcrumb: <Translate ns="mailing_list" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./mailing-list-lister/mailing-list-lister-route'),
      },
      {
        path: 'new',
        lazy: () => import('./mailing-list-editor/new-mailing-list-route'),
      },
      {
        path: ':listId',
        handle: {
          breadcrumb: <MailingListBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./mailing-list-viewer/mailing-list-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./mailing-list-viewer/mailing-list-viewer-route'),
              },
              {
                path: 'edit',
                lazy: () => import('./mailing-list-editor/edit-mailing-list-route'),
              },
              {
                path: 'entries',
                lazy: () => import('./mailing-list-entry-lister/mailing-list-entry-lister-route'),
              },
              {
                path: 'history',
                lazy: () => import('./mailing-list-history/mailing-list-history-route'),
              },
            ],
          },
          {
            path: 'entries/new',
            lazy: () => import('./mailing-list-entry-editor/edit-mailing-list-entries-route'),
          },
        ],
      },
    ],
  },
];

export default mailingListRoutes;
