import { Checkbox } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../../../../ui/dialog/confirm-dialog';

export interface CollectiveOrderConfirmationCancelConfirmationDialogProps {
  number: string;
  isOpen: boolean;

  onClose(confirm: boolean): void | Promise<void>;
}

export default function CollectiveOrderConfirmationCancelConfirmationDialog({
  number,
  isOpen,
  onClose,
}: CollectiveOrderConfirmationCancelConfirmationDialogProps) {
  const { t } = useTranslation(['common', 'order_confirmation']);
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  const [isLoading, setIsLoading] = React.useState(false);
  const [confirmed, setConfirmed] = React.useState(false);

  const onCloseWithReset = React.useCallback(
    async (confirm: boolean) => {
      setIsLoading(true);
      await onClose(confirm);
      setIsLoading(false);
      setConfirmed(false);
    },
    [onClose],
  );

  return (
    <ConfirmDialog
      isOpen={isOpen}
      initialFocusRef={initialFocusRef}
      confirmActionLabel={t('order_confirmation:collectiveOrderConfirmation.action.cancel.label')}
      onClose={onCloseWithReset}
      isConfirmActionDisabled={!confirmed}
      submitButtonColorScheme="red"
      submitButtonProps={{ isLoading }}
    >
      <Checkbox ref={initialFocusRef} isRequired isChecked={confirmed} onChange={(e) => setConfirmed(e.target.checked)}>
        {t('order_confirmation:collectiveOrderConfirmation.action.cancel.confirm', {
          collectiveOrderConfirmationNumber: number,
        })}
      </Checkbox>
    </ConfirmDialog>
  );
}
