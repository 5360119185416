import { Button, ButtonProps, forwardRef, useDisclosure } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ForwardedRef, MouseEvent, ReactElement, ReactNode, useCallback, useTransition } from 'react';
import useDefinedContext from '../../../util/context/use-defined-context/use-defined-context';
import { ElementFormModalProvider, ElementFormProvider } from './element-form-modal-context';
import { ElementTableDispatchContext } from './element-table-control';

/**
 * Properties for add button.
 */
interface AddElementButtonProps extends Omit<ButtonProps, 'form'> {
  label: ReactNode;
  formModal: ReactElement;
  hasValidationError?: boolean;
}

/**
 * Button to add element to a list or table.
 */
const AddElementButton = forwardRef(
  (
    { label, formModal, hasValidationError, onClick, ...props }: AddElementButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isPending, startTransition] = useTransition();

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
      onClick?.(event);

      if (event.defaultPrevented) {
        return;
      }

      onOpen();
    };

    const dispatch = useDefinedContext(ElementTableDispatchContext);
    const handleSubmit = useCallback(
      (element: unknown) => {
        startTransition(() => {
          dispatch({ type: 'ADD_ELEMENT', payload: element });
        });
      },
      [dispatch],
    );

    return (
      <>
        <Button
          onClick={handleClick}
          size="sm"
          data-invalid={hasValidationError}
          sx={{
            '&[data-invalid="true"]': {
              border: '2px solid',
              borderColor: 'border.error',
            },
          }}
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
          ref={ref}
          {...props}
        >
          {label}
        </Button>

        <ElementFormProvider onSubmit={handleSubmit} element={undefined}>
          <ElementFormModalProvider label={label} isOpen={isOpen} onClose={onClose} isPending={isPending}>
            {formModal}
          </ElementFormModalProvider>
        </ElementFormProvider>
      </>
    );
  },
);

export default AddElementButton;
