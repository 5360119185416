import { HStack, Image, Link } from '@chakra-ui/react';
import { faFile } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FileMetadataDto } from '../../api';
import AttachmentModalButton from './attachment-modal-button';

interface ImageProps {
  metadata: FileMetadataDto;
  alt?: string;
  borderRadius?: string;
  boxSize?: string;
  minWidth?: string;
  height?: string;
}

/**
 * Shows the image in a dialog after clickling on the smaller image preview.
 */
export function AttachmentImage({ metadata, alt, boxSize, minWidth, borderRadius, height }: ImageProps) {
  const { t } = useTranslation('attachment');

  let thumbnailSrc: string | undefined;

  if (
    metadata.mimeType !== 'application/pdf' &&
    metadata.mimeType !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ) {
    const width = boxSize === 'full' ? 400 : 48;
    const height = boxSize === 'full' ? 150 : 48;

    thumbnailSrc = `/image-manipulation/api/resize/${metadata.namespace}/${metadata.id}/${width}x${height}`;
  }

  return (
    <AttachmentModalButton
      as={Image}
      src={thumbnailSrc}
      metadata={metadata}
      boxSize={boxSize ?? 12}
      minWidth={minWidth ?? 12}
      height={height}
      borderRadius={borderRadius}
      objectFit="cover"
      alt={alt ?? t('photo')}
      cursor="pointer"
      fallback={
        <HStack ml={3}>
          <Link href={metadata.url}>
            <FontAwesomeIcon icon={faFile} size="2x" />
          </Link>
        </HStack>
      }
    />
  );
}
