import { Button, Flex, Grid, GridItem, Heading, Spacer } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { OrderConfirmationTypeDto } from '../../../../api';
import paymentApi from '../../../../data-access/payment-api';
import PageContent from '../../../../ui/page/page-content';
import { paymentFetcher } from '../payment-queries';
import OrderConfirmationPaymentDataTable from './order-confirmation-payment-data-table';

export function OrderConfirmationPaymentPageContent() {
  const params = useParams<{
    orderConfirmationId: string;
    collectiveOrderConfirmationId: string;
  }>();
  const orderConfirmationType = params.orderConfirmationId
    ? OrderConfirmationTypeDto.SINGLE
    : OrderConfirmationTypeDto.COLLECTIVE;
  const orderConfirmationId = params.orderConfirmationId
    ? params.orderConfirmationId
    : params.collectiveOrderConfirmationId!;
  const { t } = useTranslation(['payment']);

  return (
    <PageContent useFullWidth fixedHeight>
      <Grid gridRowGap={6} maxHeight="full" gridTemplateRows="auto 1fr">
        <Flex as="header">
          <Heading size="md" as="h3">
            {t('payment:lister.title')}
          </Heading>
          <Spacer />
          <SyncPaymentsButton orderConfirmationId={orderConfirmationId} orderConfirmationType={orderConfirmationType} />
        </Flex>
        <GridItem minH={0}>
          <OrderConfirmationPaymentDataTable
            orderConfirmationId={orderConfirmationId}
            orderConfirmationType={orderConfirmationType}
          />
        </GridItem>
      </Grid>
    </PageContent>
  );
}

interface SyncPaymentsButtonProps {
  orderConfirmationId: string;
  orderConfirmationType: OrderConfirmationTypeDto;
}

function SyncPaymentsButton({ orderConfirmationId, orderConfirmationType }: SyncPaymentsButtonProps) {
  const { t } = useTranslation(['payment']);
  const [isSyncing, setIsSyncing] = useState(false);
  const handleClick = async () => {
    setIsSyncing(true);
    await (orderConfirmationType == OrderConfirmationTypeDto.SINGLE
      ? paymentApi.syncPaymentsByOrderConfirmation({ id: orderConfirmationId })
      : paymentApi.syncPaymentsByCollectiveOrderConfirmation({ id: orderConfirmationId }));
    await paymentFetcher.mutate();
    setIsSyncing(false);
  };

  return (
    <Button variant="primary" onClick={handleClick} isLoading={isSyncing} loadingText={t('payment:action.sync')}>
      {t('payment:action.sync')}
    </Button>
  );
}
