/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddEntriesDto,
  EntryPageDto,
  FailureDto,
  HistoryPageDto,
  PageableDto,
  RecipientLinkDto,
  RecipientPageDto,
  RecipientTypeDto,
} from '../models/index';
import {
    AddEntriesDtoFromJSON,
    AddEntriesDtoToJSON,
    EntryPageDtoFromJSON,
    EntryPageDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    RecipientLinkDtoFromJSON,
    RecipientLinkDtoToJSON,
    RecipientPageDtoFromJSON,
    RecipientPageDtoToJSON,
    RecipientTypeDtoFromJSON,
    RecipientTypeDtoToJSON,
} from '../models/index';

export interface AddEntriesRequest {
    id: string;
    addEntriesDto: AddEntriesDto;
}

export interface DeleteMailingListEntriesRequest {
    id: string;
    recipientLinkDto: Array<RecipientLinkDto>;
}

export interface FetchMailingListEntryHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FindAlreadyExistingMailingListEmailEntriesRequest {
    id: string;
    emailAdresses: Array<string>;
}

export interface MailingListFetchEntriesRequest {
    id: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface MailingListFetchPossibleEntriesRequest {
    id: string;
    filter?: Array<string>;
    pageable?: PageableDto;
    q?: string;
    recipientType?: RecipientTypeDto;
}

/**
 * 
 */
export class MailingListEntryApi extends runtime.BaseAPI {

    /**
     */
    async addEntriesRaw(requestParameters: AddEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling addEntries().'
            );
        }

        if (requestParameters['addEntriesDto'] == null) {
            throw new runtime.RequiredError(
                'addEntriesDto',
                'Required parameter "addEntriesDto" was null or undefined when calling addEntries().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-lists/{id}/entries`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddEntriesDtoToJSON(requestParameters['addEntriesDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async addEntries(requestParameters: AddEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addEntriesRaw(requestParameters, initOverrides);
    }

    /**
     * Delete the entries with the ids listed in the request parameters
     */
    async deleteMailingListEntriesRaw(requestParameters: DeleteMailingListEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteMailingListEntries().'
            );
        }

        if (requestParameters['recipientLinkDto'] == null) {
            throw new runtime.RequiredError(
                'recipientLinkDto',
                'Required parameter "recipientLinkDto" was null or undefined when calling deleteMailingListEntries().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-lists/{id}/delete-entries`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['recipientLinkDto']!.map(RecipientLinkDtoToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the entries with the ids listed in the request parameters
     */
    async deleteMailingListEntries(requestParameters: DeleteMailingListEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteMailingListEntriesRaw(requestParameters, initOverrides);
    }

    /**
     */
    async fetchMailingListEntryHistoryRaw(requestParameters: FetchMailingListEntryHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchMailingListEntryHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-lists/entries/versions-by-person/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchMailingListEntryHistory(requestParameters: FetchMailingListEntryHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchMailingListEntryHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the existing entries of the given list.
     */
    async findAlreadyExistingMailingListEmailEntriesRaw(requestParameters: FindAlreadyExistingMailingListEmailEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling findAlreadyExistingMailingListEmailEntries().'
            );
        }

        if (requestParameters['emailAdresses'] == null) {
            throw new runtime.RequiredError(
                'emailAdresses',
                'Required parameter "emailAdresses" was null or undefined when calling findAlreadyExistingMailingListEmailEntries().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['emailAdresses'] != null) {
            queryParameters['emailAdresses'] = requestParameters['emailAdresses']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-lists/{id}/has-existing-entries`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get the existing entries of the given list.
     */
    async findAlreadyExistingMailingListEmailEntries(requestParameters: FindAlreadyExistingMailingListEmailEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.findAlreadyExistingMailingListEmailEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mailingListFetchEntriesRaw(requestParameters: MailingListFetchEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EntryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling mailingListFetchEntries().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-lists/{id}/entries`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EntryPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async mailingListFetchEntries(requestParameters: MailingListFetchEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EntryPageDto> {
        const response = await this.mailingListFetchEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mailingListFetchPossibleEntriesRaw(requestParameters: MailingListFetchPossibleEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecipientPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling mailingListFetchPossibleEntries().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['recipientType'] != null) {
            queryParameters['recipientType'] = requestParameters['recipientType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-lists/{id}/possible-entries`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecipientPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async mailingListFetchPossibleEntries(requestParameters: MailingListFetchPossibleEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecipientPageDto> {
        const response = await this.mailingListFetchPossibleEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
