/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryReferenceDto } from './CountryReferenceDto';
import {
    CountryReferenceDtoFromJSON,
    CountryReferenceDtoFromJSONTyped,
    CountryReferenceDtoToJSON,
} from './CountryReferenceDto';
import type { FileMetadataDto } from './FileMetadataDto';
import {
    FileMetadataDtoFromJSON,
    FileMetadataDtoFromJSONTyped,
    FileMetadataDtoToJSON,
} from './FileMetadataDto';
import type { ParticipationReferenceDto } from './ParticipationReferenceDto';
import {
    ParticipationReferenceDtoFromJSON,
    ParticipationReferenceDtoFromJSONTyped,
    ParticipationReferenceDtoToJSON,
} from './ParticipationReferenceDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';
import type { VisaMissingAttributeDto } from './VisaMissingAttributeDto';
import {
    VisaMissingAttributeDtoFromJSON,
    VisaMissingAttributeDtoFromJSONTyped,
    VisaMissingAttributeDtoToJSON,
} from './VisaMissingAttributeDto';
import type { VisaStatusDto } from './VisaStatusDto';
import {
    VisaStatusDtoFromJSON,
    VisaStatusDtoFromJSONTyped,
    VisaStatusDtoToJSON,
} from './VisaStatusDto';
import type { VisaTemplateReferenceDto } from './VisaTemplateReferenceDto';
import {
    VisaTemplateReferenceDtoFromJSON,
    VisaTemplateReferenceDtoFromJSONTyped,
    VisaTemplateReferenceDtoToJSON,
} from './VisaTemplateReferenceDto';

/**
 * A visa
 * @export
 * @interface VisaDto
 */
export interface VisaDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof VisaDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof VisaDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {Date}
     * @memberof VisaDto
     */
    createdAt?: Date;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof VisaDto
     */
    applicant: PersonReferenceDto;
    /**
     * 
     * @type {ParticipationReferenceDto}
     * @memberof VisaDto
     */
    participation: ParticipationReferenceDto;
    /**
     * 
     * @type {FileMetadataDto}
     * @memberof VisaDto
     */
    document?: FileMetadataDto;
    /**
     * 
     * @type {VisaTemplateReferenceDto}
     * @memberof VisaDto
     */
    visaTemplate?: VisaTemplateReferenceDto;
    /**
     * 
     * @type {CountryReferenceDto}
     * @memberof VisaDto
     */
    nationality?: CountryReferenceDto;
    /**
     * 
     * @type {Array<VisaMissingAttributeDto>}
     * @memberof VisaDto
     */
    missingAttributes: Array<VisaMissingAttributeDto>;
    /**
     * 
     * @type {VisaStatusDto}
     * @memberof VisaDto
     */
    status: VisaStatusDto;
}

/**
 * Check if a given object implements the VisaDto interface.
 */
export function instanceOfVisaDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('applicant' in value)) return false;
    if (!('participation' in value)) return false;
    if (!('missingAttributes' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function VisaDtoFromJSON(json: any): VisaDto {
    return VisaDtoFromJSONTyped(json, false);
}

export function VisaDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisaDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
        'applicant': PersonReferenceDtoFromJSON(json['applicant']),
        'participation': ParticipationReferenceDtoFromJSON(json['participation']),
        'document': json['document'] == null ? undefined : FileMetadataDtoFromJSON(json['document']),
        'visaTemplate': json['visaTemplate'] == null ? undefined : VisaTemplateReferenceDtoFromJSON(json['visaTemplate']),
        'nationality': json['nationality'] == null ? undefined : CountryReferenceDtoFromJSON(json['nationality']),
        'missingAttributes': ((json['missingAttributes'] as Array<any>).map(VisaMissingAttributeDtoFromJSON)),
        'status': VisaStatusDtoFromJSON(json['status']),
    };
}

export function VisaDtoToJSON(value?: VisaDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString().substring(0,10)),
        'applicant': PersonReferenceDtoToJSON(value['applicant']),
        'participation': ParticipationReferenceDtoToJSON(value['participation']),
        'document': FileMetadataDtoToJSON(value['document']),
        'visaTemplate': VisaTemplateReferenceDtoToJSON(value['visaTemplate']),
        'nationality': CountryReferenceDtoToJSON(value['nationality']),
        'missingAttributes': ((value['missingAttributes'] as Array<any>).map(VisaMissingAttributeDtoToJSON)),
        'status': VisaStatusDtoToJSON(value['status']),
    };
}

