import React from 'react';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';

const driveUpListRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'drive-up-list',
    handle: {
      breadcrumb: <Translate ns="drive_up_list" i18nKey="driveUpList" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./drive-up-list-all-entries/drive-up-list-all-entries-route'),
      },
    ],
  },
];

export default driveUpListRoutes;

export const driveUpListPrintRoutes: (BreadcrumbRouteObject | HelmetRouteObject)[] = [
  {
    path: 'events/:eventId/drive-up-list/:driveUpListId',
    lazy: () => import('./drive-up-list-export/drive-up-list-export-route'),
  },
];
