import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const vehicleBookingMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.vehicleBooking" />,
    path: '/vehicle-bookings',
    // neccessaryPermission: 'VEHICLE_BOOKING:CAN_SEE_LIST_VIEW',
  },
];

export default vehicleBookingMenuItems;
