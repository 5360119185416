import { SpinnerIcon } from '@chakra-ui/icons';
import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { BooleanDto, NotificationModeDto, SubscriptionDto } from '../../../api';
import subscriptionApi from '../../../data-access/subscription-api';
import Form from '../../../ui/form/form';
import ValueSelectFormControl from '../../../ui/form/select-control/value-select-form-control';
import SubmitButton from '../../../ui/form/submit-button';
import useToast from '../../../ui/use-toast/use-toast';
import useFetcher from '../../../util/swr/use-fetcher';
import { hasSubscription } from '../notification-queries';

interface SubscribeButtonProps {
  type: string;
  entityId: string;
}

export default function SubscribeButton({ type, entityId }: SubscribeButtonProps) {
  const { t } = useTranslation(['notification']);
  const [isLoading, setIsLoading] = useState(false);
  const showSuccessToast = useToast({
    id: 'success-toast',
    status: 'success',
  });
  const exists = useFetcher(hasSubscription, { id: entityId, type }, { suspense: false });

  const { onOpen, isOpen, onClose } = useDisclosure();

  const subscribe = async (dto: SubscriptionDto) => {
    setIsLoading(true);
    invariant(exists?.value === false, 'Exists must be true here');
    await subscriptionApi.createSubscription({
      subscriptionDto: { ...dto, entityId, type },
    });
    showSuccessToast({
      title: t('toast.subscribe.title'),
      description: t('toast.subscribe.description'),
    });
    await hasSubscription.mutate({ id: entityId, type });
    setIsLoading(false);
    onClose();
  };

  const unsubscribe = async () => {
    setIsLoading(true);
    invariant(exists?.value === true, 'Exists must be true here');
    await subscriptionApi.deleteSubscriptionByEntityIdAndType({
      id: entityId,
      type,
    });

    showSuccessToast({
      title: t('toast.unsubscribe.title'),
      description: t('toast.unsubscribe.description'),
    });
    await hasSubscription.mutate({ id: entityId, type });
    setIsLoading(false);
  };

  return (
    <>
      <SubscriptionDialog isOpen={isOpen} handleValid={subscribe} onClose={onClose} />
      <IconButton
        isDisabled={exists == null || isLoading}
        onClick={exists?.value ? unsubscribe : onOpen}
        variant="ghost"
        icon={getIcon(exists)}
        aria-label={exists?.value ? t('unsubscribe') : t('subscribe')}
      />
    </>
  );
}

function getIcon(exists: BooleanDto | undefined) {
  if (exists == null) {
    return <SpinnerIcon />;
  }
  return exists.value ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />;
}

function SubscriptionDialog({
  isOpen,
  onClose,
  handleValid,
}: {
  isOpen: boolean;
  onClose: () => void;
  handleValid: (dto: SubscriptionDto) => void;
}) {
  const initialFocusRef = React.useRef<HTMLInputElement>(null);
  const { t } = useTranslation(['notification', 'common']);
  const form = useForm();
  const isDirty = form.formState.dirtyFields && Object.keys(form.formState.dirtyFields).length > 0;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md" closeOnOverlayClick={false} initialFocusRef={initialFocusRef}>
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...form}>
          <Form<SubscriptionDto> onValid={handleValid} initialFocusRef={initialFocusRef}>
            <ModalHeader>{t('notification:subscribe')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing={4}>
                <ValueSelectFormControl<NotificationModeDto>
                  options={[NotificationModeDto.ONCE, NotificationModeDto.ALWAYS]}
                  renderLabel={(value) => t(`notification:notificationModeOptions.${value}`)}
                  name="notificationMode"
                  label={t('notification:notificationMode')}
                  isRequired
                />
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button mr={3} onClick={onClose}>
                {t('common:action.abort')}
              </Button>
              <SubmitButton variant="primary" isDisabled={!isDirty}>
                {t('common:action.save')}
              </SubmitButton>
            </ModalFooter>
          </Form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}
