/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccountingCreationTypeDto } from './AccountingCreationTypeDto';
import {
    AccountingCreationTypeDtoFromJSON,
    AccountingCreationTypeDtoFromJSONTyped,
    AccountingCreationTypeDtoToJSON,
} from './AccountingCreationTypeDto';
import type { AccountingIssuerDto } from './AccountingIssuerDto';
import {
    AccountingIssuerDtoFromJSON,
    AccountingIssuerDtoFromJSONTyped,
    AccountingIssuerDtoToJSON,
} from './AccountingIssuerDto';
import type { AccountingRecipientDto } from './AccountingRecipientDto';
import {
    AccountingRecipientDtoFromJSON,
    AccountingRecipientDtoFromJSONTyped,
    AccountingRecipientDtoToJSON,
} from './AccountingRecipientDto';
import type { CollectiveOrderConfirmationReferenceDto } from './CollectiveOrderConfirmationReferenceDto';
import {
    CollectiveOrderConfirmationReferenceDtoFromJSON,
    CollectiveOrderConfirmationReferenceDtoFromJSONTyped,
    CollectiveOrderConfirmationReferenceDtoToJSON,
} from './CollectiveOrderConfirmationReferenceDto';
import type { InvoiceStatusDto } from './InvoiceStatusDto';
import {
    InvoiceStatusDtoFromJSON,
    InvoiceStatusDtoFromJSONTyped,
    InvoiceStatusDtoToJSON,
} from './InvoiceStatusDto';
import type { InvoiceTypeDto } from './InvoiceTypeDto';
import {
    InvoiceTypeDtoFromJSON,
    InvoiceTypeDtoFromJSONTyped,
    InvoiceTypeDtoToJSON,
} from './InvoiceTypeDto';
import type { OrderConfirmationReferenceDto } from './OrderConfirmationReferenceDto';
import {
    OrderConfirmationReferenceDtoFromJSON,
    OrderConfirmationReferenceDtoFromJSONTyped,
    OrderConfirmationReferenceDtoToJSON,
} from './OrderConfirmationReferenceDto';
import type { SumTotalDto } from './SumTotalDto';
import {
    SumTotalDtoFromJSON,
    SumTotalDtoFromJSONTyped,
    SumTotalDtoToJSON,
} from './SumTotalDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface InvoiceListItemDto
 */
export interface InvoiceListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof InvoiceListItemDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListItemDto
     */
    invoiceNumber: string;
    /**
     * 
     * @type {AccountingIssuerDto}
     * @memberof InvoiceListItemDto
     */
    issuer: AccountingIssuerDto;
    /**
     * 
     * @type {InvoiceStatusDto}
     * @memberof InvoiceListItemDto
     */
    status: InvoiceStatusDto;
    /**
     * 
     * @type {InvoiceTypeDto}
     * @memberof InvoiceListItemDto
     */
    type: InvoiceTypeDto;
    /**
     * 
     * @type {SumTotalDto}
     * @memberof InvoiceListItemDto
     */
    sumTotal: SumTotalDto;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListItemDto
     */
    invoiceDate: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceListItemDto
     */
    documentGenerated: boolean;
    /**
     * 
     * @type {AccountingRecipientDto}
     * @memberof InvoiceListItemDto
     */
    recipient: AccountingRecipientDto;
    /**
     * 
     * @type {AccountingCreationTypeDto}
     * @memberof InvoiceListItemDto
     */
    creationType: AccountingCreationTypeDto;
    /**
     * 
     * @type {OrderConfirmationReferenceDto}
     * @memberof InvoiceListItemDto
     */
    orderConfirmation?: OrderConfirmationReferenceDto;
    /**
     * 
     * @type {CollectiveOrderConfirmationReferenceDto}
     * @memberof InvoiceListItemDto
     */
    collectiveOrderConfirmation?: CollectiveOrderConfirmationReferenceDto;
    /**
     * 
     * @type {VersionDto}
     * @memberof InvoiceListItemDto
     */
    version: VersionDto;
}

/**
 * Check if a given object implements the InvoiceListItemDto interface.
 */
export function instanceOfInvoiceListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('invoiceNumber' in value)) return false;
    if (!('issuer' in value)) return false;
    if (!('status' in value)) return false;
    if (!('type' in value)) return false;
    if (!('sumTotal' in value)) return false;
    if (!('invoiceDate' in value)) return false;
    if (!('documentGenerated' in value)) return false;
    if (!('recipient' in value)) return false;
    if (!('creationType' in value)) return false;
    if (!('version' in value)) return false;
    return true;
}

export function InvoiceListItemDtoFromJSON(json: any): InvoiceListItemDto {
    return InvoiceListItemDtoFromJSONTyped(json, false);
}

export function InvoiceListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'invoiceNumber': json['invoiceNumber'],
        'issuer': AccountingIssuerDtoFromJSON(json['issuer']),
        'status': InvoiceStatusDtoFromJSON(json['status']),
        'type': InvoiceTypeDtoFromJSON(json['type']),
        'sumTotal': SumTotalDtoFromJSON(json['sumTotal']),
        'invoiceDate': json['invoiceDate'],
        'documentGenerated': json['documentGenerated'],
        'recipient': AccountingRecipientDtoFromJSON(json['recipient']),
        'creationType': AccountingCreationTypeDtoFromJSON(json['creationType']),
        'orderConfirmation': json['orderConfirmation'] == null ? undefined : OrderConfirmationReferenceDtoFromJSON(json['orderConfirmation']),
        'collectiveOrderConfirmation': json['collectiveOrderConfirmation'] == null ? undefined : CollectiveOrderConfirmationReferenceDtoFromJSON(json['collectiveOrderConfirmation']),
        'version': VersionDtoFromJSON(json['version']),
    };
}

export function InvoiceListItemDtoToJSON(value?: InvoiceListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'invoiceNumber': value['invoiceNumber'],
        'issuer': AccountingIssuerDtoToJSON(value['issuer']),
        'status': InvoiceStatusDtoToJSON(value['status']),
        'type': InvoiceTypeDtoToJSON(value['type']),
        'sumTotal': SumTotalDtoToJSON(value['sumTotal']),
        'invoiceDate': value['invoiceDate'],
        'documentGenerated': value['documentGenerated'],
        'recipient': AccountingRecipientDtoToJSON(value['recipient']),
        'creationType': AccountingCreationTypeDtoToJSON(value['creationType']),
        'orderConfirmation': OrderConfirmationReferenceDtoToJSON(value['orderConfirmation']),
        'collectiveOrderConfirmation': CollectiveOrderConfirmationReferenceDtoToJSON(value['collectiveOrderConfirmation']),
        'version': VersionDtoToJSON(value['version']),
    };
}

