/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DriverStatusDto = {
    NONE: 'NONE',
    TO_CONFIRM: 'TO_CONFIRM',
    CONFIRMED: 'CONFIRMED',
    PICKUP_LOCATION: 'PICKUP_LOCATION',
    ON_THE_ROAD: 'ON_THE_ROAD',
    ARRIVED_AT_DESTINATION: 'ARRIVED_AT_DESTINATION',
    NO_SHOW: 'NO_SHOW',
    CHANGE_IN_PROGRESS: 'CHANGE_IN_PROGRESS',
    CANCELLED: 'CANCELLED'
} as const;
export type DriverStatusDto = typeof DriverStatusDto[keyof typeof DriverStatusDto];


export function DriverStatusDtoFromJSON(json: any): DriverStatusDto {
    return DriverStatusDtoFromJSONTyped(json, false);
}

export function DriverStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverStatusDto {
    return json as DriverStatusDto;
}

export function DriverStatusDtoToJSON(value?: DriverStatusDto | null): any {
    return value as any;
}

