/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  FailureDto,
  HistoryPageDto,
  PageableDto,
  StaffDto,
  StaffPageDto,
  StaffPatchDto,
  StaffReferencePageDto,
  StaffTypeDto,
  WorkingPeriodsLimitationDto,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    StaffDtoFromJSON,
    StaffDtoToJSON,
    StaffPageDtoFromJSON,
    StaffPageDtoToJSON,
    StaffPatchDtoFromJSON,
    StaffPatchDtoToJSON,
    StaffReferencePageDtoFromJSON,
    StaffReferencePageDtoToJSON,
    StaffTypeDtoFromJSON,
    StaffTypeDtoToJSON,
    WorkingPeriodsLimitationDtoFromJSON,
    WorkingPeriodsLimitationDtoToJSON,
} from '../models/index';

export interface ActivateStaffRequest {
    id: string;
    expiresAt: Date;
}

export interface BelongsToInactiveSectionsRequest {
    staffId?: string;
}

export interface CreateStaffRequest {
    staffDto: StaffDto;
}

export interface DeactivateStaffRequest {
    id: string;
}

export interface FetchStaffRequest {
    id: string;
}

export interface FetchStaffHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface GetWorkingPeriodLimitationRequest {
    staffType: StaffTypeDto;
}

export interface PatchStaffRequest {
    id: string;
    staffPatchDto: StaffPatchDto;
}

export interface SearchStaffRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchStaffReferencesRequest {
    q?: string;
    pageable?: PageableDto;
    types?: Array<StaffTypeDto>;
}

export interface TemplateStaffRequest {
    personId: string;
}

export interface UpdateStaffRequest {
    id: string;
    staffDto: StaffDto;
}

/**
 * 
 */
export class StaffApi extends runtime.BaseAPI {

    /**
     * Activate the staff
     */
    async activateStaffRaw(requestParameters: ActivateStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling activateStaff().'
            );
        }

        if (requestParameters['expiresAt'] == null) {
            throw new runtime.RequiredError(
                'expiresAt',
                'Required parameter "expiresAt" was null or undefined when calling activateStaff().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['expiresAt'] != null) {
            queryParameters['expiresAt'] = (requestParameters['expiresAt'] as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/staff/{id}/activate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Activate the staff
     */
    async activateStaff(requestParameters: ActivateStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.activateStaffRaw(requestParameters, initOverrides);
    }

    /**
     */
    async belongsToInactiveSectionsRaw(requestParameters: BelongsToInactiveSectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters['staffId'] != null) {
            queryParameters['staffId'] = requestParameters['staffId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/staff/belongs-to-inactive-sections`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async belongsToInactiveSections(requestParameters: BelongsToInactiveSectionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.belongsToInactiveSectionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new staff.
     */
    async createStaffRaw(requestParameters: CreateStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StaffDto>> {
        if (requestParameters['staffDto'] == null) {
            throw new runtime.RequiredError(
                'staffDto',
                'Required parameter "staffDto" was null or undefined when calling createStaff().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/staff`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StaffDtoToJSON(requestParameters['staffDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StaffDtoFromJSON(jsonValue));
    }

    /**
     * Create a new staff.
     */
    async createStaff(requestParameters: CreateStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StaffDto> {
        const response = await this.createStaffRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deactivate the staff
     */
    async deactivateStaffRaw(requestParameters: DeactivateStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deactivateStaff().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/staff/{id}/deactivate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deactivate the staff
     */
    async deactivateStaff(requestParameters: DeactivateStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deactivateStaffRaw(requestParameters, initOverrides);
    }

    /**
     * Get the staff business object.
     */
    async fetchStaffRaw(requestParameters: FetchStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StaffDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchStaff().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/staff/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StaffDtoFromJSON(jsonValue));
    }

    /**
     * Get the staff business object.
     */
    async fetchStaff(requestParameters: FetchStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StaffDto> {
        const response = await this.fetchStaffRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the staff.
     */
    async fetchStaffHistoryRaw(requestParameters: FetchStaffHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchStaffHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/staff/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the staff.
     */
    async fetchStaffHistory(requestParameters: FetchStaffHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchStaffHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the working period limitation for staff type.
     */
    async getWorkingPeriodLimitationRaw(requestParameters: GetWorkingPeriodLimitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkingPeriodsLimitationDto>> {
        if (requestParameters['staffType'] == null) {
            throw new runtime.RequiredError(
                'staffType',
                'Required parameter "staffType" was null or undefined when calling getWorkingPeriodLimitation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/staff/{staffType}/working-period-limitation`.replace(`{${"staffType"}}`, encodeURIComponent(String(requestParameters['staffType']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkingPeriodsLimitationDtoFromJSON(jsonValue));
    }

    /**
     * Get the working period limitation for staff type.
     */
    async getWorkingPeriodLimitation(requestParameters: GetWorkingPeriodLimitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkingPeriodsLimitationDto> {
        const response = await this.getWorkingPeriodLimitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Patch the existing staff.
     */
    async patchStaffRaw(requestParameters: PatchStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StaffDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling patchStaff().'
            );
        }

        if (requestParameters['staffPatchDto'] == null) {
            throw new runtime.RequiredError(
                'staffPatchDto',
                'Required parameter "staffPatchDto" was null or undefined when calling patchStaff().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/merge-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/staff/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: StaffPatchDtoToJSON(requestParameters['staffPatchDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StaffDtoFromJSON(jsonValue));
    }

    /**
     * Patch the existing staff.
     */
    async patchStaff(requestParameters: PatchStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StaffDto> {
        const response = await this.patchStaffRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for staff
     */
    async searchStaffRaw(requestParameters: SearchStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StaffPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/staff`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StaffPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for staff
     */
    async searchStaff(requestParameters: SearchStaffRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StaffPageDto> {
        const response = await this.searchStaffRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchStaffReferencesRaw(requestParameters: SearchStaffReferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StaffReferencePageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        if (requestParameters['types'] != null) {
            queryParameters['types'] = requestParameters['types'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/staff-references`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StaffReferencePageDtoFromJSON(jsonValue));
    }

    /**
     */
    async searchStaffReferences(requestParameters: SearchStaffReferencesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StaffReferencePageDto> {
        const response = await this.searchStaffReferencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new staff.
     */
    async templateStaffRaw(requestParameters: TemplateStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StaffDto>> {
        if (requestParameters['personId'] == null) {
            throw new runtime.RequiredError(
                'personId',
                'Required parameter "personId" was null or undefined when calling templateStaff().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/staff/template/{personId}`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters['personId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StaffDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new staff.
     */
    async templateStaff(requestParameters: TemplateStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StaffDto> {
        const response = await this.templateStaffRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the existing staff.
     */
    async updateStaffRaw(requestParameters: UpdateStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StaffDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateStaff().'
            );
        }

        if (requestParameters['staffDto'] == null) {
            throw new runtime.RequiredError(
                'staffDto',
                'Required parameter "staffDto" was null or undefined when calling updateStaff().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/staff/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StaffDtoToJSON(requestParameters['staffDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StaffDtoFromJSON(jsonValue));
    }

    /**
     * Update the existing staff.
     */
    async updateStaff(requestParameters: UpdateStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StaffDto> {
        const response = await this.updateStaffRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
