import React from 'react';
import { useParams } from 'react-router-dom';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchVehicleBooking } from '../vehicle-booking-queries';
import { getVehicleBookingId } from './get-vehicle-booking-id';

export default function VehicleBookingBreadcrumb() {
  const vehicleBooking = useFetcher(fetchVehicleBooking, { id: getVehicleBookingId(useParams()) });

  return <>{vehicleBooking.transactionNumber}</>;
}
