import { useTranslation } from 'react-i18next';
import { EventReferenceDto } from '../../../api';
import { eventIcon } from '../../../ui/icons/business-objects';
import Reference, { ReferenceProps } from '../../../ui/reference/reference';

export interface EventReferenceProps extends Omit<ReferenceProps, 'icon' | 'to' | 'children'> {
  event?: EventReferenceDto;
  mobile?: boolean;
  useEnglishTitle?: boolean;
  isInline?: boolean;
  fullWidth?: boolean;
  readOnly?: boolean;
}

export default function EventReference({
  event,
  useEnglishTitle = false,
  mobile = false,
  isInline,
  fullWidth = false,
  readOnly = false,
  ...props
}: EventReferenceProps) {
  const { t } = useTranslation('event', { useSuspense: false });
  if (event == null) {
    return (
      <Reference color="heading" display="flex" alignItems="center" icon={eventIcon} {...props}>
        {t('forbiddenEventTitle')}
      </Reference>
    );
  }

  return (
    <Reference
      icon={eventIcon}
      to={readOnly ? undefined : `${mobile ? '/mobile' : ''}/events/${event.id}`}
      ml={isInline ? 1 : 0}
      width={fullWidth ? 'full' : undefined}
      necessaryPermission={({ hasPermission, hasPermissionFromSection }) =>
        hasPermission('EVENT:CAN_SEE_DETAILS') ||
        event.sectionIdsRequiredToSeeEvent.some((sectionId) =>
          hasPermissionFromSection(sectionId, 'EVENT:CAN_SEE_DETAILS_OWN'),
        )
      }
      {...props}
    >
      {useEnglishTitle ? event.englishTitle : event.title}
    </Reference>
  );
}
