import { faCars } from '@fortawesome/pro-regular-svg-icons';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import EVENT_CHILD_ROUTES from '../event/event-child-routes';
import { EVENT_MENU_ITEM, EventMenuItem } from '../event/event-page/event-page-menu';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import driveUpListEventRoutes from './drive-up-list-event-routes';
import { useDriveUpListPermissionClassGroupExtension } from './drive-up-list-permissions';

export const driveUpListEventMenuItem: EventMenuItem = {
  order: 6,
  label: <Translate ns="drive_up_list" i18nKey="driveUpList" />,
  icon: faCars,
  path: (eventId) => `/events/${eventId}/drive-up-list`,
  isVisible: (ownerSectionIds, permissions) =>
    permissions.hasPermission('DRIVE_UP_LIST:CAN_SEE_DETAILS') ||
    ownerSectionIds.some((sectionId) =>
      permissions.hasPermissionFromSection(sectionId, 'DRIVE_UP_LIST:CAN_SEE_DETAILS_OWN'),
    ),
};

export default function DriveUpListPluginProvider(registry: PluginRegistry) {
  registry.register(EVENT_MENU_ITEM, driveUpListEventMenuItem);
  registry.register(EVENT_CHILD_ROUTES, driveUpListEventRoutes);
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useDriveUpListPermissionClassGroupExtension);
}
