import { HStack } from '@chakra-ui/react';
import React from 'react';
import {
  CollectiveOrderConfirmationViewerDto,
  InvoiceReferenceDto,
  InvoiceReferenceDtoFromJSON,
  OrderConfirmationViewerDto,
} from '../../../api';
import { DescriptionItem } from '../../../ui/description';
import Translate from '../../../util/translate/translate';
import InvoiceReference from '../invoice-reference/invoice-reference';

interface OrderConfirmationInvoiceDescriptionItemProps {
  orderConfirmation: OrderConfirmationViewerDto | CollectiveOrderConfirmationViewerDto;
}

export default function InvoiceOrderConfirmationDescriptionItem({
  orderConfirmation,
}: OrderConfirmationInvoiceDescriptionItemProps) {
  const invoices = useInvoices(orderConfirmation);

  return invoices != null ? (
    <DescriptionItem label={<Translate ns="invoice" i18nKey="invoices" />} span={2}>
      <HStack>
        {invoices.map((invoice, index) => (
          <InvoiceReference invoice={{ id: invoice.id, invoiceNumber: invoice.invoiceNumber }} key={index} />
        ))}
      </HStack>
    </DescriptionItem>
  ) : undefined;
}

function useInvoices(
  orderConfirmation: OrderConfirmationViewerDto | CollectiveOrderConfirmationViewerDto,
): InvoiceReferenceDto[] {
  return orderConfirmation.extensions.invoice?.map((item: any) => InvoiceReferenceDtoFromJSON(item));
}
