import { Progress } from '@chakra-ui/react';
import { useNavigation } from 'react-router-dom';

/**
 * Shows an loading bar when the app is currently navigating.
 * This happens when we are loading website chunks or waiting for loaders to finish loading.
 */
export default function AppSpinner() {
  const { state } = useNavigation();

  return (
    state === 'loading' && (
      <Progress
        size="xs"
        isIndeterminate
        background="transparent"
        position="absolute"
        bottom="-1px"
        insetX="0"
        height="1px"
      />
    )
  );
}
