import collectiveOrderConfirmationApi from '../../../data-access/collective-order-confirmation-api';
import { createFetcherScope } from '../../../util/swr/fetcher-scope';

export const collectiveOrderConfirmationFetcher = createFetcherScope(collectiveOrderConfirmationApi);

export const fetchCollectiveOrderConfirmation = collectiveOrderConfirmationFetcher.createFetcher(
  collectiveOrderConfirmationApi.fetchCollectiveOrderConfirmation,
);
export const fetchCollectiveOrderConfirmationHistory = collectiveOrderConfirmationFetcher.createFetcher(
  collectiveOrderConfirmationApi.fetchCollectiveOrderConfirmationHistory,
);
export const fetchConnectedOrderConfirmationsHistory = collectiveOrderConfirmationFetcher.createFetcher(
  collectiveOrderConfirmationApi.fetchConnectedOrderConfirmationsHistory,
);

export const fetchCollectedOrderConfirmations = collectiveOrderConfirmationFetcher.createFetcher(
  collectiveOrderConfirmationApi.fetchOrderConfirmations,
);
