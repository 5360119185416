import React from 'react';
import { VehicleBookingDto } from '../../../api';
import { VehicleBookingViewerItemExtension } from '../../vehicle-booking/vehicle-booking-viewer/vehicle-booking-viewer-item-extension';
import DrivingAssignmentVehicleBookingDescriptionItem from './driving-assignment-vehicle-booking-description-item';

export const drivingAssignmentVehicleBookingViewerItem: VehicleBookingViewerItemExtension = {
  item: (vehicleBooking: VehicleBookingDto, key: number) => (
    <DrivingAssignmentVehicleBookingDescriptionItem vehicleBooking={vehicleBooking} key={key} />
  ),
};
