/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const NotificationModeDto = {
    ONCE: 'ONCE',
    ALWAYS: 'ALWAYS'
} as const;
export type NotificationModeDto = typeof NotificationModeDto[keyof typeof NotificationModeDto];


export function NotificationModeDtoFromJSON(json: any): NotificationModeDto {
    return NotificationModeDtoFromJSONTyped(json, false);
}

export function NotificationModeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationModeDto {
    return json as NotificationModeDto;
}

export function NotificationModeDtoToJSON(value?: NotificationModeDto | null): any {
    return value as any;
}

