import React from 'react';
import { redirect } from 'react-router-dom';
import registry from '../../registry';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import CollectiveOrderConfirmationBreadcrumb from './collective-order-confirmation/collective-order-confirmation-breadcrumb';
import COLLECTIVE_ORDER_CONFIRMATION_CHILD_ROUTES from './collective-order-confirmation/collective-order-confirmation-child-routes';
import OrderConfirmationBreadcrumb from './common/order-confirmation-breadcrumb';
import ORDER_CONFIRMATION_CHILD_ROUTES from './order-confirmation-child-routes';

const collectiveOrderConfirmationRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'collective-order-confirmations',
    handle: {
      breadcrumb: <Translate ns="order_confirmation" i18nKey="collectiveOrderConfirmation.label" />,
    },
    children: [
      {
        index: true,
        loader: () => redirect('/order-confirmations'),
      },
      {
        path: 'new',
        lazy: () =>
          import(
            './collective-order-confirmation/collective-order-confirmation-editor/new-collective-order-confirmation-route'
          ),
      },
      {
        path: ':collectiveOrderConfirmationId',
        handle: {
          breadcrumb: <CollectiveOrderConfirmationBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () =>
              import(
                './collective-order-confirmation/collective-order-confirmation-page/collective-order-confirmation-page-route'
              ),
            children: [
              {
                path: '',
                lazy: () =>
                  import(
                    './collective-order-confirmation/collective-order-confirmation-viewer/collective-order-confirmation-viewer-route'
                  ),
              },
              {
                path: 'order-confirmations',
                lazy: () =>
                  import(
                    './collective-order-confirmation/collective-order-confirmation-lister/collective-order-confirmation-lister-route'
                  ),
              },
              {
                path: 'history',
                lazy: () =>
                  import(
                    './collective-order-confirmation/collective-order-confirmation-history/collective-order-confirmation-history-route'
                  ),
              },
            ],
          },
          {
            path: 'edit',
            lazy: () =>
              import(
                './collective-order-confirmation/collective-order-confirmation-editor/edit-collective-order-confirmation-route'
              ),
          },
          {
            path: 'replace',
            lazy: () =>
              import(
                './collective-order-confirmation/collective-order-confirmation-editor/replace-collective-order-confirmation-route'
              ),
          },
          ...registry.getAll(COLLECTIVE_ORDER_CONFIRMATION_CHILD_ROUTES).flat(),
        ],
      },
    ],
  },
];

const orderConfirmationRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'order-confirmations',
    handle: {
      breadcrumb: <Translate ns="order_confirmation" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./order-confirmation-lister/order-confirmation-lister-route'),
      },
      {
        path: 'new',
        lazy: () => import('./order-confirmation-editor/new-order-confirmation-route'),
      },
      {
        path: ':orderConfirmationId',
        handle: {
          breadcrumb: <OrderConfirmationBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./order-confirmation-page/order-confirmation-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./order-confirmation-viewer/order-confirmation-viewer-route'),
              },
              {
                path: 'history',
                lazy: () => import('./order-confirmation-history/order-confirmation-history-route'),
              },
            ],
          },
          {
            path: 'edit',
            lazy: () => import('./order-confirmation-editor/edit-order-confirmation-route'),
          },
          ...registry.getAll(ORDER_CONFIRMATION_CHILD_ROUTES).flat(),
        ],
      },
    ],
  },
  ...collectiveOrderConfirmationRoutes,
];
export default orderConfirmationRoutes;
