import { Badge, Box, Button, ButtonGroup, GridItem, Spinner } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHistory, faHomeAlt } from '@fortawesome/pro-regular-svg-icons';
import { sortBy } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import invariant from 'tiny-invariant';
import eventApi from '../../../data-access/event-api';
import { DisableableRouterLink } from '../../../ui/disableable-button/disableable-router-link';
import { PageHeader, PageTab } from '../../../ui/page';
import Page from '../../../ui/page/page';
import { PageContentProps } from '../../../ui/page/page-content';
import usePlugins, { PluginToken } from '../../../util/plugin/use-plugins';
import EditionHeader from '../../edition/focused-edition-header/edition-header';
import { useSetFocusedEdition } from '../../edition/use-focused-or-active-edition/use-focused-or-active-edition-id';
import SubscribeButton from '../../notification/subscribe-button/subscribe-button';
import usePermission from '../../permission/use-permission';
import { EventMenuList } from '../event-menu/event-menu';
import useEvent from '../use-event/use-event';
import EventPageMenu from './event-page-menu';

export interface EventTabItem {
  order: number;
  label: React.ReactNode;
  icon: IconProp;
  to: string;
}

export const EVENT_TAB_ITEM = new PluginToken<EventTabItem>('EventTabItem');

export interface EventPageProps extends PageContentProps {
  actions?: React.ReactNode;
  actionHint?: React.ReactNode;
  tabs?: React.ReactNode;
  children?: React.ReactNode;
}

/**
 * Represents the event page. It includes the header of {@Link EventViewer} and the {@Link FocusedEditionHeader}.
 */
export default function EventPage({
  actions = <EventPageActions />,
  tabs = <EventPageTabs />,
  children = <Outlet />,
  actionHint = undefined,
}: EventPageProps) {
  const event = usePageEvent();
  useSetFocusedEdition(event.edition.id);

  return (
    <Page
      header={
        <GridItem>
          <EditionHeader editionId={event.edition.id} navigationTarget="events" />
          <PageHeader
            title={event.title}
            actions={actions}
            actionHint={actionHint}
            menu={<EventPageMenu />}
            tabs={tabs}
          />
        </GridItem>
      }
    >
      {children}
    </Page>
  );
}

function EventPageTabs() {
  const { eventId } = useParams<{ eventId: string }>();
  invariant(eventId, 'Empty eventId');
  const event = usePageEvent();

  const { t } = useTranslation('event');
  const { t: tCommon } = useTranslation('common');
  const { t: tAttachment } = useTranslation('attachment');

  const { hasPermission, hasPermissionFromSection } = usePermission();
  const canSeeComments =
    hasPermission('EVENT:CAN_SEE_COMMENT') ||
    event.owners
      .map((owner) => owner.section.id)
      .some((sectionId) => hasPermissionFromSection(sectionId, 'EVENT:CAN_SEE_COMMENT_OWN'));

  const canAccessAttachments =
    hasPermission('EVENT:CAN_ACCESS_ATTACHMENTS') ||
    event.owners
      .map((owner) => owner.section.id)
      .some((sectionId) => hasPermissionFromSection(sectionId, 'EVENT:CAN_ACCESS_ATTACHMENTS_OWN'));

  const tabItems = usePlugins(EVENT_TAB_ITEM);
  const sortedTabItems = React.useMemo(() => sortBy(tabItems, 'order'), [tabItems]);

  const { data: comments } = useSWR(['eventComments', eventId], () =>
    canSeeComments
      ? eventApi.fetchEventComments({
          eventId,
          pageable: {
            size: 1,
          },
        })
      : undefined,
  );

  return (
    <>
      <PageTab to="." icon={faHomeAlt} />
      {canAccessAttachments && <PageTab to="./attachments">{tAttachment('attachments')}</PageTab>}
      {canSeeComments && (
        <PageTab to="./comments">
          {t('comments.label')}
          <Badge colorScheme="teal" variant="solid" ml={2} display="inline-flex">
            {comments?.totalElements ?? <Spinner color="white" size="xs" my={1} />}
          </Badge>
        </PageTab>
      )}
      {sortedTabItems.map((tabItem, index) => (
        <PageTab key={'additionalTab' + index} to={tabItem.to} icon={tabItem.icon}>
          {tabItem.label}
        </PageTab>
      ))}
      <PageTab to="./history" icon={faHistory}>
        {tCommon('history.label')}
      </PageTab>
    </>
  );
}

function EventPageActions() {
  const { t } = useTranslation(['event', 'common']);
  const event = usePageEvent();
  const navigate = useNavigate();
  const { hasPermission, hasPermissionFromSection } = usePermission();

  const canEdit =
    hasPermission('EVENT:CAN_EDIT') ||
    event.owners
      .map((owner) => owner.section.id)
      .some((sectionId) => hasPermissionFromSection(sectionId, 'EVENT:CAN_EDIT_OWN'));

  return (
    <ButtonGroup>
      <Box>
        <EventMenuList event={event} onDelete={() => navigate(`/events`)} hasSwitchToMobileButton />
      </Box>
      <SubscribeButton type="Event" entityId={event.id!} />
      {(hasPermission('EVENT:CAN_EDIT') || hasPermission('EVENT:CAN_EDIT_OWN')) && (
        <Button
          as={DisableableRouterLink}
          to="edit"
          isDisabled={!canEdit}
          disableReason={t('common:validation_error.incomplete_permission')}
          variant="primary"
        >
          {t('event:action.edit')}
        </Button>
      )}
    </ButtonGroup>
  );
}

export function usePageEvent() {
  const { eventId } = useParams<{ eventId: string }>();
  invariant(eventId, 'Empty eventId');
  return useEvent(eventId);
}
