import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useHotelEditionDataPermissionClassGroupExtension(): PermissionClassGroupExtension<'HOTEL_EDITION_DATA'> {
  const { t } = useTranslation(['hotel_edition_data']);

  return {
    name: 'HOTEL_EDITION_DATA',
    label: t('hotel_edition_data:hotelEditionData'),
    getActionLabel: (action) => t(`hotel_edition_data:permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`hotel_edition_data:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`hotel_edition_data:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
