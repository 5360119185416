import { Button, ButtonGroup, HStack, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { MailingListPersonEntryDto, OccupationDto, PersonDto } from '../../../api';
import personApi from '../../../data-access/person-api';
import HistoryPage from '../../../ui/history/history-page';
import { PageContent } from '../../../ui/page';
import usePaginationState from '../../../ui/pagination/use-pagination-state';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchMailingListEntryHistory } from '../../mailing-list/mailing-list-queries';
import { fetchOccupationHistory, fetchPersonHistory } from '../person-queries';
import useMailingListHistorySettings from './use-mailing-list-history-settings';
import useOccupationHistorySettings from './use-occupation-history-settings';
import usePersonHistorySettings from './use-person-history-settings';

export default function PersonHistory() {
  const { t } = useTranslation(['person', 'common', 'mailing_list']);
  const [state, setState] = usePaginationState();
  const personHistorySettings = usePersonHistorySettings();
  const occupationHistorySettings = useOccupationHistorySettings();
  const mailingListHistorySettings = useMailingListHistorySettings();
  const { personId } = useParams<{ personId: string }>();
  invariant(personId != null, 'Missing personId');
  const page = useFetcher(fetchPersonHistory, { id: personId, pageable: state });
  const occupationPage = useFetcher(fetchOccupationHistory, { id: personId, pageable: state });
  const mailingListPage = useFetcher(fetchMailingListEntryHistory, { id: personId, pageable: state });
  invariant(page != null, 'Missing page');

  const fetchSlimPerson = (id: string, signal: AbortSignal) => personApi.fetchSlimPerson({ personId: id }, { signal });

  const [selectedHistory, setSelectedHistory] = React.useState(0);

  const showPersonHistory = () => {
    setSelectedHistory(0);
  };

  const showOccupationHistory = () => {
    setSelectedHistory(1);
  };

  const showMailingListHistory = () => {
    setSelectedHistory(2);
  };

  return (
    <PageContent>
      <Stack spacing={0}>
        <HStack pb={4}>
          <Text fontSize="xl" fontWeight="bold" width="30%">
            {t('common:history.history_selection')}
          </Text>
          <ButtonGroup isAttached variant="outline" width="full">
            <Button width="33%" isActive={selectedHistory === 0} onClick={showPersonHistory}>
              {t('person:person')}
            </Button>
            <Button width="33%" isActive={selectedHistory === 1} onClick={showOccupationHistory}>
              {t('person:occupations.label')}
            </Button>
            <Button width="33%" isActive={selectedHistory === 2} onClick={showMailingListHistory}>
              {t('mailing_list:lister.title')}
            </Button>
          </ButtonGroup>
        </HStack>
        {selectedHistory === 0 && (
          <HistoryPage<PersonDto>
            page={page}
            state={state}
            onStateChange={setState}
            historyDisplaySettings={personHistorySettings}
            fetchMerged={fetchSlimPerson}
          />
        )}
        {selectedHistory === 1 && (
          <HistoryPage<OccupationDto>
            page={occupationPage}
            state={state}
            onStateChange={setState}
            historyDisplaySettings={occupationHistorySettings}
            fetchMerged={fetchSlimPerson}
            showLine={false}
          />
        )}
        {selectedHistory === 2 && (
          <HistoryPage<MailingListPersonEntryDto>
            page={mailingListPage}
            state={state}
            onStateChange={setState}
            historyDisplaySettings={mailingListHistorySettings}
            fetchMerged={fetchSlimPerson}
            showLine={false}
          />
        )}
      </Stack>
    </PageContent>
  );
}
