import { Button, Center, GridItem, HStack, Menu, MenuItem, MenuList, Portal, Spinner } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHistory, faHomeAlt, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { endOfDay, isBefore } from 'date-fns';
import { sortBy } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderFunctionArgs, Outlet, useNavigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { GroupDto, GroupStatusDto, OwnerTypeDto } from '../../../api';
import groupApi from '../../../data-access/group-api';
import { BurgerMenuButton } from '../../../ui/burger-menu-button/burger-menu-button';
import { DisableableRouterLink } from '../../../ui/disableable-button/disableable-router-link';
import Page, { PageHeader, PageTab } from '../../../ui/page';
import now from '../../../util/now';
import usePlugins, { PluginToken } from '../../../util/plugin/use-plugins';
import useFetcher from '../../../util/swr/use-fetcher';
import EditionHeader from '../../edition/focused-edition-header/edition-header';
import { useSetFocusedEdition } from '../../edition/use-focused-or-active-edition/use-focused-or-active-edition-id';
import SubscribeButton from '../../notification/subscribe-button/subscribe-button';
import usePermission, { Permission } from '../../permission/use-permission';
import { getGroupId } from '../common/get-group-id';
import GroupDeleteButton from '../group-delete/group-delete-button';
import { fetchGroup } from '../group-queries';

export interface GroupTabItem {
  order: number;
  label: React.ReactNode;
  icon: IconProp;
  to: string;
  isVisible?: (isUserInternalGroupAdmin: boolean, hasPermission: (permission: Permission) => boolean) => boolean;
}

export const GROUP_TAB_ITEM = new PluginToken<GroupTabItem>('GroupTabItem');

export const Component = GroupPageRoute;

export const loader = ({ params }: LoaderFunctionArgs) => {
  return fetchGroup.mutate({ id: String(params.groupId) });
};

function GroupPageRoute() {
  const { t } = useTranslation(['common', 'group']);
  const params = useParams<{ groupId: string }>();
  const { hasPermission, hasPermissionFromSection } = usePermission();
  const group = useFetcher(fetchGroup, { id: getGroupId(params) });
  useSetFocusedEdition(group.edition.id!);
  const tabs = <GroupPageTabs group={group} />;
  const navigate = useNavigate();
  const [isDeletable, setDeletable] = React.useState<boolean | null>(null);

  const checkStatus = async () => {
    setDeletable(null);
    invariant(group.id != null, 'Missing group id');
    const response = await groupApi.groupDeletable({ id: group.id });
    setDeletable(response.value);
  };

  const groupOwners = group.owners.filter((owner) => owner.type == OwnerTypeDto.OWNER);
  const canEdit =
    hasPermission('GROUP:CAN_EDIT') ||
    groupOwners.some((owner) => hasPermissionFromSection(owner.section.id, 'GROUP:CAN_EDIT_OWN'));
  const canDelete =
    hasPermission('GROUP:CAN_DELETE') ||
    groupOwners.some((owner) => hasPermissionFromSection(owner.section.id, 'GROUP:CAN_DELETE_OWN'));

  return (
    <Page
      header={
        <GridItem>
          <EditionHeader editionId={group.edition.id} navigationTarget="groups" />
          <PageHeader
            title={group.name}
            tabs={tabs}
            actions={
              <HStack>
                <HStack spacing={0}>
                  {canDelete && (
                    <Menu onOpen={checkStatus}>
                      <BurgerMenuButton />
                      <Portal>
                        <MenuList>
                          {isDeletable == null ? (
                            <Center p={2}>
                              <Spinner size="md" />
                            </Center>
                          ) : (
                            <>
                              <MenuItem
                                as={GroupDeleteButton}
                                group={group}
                                inActiveEdition={group.edition.active}
                                color="text.error"
                                deletable={isDeletable}
                                onSuccess={() => navigate(`/groups`)}
                                icon={<FontAwesomeIcon icon={faTrashAlt} />}
                              >
                                {t('group:action.delete')}
                              </MenuItem>
                            </>
                          )}
                        </MenuList>
                      </Portal>
                    </Menu>
                  )}
                  <SubscribeButton type="Group" entityId={group.id!} />
                </HStack>
                {canEdit && (
                  <Button
                    as={DisableableRouterLink}
                    to="edit"
                    variant="primary"
                    isDisabled={
                      isBefore(endOfDay(group.edition.dateRange.end), new Date(now())) ||
                      group.status !== GroupStatusDto.ACTIVE
                    }
                    disableReason={t('group:status_inactive_message')}
                  >
                    {t('group:action.edit')}
                  </Button>
                )}
              </HStack>
            }
          />
        </GridItem>
      }
    >
      <Outlet />
    </Page>
  );
}

interface GroupPageTabsProps {
  group: GroupDto;
}

function GroupPageTabs({ group }: GroupPageTabsProps) {
  const { t } = useTranslation(['common', 'group']);
  const { hasPermission, hasPermissionFromSection } = usePermission();
  const tabItems = usePlugins(GROUP_TAB_ITEM);
  const sortedTabItems = React.useMemo(
    () =>
      sortBy(
        tabItems.filter(
          (tabItem) => tabItem.isVisible == null || tabItem.isVisible(group.userIsInternalGroupAdmin, hasPermission),
        ),
        'order',
      ),
    [group.userIsInternalGroupAdmin, hasPermission, tabItems],
  );

  const canSeeExternalApplicationsTab = hasPermission('GROUP:CAN_SEE_EXTERNAL_APPLICATIONS');

  const ownerSections = group.owners.filter((owner) => owner.type == OwnerTypeDto.OWNER).map((owner) => owner.section);

  const ownerCanSeeExternalApplicationsTab = ownerSections.some((section) =>
    hasPermissionFromSection(section.id, 'GROUP:CAN_SEE_EXTERNAL_APPLICATIONS_OWN'),
  );

  const internalGroupAdminCanSeeExternalApplicationsTab =
    hasPermission('GROUP:CAN_SEE_EXTERNAL_APPLICATIONS_INTERNAL_GROUP_ADMIN') && group.userIsInternalGroupAdmin;

  const showExternalApplicationsTab =
    canSeeExternalApplicationsTab ||
    ownerCanSeeExternalApplicationsTab ||
    internalGroupAdminCanSeeExternalApplicationsTab;

  return (
    <>
      <PageTab to="." icon={faHomeAlt} />
      <PageTab to="./personRelations">{t('group:connections.persons')}</PageTab>
      {showExternalApplicationsTab && (
        <PageTab to="./external-applications">{t('group:personGroupApplications.label')}</PageTab>
      )}
      <PageTab to="./companyRelations">{t('group:connections.companies')}</PageTab>
      <PageTab to="./staffRelations">{t('group:connections.staff')}</PageTab>
      <PageTab to="./filmRelations">{t('group:filmRelation.label')}</PageTab>
      {sortedTabItems.map((tabItem, index) => (
        <PageTab key={'additionalTab' + index} to={tabItem.to} icon={tabItem.icon}>
          {tabItem.label}
        </PageTab>
      ))}
      <PageTab to="./history" icon={faHistory}>
        {t('common:history.label')}
      </PageTab>
    </>
  );
}
