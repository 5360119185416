import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageContent, PageHeader } from '../../../ui/page';
import Page from '../../../ui/page/page';
import ExportDataTable from './export-data-table';

export default function ExportLister() {
  const { t } = useTranslation('export');

  return (
    <Page header={<PageHeader title={t('lister.title')} />}>
      <PageContent useFullWidth fixedHeight>
        <Box w="full" mb={2}>
          <Text>{t('lister.hint')}</Text>
        </Box>
        <ExportDataTable />
      </PageContent>
    </Page>
  );
}
