import { Box, Button, Heading, HStack, Text, Stack, useId } from '@chakra-ui/react';
import { useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { NotificationDto } from '../../../api';
import notificationApi from '../../../data-access/notification-api';
import { renderBusinessObjectIcon } from '../../../ui/icons/business-objects';
import isFocusable from '../../../util/dom/is-focusable';
import { useNotificationTypeExtension } from '../notification-type-extension';
import Optional from '../../../ui/optional/optional';

interface NotificationProps {
  notification: NotificationDto;
  onClick: () => void;
}

export default function Notification({ notification, onClick }: NotificationProps) {
  const { t } = useTranslation(['notification', 'common']);
  const [isHighlighted, toggleHighlight] = useReducer((last) => !last, false);
  const extension = useNotificationTypeExtension(notification.type);
  const id = useId();

  const linkRef = useRef<HTMLAnchorElement>(null);

  const markAsRead = async () => {
    await notificationApi.readNotification({ id: notification.entityId, type: notification.type });
  };
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isFocusable(event.target as Element)) {
      return;
    }

    // Propagate click event to link
    linkRef.current?.click();
  };

  return (
    <Box
      role="option"
      sx={{
        w: 'full',
        px: notification.read ? 6 : 3,
        py: 2,
        borderWidth: notification.read || '1px 1px 1px 12px',
        borderColor: 'teal.600',
        borderRadius: 'md',
        cursor: 'pointer',
        transition: 'background-color 0.15s ease-in-out',
        '&.is-highlighted': {
          bgColor: 'layer.02',
        },
      }}
      onMouseOver={toggleHighlight}
      onMouseOut={toggleHighlight}
      aria-selected={isHighlighted}
      className={isHighlighted ? 'is-highlighted' : undefined}
      onClick={(event) => {
        handleClick(event);
      }}
      aria-labelledby={id}
    >
      <Button
        variant="link"
        as={RouterLink}
        to={extension.linkUrl(notification.entityId)}
        whiteSpace="initial"
        onClick={() => {
          if (!notification.read) {
            markAsRead();
          }
          onClick();
        }}
        ref={linkRef}
      >
        <Heading fontSize="md" size="md" lineHeight={6} id={id}>
          <HStack as="span">
            <span>{renderBusinessObjectIcon(extension.icon)}</span>
            <span>{notification.title}</span>
          </HStack>
        </Heading>
      </Button>
      <Stack ml={7} mt={0.5} lineHeight={5} spacing={0} textColor="text.muted" sx={{ hyphens: 'auto' }} fontSize="sm">
        <Text as="span">
          {t('notification:createdAt')}
          {t('common:format.date_time', { dateTime: notification.createdAt })}
        </Text>
        <Text as="span">
          {t('notification:modifiedBy')}
          {notification.entityModifiedBy?.displayName}
        </Text>
        <Optional>
          {notification.edition && (
            <Text as="span">
              {t('notification:edition')}
              {notification.edition.name}
            </Text>
          )}
        </Optional>
      </Stack>
    </Box>
  );
}
