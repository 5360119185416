import { Button, IconButton, useDisclosure } from '@chakra-ui/react';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  CollectiveOrderConfirmationStatusDto,
  CollectiveOrderConfirmationViewerDto,
  OrderConfirmationListItemDto,
} from '../../../../api';
import { DisableableRouterLink } from '../../../../ui/disableable-button/disableable-router-link';
import { getCollectiveOrderConfirmationNumber } from '../../order-confirmation-utils';
import CollectiveOrderConfirmationEditDialog from './collective-order-confirmation-edit-dialog';

export interface CollectiveOrderConfirmationEditButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  collectiveOrderConfirmation: CollectiveOrderConfirmationViewerDto | OrderConfirmationListItemDto;
  buttonType: typeof Button | typeof IconButton;
}

const CollectiveOrderConfirmationEditButton = React.forwardRef<
  HTMLButtonElement,
  CollectiveOrderConfirmationEditButtonProps
>(({ collectiveOrderConfirmation, buttonType, ...props }, ref) => {
  const { t } = useTranslation(['order_confirmation']);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const editorRoute = `/collective-order-confirmations/${collectiveOrderConfirmation.id}/edit`;
  const isDisabled =
    collectiveOrderConfirmation.status !== CollectiveOrderConfirmationStatusDto.SEND &&
    collectiveOrderConfirmation.status !== CollectiveOrderConfirmationStatusDto.NOT_SEND;
  const disableReason = t('order_confirmation:collectiveOrderConfirmation.action.edit.disabled', {
    collectiveOrderConfirmationNumber: getCollectiveOrderConfirmationNumber(collectiveOrderConfirmation),
  });

  const handleClick = (e: React.MouseEvent) => {
    if (collectiveOrderConfirmation.status === CollectiveOrderConfirmationStatusDto.SEND) {
      e.preventDefault();
      onOpen();
    }
  };

  const handleDialogClose = async (confirm: boolean) => {
    if (confirm) {
      navigate(`/collective-order-confirmations/${collectiveOrderConfirmation.id}/replace`);
    }
    onClose();
  };

  if (buttonType === Button) {
    return (
      <>
        <Button
          as={DisableableRouterLink}
          {...props}
          ref={ref}
          to={editorRoute}
          onClick={handleClick}
          isDisabled={isDisabled}
          disableReason={disableReason}
          variant="primary"
        >
          {t('order_confirmation:collectiveOrderConfirmation.action.edit.label')}
        </Button>
        <CollectiveOrderConfirmationEditDialog
          collectiveOrderConfirmation={collectiveOrderConfirmation}
          onClose={handleDialogClose}
          isOpen={isOpen}
        />
      </>
    );
  }

  if (buttonType === IconButton) {
    return (
      <>
        <IconButton
          as={DisableableRouterLink}
          {...props}
          ref={ref}
          to={editorRoute}
          onClick={handleClick}
          isDisabled={isDisabled}
          disableReason={disableReason}
          variant="ghost"
          size="sm"
          aria-label={t('order_confirmation:collectiveOrderConfirmation.action.edit.label')}
          icon={<FontAwesomeIcon icon={faPencil} />}
        />
        <CollectiveOrderConfirmationEditDialog
          collectiveOrderConfirmation={collectiveOrderConfirmation}
          onClose={handleDialogClose}
          isOpen={isOpen}
        />
      </>
    );
  }
});

export default CollectiveOrderConfirmationEditButton;
