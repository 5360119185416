import { type InputProps, useControllableState } from '@chakra-ui/react';
import { callAllHandlers } from '@chakra-ui/utils';
import { useState } from 'react';

export default function useFormattedValue<T>({
  format,
  parse,
  value,
  onChange,
}: {
  format(value: T | string): string;
  parse(value: string): T | string;
  value: T | string | null;
  onChange(date: T | string): void;
}) {
  // State for switching between controlled and uncontrolled mode when focused or blurred.
  const [focused, setFocused] = useState(false);
  const formattedValue = value != null ? format(value) : '';

  const [stateValue = formattedValue, setStateValue] = useControllableState(
    // Use uncontrolled mode when focused to allow the user to enter any value and emit the parsed value to the field.
    // Use controlled mode when blurred to always display the formatted value from the field.
    focused
      ? {
          defaultValue: formattedValue,
          onChange(value) {
            onChange(parse(value));
          },
        }
      : {
          value: formattedValue,
        },
  );

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);

    // When the field is blurred, reset the state value to the formatted value.
    // Otherwise, useControllableState will keep the last value from the uncontrolled mode
    // and the field will jump back to this value when focused again.
    setStateValue(formattedValue);
  };

  return {
    getInputProps(props?: InputProps): InputProps {
      return {
        ...props,
        value: stateValue,
        onChange: callAllHandlers(props?.onChange, (event) => setStateValue(event.target.value)),
        onFocus: callAllHandlers(props?.onFocus, handleFocus),
        onBlur: callAllHandlers(props?.onBlur, handleBlur),
      };
    },
  };
}
