import React from 'react';
import { placementIcon } from '../../../ui/icons/business-objects';
import { PluginRegistry } from '../../../util/plugin/use-plugins';
import Translate from '../../../util/translate/translate';
import LOCATION_CHILD_ROUTES from '../../location/location-child-routes';
import { LOCATION_TAB_ITEM, LocationTabItem } from '../../location/location-page/location-page';
import { SEARCH_FILTER } from '../../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../../search/search-plugin';
import placementTemplateRoutes from './placement-template-routes';
import PlacementTemplateSearchResult from './placement-template-search-result';

export const placementTemplateLocationTabItem: LocationTabItem = {
  order: 3,
  label: <Translate ns="placement_template" i18nKey="placement_templates" />,
  icon: placementIcon,
  to: './placement-templates',
};

export default function PlacementTemplatePluginProvider(registry: PluginRegistry) {
  registry.register(LOCATION_TAB_ITEM, placementTemplateLocationTabItem);
  registry.register(LOCATION_CHILD_ROUTES, placementTemplateRoutes);
  registry.register(SEARCH_RESULT_CONTENT, {
    type: 'PLACEMENT_TEMPLATE',
    Component: PlacementTemplateSearchResult,
  });
  registry.register(SEARCH_FILTER, {
    key: 'PLACEMENT_TEMPLATE',
    icon: placementIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.PLACEMENT_TEMPLATE" />,
    necessaryPermission: 'LOCATION:CAN_SEE_PLACEMENT_TEMPLATE',
  });
}
