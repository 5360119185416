/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CollectiveOrderConfirmationDto,
  CollectiveOrderConfirmationViewerDto,
  CreateCollectiveOrderConfirmationDto,
  FailureDto,
  HistoryPageDto,
  ManualPaymentDto,
  OrderConfirmationPageDto,
  OrderConfirmationSendRequestDto,
  PageableDto,
} from '../models/index';
import {
    CollectiveOrderConfirmationDtoFromJSON,
    CollectiveOrderConfirmationDtoToJSON,
    CollectiveOrderConfirmationViewerDtoFromJSON,
    CollectiveOrderConfirmationViewerDtoToJSON,
    CreateCollectiveOrderConfirmationDtoFromJSON,
    CreateCollectiveOrderConfirmationDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    ManualPaymentDtoFromJSON,
    ManualPaymentDtoToJSON,
    OrderConfirmationPageDtoFromJSON,
    OrderConfirmationPageDtoToJSON,
    OrderConfirmationSendRequestDtoFromJSON,
    OrderConfirmationSendRequestDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface AddOrderConfirmationsRequest {
    id: string;
    requestBody: Array<string>;
}

export interface CancelRequest {
    id: string;
}

export interface CreateCollectiveOrderConfirmationRequest {
    createCollectiveOrderConfirmationDto: CreateCollectiveOrderConfirmationDto;
}

export interface DownloadCollectiveOrderConfirmationRequest {
    id: string;
}

export interface FetchCollectiveOrderConfirmationRequest {
    id: string;
}

export interface FetchCollectiveOrderConfirmationHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FetchConnectedOrderConfirmationsHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FetchOrderConfirmationsRequest {
    id: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface RecordManualPaymentCollectiveRequest {
    id: string;
    manualPaymentDto: ManualPaymentDto;
}

export interface RemoveOrderConfirmationRequest {
    id: string;
    orderConfirmationId: string;
}

export interface ReplaceCollectiveOrderConfirmationRequest {
    id: string;
    collectiveOrderConfirmationDto: CollectiveOrderConfirmationDto;
}

export interface SendCollectiveOrderConfirmationRequest {
    id: string;
    orderConfirmationSendRequestDto: OrderConfirmationSendRequestDto;
}

export interface UpdateCollectiveOrderConfirmationRequest {
    id: string;
    collectiveOrderConfirmationDto: CollectiveOrderConfirmationDto;
}

/**
 * 
 */
export class CollectiveOrderConfirmationApi extends runtime.BaseAPI {

    /**
     * Add multiple order confirmations to the collective order confirmation.
     */
    async addOrderConfirmationsRaw(requestParameters: AddOrderConfirmationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling addOrderConfirmations().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling addOrderConfirmations().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collective-order-confirmations/{id}/order-confirmations`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add multiple order confirmations to the collective order confirmation.
     */
    async addOrderConfirmations(requestParameters: AddOrderConfirmationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addOrderConfirmationsRaw(requestParameters, initOverrides);
    }

    /**
     * Cancel the collective order confirmation.
     */
    async cancelRaw(requestParameters: CancelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling cancel().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collective-order-confirmations/{id}/cancel`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Cancel the collective order confirmation.
     */
    async cancel(requestParameters: CancelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.cancelRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new collective order confirmation.
     */
    async createCollectiveOrderConfirmationRaw(requestParameters: CreateCollectiveOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['createCollectiveOrderConfirmationDto'] == null) {
            throw new runtime.RequiredError(
                'createCollectiveOrderConfirmationDto',
                'Required parameter "createCollectiveOrderConfirmationDto" was null or undefined when calling createCollectiveOrderConfirmation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collective-order-confirmations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCollectiveOrderConfirmationDtoToJSON(requestParameters['createCollectiveOrderConfirmationDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new collective order confirmation.
     */
    async createCollectiveOrderConfirmation(requestParameters: CreateCollectiveOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createCollectiveOrderConfirmationRaw(requestParameters, initOverrides);
    }

    /**
     * Download the collective order confirmation.
     */
    async downloadCollectiveOrderConfirmationRaw(requestParameters: DownloadCollectiveOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling downloadCollectiveOrderConfirmation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collective-order-confirmations/{id}/download`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download the collective order confirmation.
     */
    async downloadCollectiveOrderConfirmation(requestParameters: DownloadCollectiveOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadCollectiveOrderConfirmationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the collective order confirmation.
     */
    async fetchCollectiveOrderConfirmationRaw(requestParameters: FetchCollectiveOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectiveOrderConfirmationViewerDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchCollectiveOrderConfirmation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collective-order-confirmations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollectiveOrderConfirmationViewerDtoFromJSON(jsonValue));
    }

    /**
     * Get the collective order confirmation.
     */
    async fetchCollectiveOrderConfirmation(requestParameters: FetchCollectiveOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectiveOrderConfirmationViewerDto> {
        const response = await this.fetchCollectiveOrderConfirmationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the collective order confirmation.
     */
    async fetchCollectiveOrderConfirmationHistoryRaw(requestParameters: FetchCollectiveOrderConfirmationHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchCollectiveOrderConfirmationHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collective-order-confirmations/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the collective order confirmation.
     */
    async fetchCollectiveOrderConfirmationHistory(requestParameters: FetchCollectiveOrderConfirmationHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchCollectiveOrderConfirmationHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fetchConnectedOrderConfirmationsHistoryRaw(requestParameters: FetchConnectedOrderConfirmationsHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchConnectedOrderConfirmationsHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collective-order-confirmations/{id}/order-confirmations-versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchConnectedOrderConfirmationsHistory(requestParameters: FetchConnectedOrderConfirmationsHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchConnectedOrderConfirmationsHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get order confirmations of collective order confirmation.
     */
    async fetchOrderConfirmationsRaw(requestParameters: FetchOrderConfirmationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderConfirmationPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchOrderConfirmations().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collective-order-confirmations/{id}/order-confirmations`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderConfirmationPageDtoFromJSON(jsonValue));
    }

    /**
     * Get order confirmations of collective order confirmation.
     */
    async fetchOrderConfirmations(requestParameters: FetchOrderConfirmationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderConfirmationPageDto> {
        const response = await this.fetchOrderConfirmationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async recordManualPaymentCollectiveRaw(requestParameters: RecordManualPaymentCollectiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling recordManualPaymentCollective().'
            );
        }

        if (requestParameters['manualPaymentDto'] == null) {
            throw new runtime.RequiredError(
                'manualPaymentDto',
                'Required parameter "manualPaymentDto" was null or undefined when calling recordManualPaymentCollective().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collective-order-confirmations/{id}/record-manual-payment`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ManualPaymentDtoToJSON(requestParameters['manualPaymentDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async recordManualPaymentCollective(requestParameters: RecordManualPaymentCollectiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.recordManualPaymentCollectiveRaw(requestParameters, initOverrides);
    }

    /**
     * Remove the order confirmation. If the collective order confirmation had been sent, create a copy and cancel the old one, then remove the order confirmation.
     */
    async removeOrderConfirmationRaw(requestParameters: RemoveOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling removeOrderConfirmation().'
            );
        }

        if (requestParameters['orderConfirmationId'] == null) {
            throw new runtime.RequiredError(
                'orderConfirmationId',
                'Required parameter "orderConfirmationId" was null or undefined when calling removeOrderConfirmation().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['orderConfirmationId'] != null) {
            queryParameters['orderConfirmationId'] = requestParameters['orderConfirmationId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collective-order-confirmations/{id}/order-confirmations`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove the order confirmation. If the collective order confirmation had been sent, create a copy and cancel the old one, then remove the order confirmation.
     */
    async removeOrderConfirmation(requestParameters: RemoveOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeOrderConfirmationRaw(requestParameters, initOverrides);
    }

    /**
     * Replace the existing collective order confirmation.
     */
    async replaceCollectiveOrderConfirmationRaw(requestParameters: ReplaceCollectiveOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling replaceCollectiveOrderConfirmation().'
            );
        }

        if (requestParameters['collectiveOrderConfirmationDto'] == null) {
            throw new runtime.RequiredError(
                'collectiveOrderConfirmationDto',
                'Required parameter "collectiveOrderConfirmationDto" was null or undefined when calling replaceCollectiveOrderConfirmation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collective-order-confirmations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CollectiveOrderConfirmationDtoToJSON(requestParameters['collectiveOrderConfirmationDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Replace the existing collective order confirmation.
     */
    async replaceCollectiveOrderConfirmation(requestParameters: ReplaceCollectiveOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.replaceCollectiveOrderConfirmationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send the collective order confirmation to given recipients.
     */
    async sendCollectiveOrderConfirmationRaw(requestParameters: SendCollectiveOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling sendCollectiveOrderConfirmation().'
            );
        }

        if (requestParameters['orderConfirmationSendRequestDto'] == null) {
            throw new runtime.RequiredError(
                'orderConfirmationSendRequestDto',
                'Required parameter "orderConfirmationSendRequestDto" was null or undefined when calling sendCollectiveOrderConfirmation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collective-order-confirmations/{id}/send`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderConfirmationSendRequestDtoToJSON(requestParameters['orderConfirmationSendRequestDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send the collective order confirmation to given recipients.
     */
    async sendCollectiveOrderConfirmation(requestParameters: SendCollectiveOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendCollectiveOrderConfirmationRaw(requestParameters, initOverrides);
    }

    /**
     * Fetch the template for creating a new collective order confirmation.
     */
    async templateCollectiveOrderConfirmationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectiveOrderConfirmationDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collective-order-confirmations/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollectiveOrderConfirmationDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new collective order confirmation.
     */
    async templateCollectiveOrderConfirmation(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectiveOrderConfirmationDto> {
        const response = await this.templateCollectiveOrderConfirmationRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update the existing collective order confirmation.
     */
    async updateCollectiveOrderConfirmationRaw(requestParameters: UpdateCollectiveOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateCollectiveOrderConfirmation().'
            );
        }

        if (requestParameters['collectiveOrderConfirmationDto'] == null) {
            throw new runtime.RequiredError(
                'collectiveOrderConfirmationDto',
                'Required parameter "collectiveOrderConfirmationDto" was null or undefined when calling updateCollectiveOrderConfirmation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collective-order-confirmations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CollectiveOrderConfirmationDtoToJSON(requestParameters['collectiveOrderConfirmationDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the existing collective order confirmation.
     */
    async updateCollectiveOrderConfirmation(requestParameters: UpdateCollectiveOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCollectiveOrderConfirmationRaw(requestParameters, initOverrides);
    }

}
