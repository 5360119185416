/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HotelEditionDataPermissionDto
 */
export interface HotelEditionDataPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof HotelEditionDataPermissionDto
     */
    name: HotelEditionDataPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof HotelEditionDataPermissionDto
     */
    action: HotelEditionDataPermissionDtoActionEnum;
}


/**
 * @export
 */
export const HotelEditionDataPermissionDtoNameEnum = {
    HOTEL_EDITION_DATA: 'HOTEL_EDITION_DATA'
} as const;
export type HotelEditionDataPermissionDtoNameEnum = typeof HotelEditionDataPermissionDtoNameEnum[keyof typeof HotelEditionDataPermissionDtoNameEnum];

/**
 * @export
 */
export const HotelEditionDataPermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    COPY: 'CAN_COPY',
    CREATE: 'CAN_CREATE',
    EDIT: 'CAN_EDIT'
} as const;
export type HotelEditionDataPermissionDtoActionEnum = typeof HotelEditionDataPermissionDtoActionEnum[keyof typeof HotelEditionDataPermissionDtoActionEnum];


/**
 * Check if a given object implements the HotelEditionDataPermissionDto interface.
 */
export function instanceOfHotelEditionDataPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function HotelEditionDataPermissionDtoFromJSON(json: any): HotelEditionDataPermissionDto {
    return HotelEditionDataPermissionDtoFromJSONTyped(json, false);
}

export function HotelEditionDataPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelEditionDataPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function HotelEditionDataPermissionDtoToJSON(value?: HotelEditionDataPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

