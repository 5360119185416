import { Stack } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OwnerDto, OwnerTypeDto, SectionDtoStatusEnum, SectionReferenceDto } from '../../../api';
import sectionApi from '../../../data-access/section-api';
import { ElementFormModal, useElementForm } from '../../../ui/form/element-control';
import ValueAsyncSelectFormControl from '../../../ui/form/select-control/value-async-select-form-control';
import ValueSelectFormControl from '../../../ui/form/select-control/value-select-form-control';
import now from '../../../util/now';

interface OwnerFormModalProps {
  addedOwners: OwnerDto[];
  ownerTypeHelperText?: React.ReactNode;
}

export default function OwnerFormModal({ addedOwners, ownerTypeHelperText }: OwnerFormModalProps) {
  const { t: tCommon } = useTranslation('common');
  const { element: owner, onSubmit } = useElementForm<OwnerDto>();
  const initialFocusRef = React.useRef<HTMLInputElement>(null);
  const { register } = useFormContext<OwnerDto>();
  register('timestamp');

  return (
    <ElementFormModal<OwnerDto>
      element={owner}
      onSubmit={onSubmit}
      initialFocusRef={initialFocusRef}
      defaultElement={{ timestamp: new Date(now()) }}
    >
      <Stack spacing={4}>
        <ValueAsyncSelectFormControl<SectionReferenceDto>
          name="section"
          isRequired
          label={tCommon('owners.section.label')}
          helperText={tCommon('owners.section.helper_text')}
          ref={initialFocusRef}
          loadOptions={async (value: string, pageSizeLimit: number) => {
            const page = await sectionApi.searchSectionReferences({
              pageable: { size: pageSizeLimit, sort: ['name,ASC'] },
              filter: [`status,eq,${SectionDtoStatusEnum.ACTIVE}`],
              q: value,
            });
            const alreadyAddedSections = addedOwners.map((o) => o.section?.id);
            return page.content.filter((section) => !alreadyAddedSections.includes(section.id));
          }}
          renderLabel={(section) => section.name}
          optionIdentifier={(section) => section.id!}
          defaultOptions
        />
        <ValueSelectFormControl<OwnerTypeDto>
          name="type"
          label={tCommon('owners.type')}
          isRequired
          helperText={ownerTypeHelperText}
          options={[OwnerTypeDto.OWNER, OwnerTypeDto.CO_OWNER]}
          renderLabel={(value) => tCommon(`owners.typeLabels.${value}`)}
        />
      </Stack>
    </ElementFormModal>
  );
}
