/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { RecipientTypeDto } from './RecipientTypeDto';
import {
    RecipientTypeDtoFromJSON,
    RecipientTypeDtoFromJSONTyped,
    RecipientTypeDtoToJSON,
} from './RecipientTypeDto';

/**
 * 
 * @export
 * @interface MailTrackingListItemRecipientDto
 */
export interface MailTrackingListItemRecipientDto {
    /**
     * 
     * @type {RecipientTypeDto}
     * @memberof MailTrackingListItemRecipientDto
     */
    type: RecipientTypeDto;
    /**
     * 
     * @type {string}
     * @memberof MailTrackingListItemRecipientDto
     */
    id: string;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof MailTrackingListItemRecipientDto
     */
    person?: PersonReferenceDto;
}

/**
 * Check if a given object implements the MailTrackingListItemRecipientDto interface.
 */
export function instanceOfMailTrackingListItemRecipientDto(value: object): boolean {
    if (!('type' in value)) return false;
    if (!('id' in value)) return false;
    return true;
}

export function MailTrackingListItemRecipientDtoFromJSON(json: any): MailTrackingListItemRecipientDto {
    return MailTrackingListItemRecipientDtoFromJSONTyped(json, false);
}

export function MailTrackingListItemRecipientDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailTrackingListItemRecipientDto {
    if (json == null) {
        return json;
    }
    return {
        
        'type': RecipientTypeDtoFromJSON(json['type']),
        'id': json['id'],
        'person': json['person'] == null ? undefined : PersonReferenceDtoFromJSON(json['person']),
    };
}

export function MailTrackingListItemRecipientDtoToJSON(value?: MailTrackingListItemRecipientDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': RecipientTypeDtoToJSON(value['type']),
        'id': value['id'],
        'person': PersonReferenceDtoToJSON(value['person']),
    };
}

