/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DrivingAssignmentStatusDto = {
    NEW: 'NEW',
    NOT_NOTIFIED: 'NOT_NOTIFIED',
    NOTIFIED: 'NOTIFIED',
    CONFIRMED: 'CONFIRMED',
    PICKUP_LOCATION: 'PICKUP_LOCATION',
    ON_THE_ROAD: 'ON_THE_ROAD',
    ARRIVED_AT_DESTINATION: 'ARRIVED_AT_DESTINATION',
    NO_SHOW: 'NO_SHOW',
    CHANGE_REQUEST: 'CHANGE_REQUEST',
    WITHDRAWN: 'WITHDRAWN',
    CANCELLED: 'CANCELLED'
} as const;
export type DrivingAssignmentStatusDto = typeof DrivingAssignmentStatusDto[keyof typeof DrivingAssignmentStatusDto];


export function DrivingAssignmentStatusDtoFromJSON(json: any): DrivingAssignmentStatusDto {
    return DrivingAssignmentStatusDtoFromJSONTyped(json, false);
}

export function DrivingAssignmentStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrivingAssignmentStatusDto {
    return json as DrivingAssignmentStatusDto;
}

export function DrivingAssignmentStatusDtoToJSON(value?: DrivingAssignmentStatusDto | null): any {
    return value as any;
}

