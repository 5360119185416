import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const standMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.stand" />,
    path: '/stands',
    necessaryPermission: 'STAND:CAN_SEE_LIST_VIEW',
  },
];

export default standMenuItems;
