/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryReferenceDto } from './CountryReferenceDto';
import {
    CountryReferenceDtoFromJSON,
    CountryReferenceDtoFromJSONTyped,
    CountryReferenceDtoToJSON,
} from './CountryReferenceDto';

/**
 * A postal address of a person
 * @export
 * @interface DefaultPostalAddressDto
 */
export interface DefaultPostalAddressDto {
    /**
     * The address
     * @type {string}
     * @memberof DefaultPostalAddressDto
     */
    address?: string;
    /**
     * The address supplement
     * @type {string}
     * @memberof DefaultPostalAddressDto
     */
    addressSupplement?: string;
    /**
     * The post code of the address
     * @type {string}
     * @memberof DefaultPostalAddressDto
     */
    postCode?: string;
    /**
     * The city of the address
     * @type {string}
     * @memberof DefaultPostalAddressDto
     */
    city: string;
    /**
     * The state of the address
     * @type {string}
     * @memberof DefaultPostalAddressDto
     */
    state?: string;
    /**
     * 
     * @type {CountryReferenceDto}
     * @memberof DefaultPostalAddressDto
     */
    country: CountryReferenceDto;
    /**
     * 
     * @type {Date}
     * @memberof DefaultPostalAddressDto
     */
    timestamp?: Date;
}

/**
 * Check if a given object implements the DefaultPostalAddressDto interface.
 */
export function instanceOfDefaultPostalAddressDto(value: object): boolean {
    if (!('city' in value)) return false;
    if (!('country' in value)) return false;
    return true;
}

export function DefaultPostalAddressDtoFromJSON(json: any): DefaultPostalAddressDto {
    return DefaultPostalAddressDtoFromJSONTyped(json, false);
}

export function DefaultPostalAddressDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DefaultPostalAddressDto {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'] == null ? undefined : json['address'],
        'addressSupplement': json['addressSupplement'] == null ? undefined : json['addressSupplement'],
        'postCode': json['postCode'] == null ? undefined : json['postCode'],
        'city': json['city'],
        'state': json['state'] == null ? undefined : json['state'],
        'country': CountryReferenceDtoFromJSON(json['country']),
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
    };
}

export function DefaultPostalAddressDtoToJSON(value?: DefaultPostalAddressDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': value['address'],
        'addressSupplement': value['addressSupplement'],
        'postCode': value['postCode'],
        'city': value['city'],
        'state': value['state'],
        'country': CountryReferenceDtoToJSON(value['country']),
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
    };
}

