import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useOfferPermissionClassGroupExtension(): PermissionClassGroupExtension<'OFFER'> {
  const { t } = useTranslation(['offer']);

  return {
    name: 'OFFER',
    label: t('offer:offer'),
    additionalInformation: t('offer:permissionAdditionalInfo'),
    getActionLabel: (action) => t(`offer:permission.${action}`),
    getAdditionalRequirement: (action) => t(`offer:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`offer:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
