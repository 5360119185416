import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const mailingListMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.mailing-list" />,
    path: '/mailing-lists',
    necessaryPermission: 'MAILING_LIST:CAN_SEE_LIST_VIEW',
  },
];

export default mailingListMenuItems;
