import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import VisaBreadcrumb from './common/visa-breadcrumb';

const visaRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'visas',
    handle: {
      breadcrumb: <Translate ns="visa" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./visa-lister/visa-lister-route'),
      },
      {
        path: ':visaId',
        handle: {
          breadcrumb: <VisaBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./visa-page/visa-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./visa-viewer/visa-viewer-route'),
              },
            ],
          },
        ],
      },
    ],
  },
];

export default visaRoutes;
