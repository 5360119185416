import entryApi from '../../data-access/entry-api';
import mailingListApi from '../../data-access/mailing-list-api';
import { createFetcherScope } from '../../util/swr/fetcher-scope';

export const mailingListFetcher = createFetcherScope(mailingListApi);
export const searchMailingLists = mailingListFetcher.createFetcher(mailingListApi.searchMailingLists);
export const fetchMailingList = mailingListFetcher.createFetcher(mailingListApi.fetchMailingList);
export const fetchMailingListHistory = mailingListFetcher.createFetcher(mailingListApi.fetchMailingListHistory);
export const isMailingListDeletable = mailingListFetcher.createFetcher(mailingListApi.isMailingListDeletable);

export const entryFetcher = createFetcherScope(entryApi);
export const fetchEntries = entryFetcher.createFetcher(entryApi.mailingListFetchEntries);
export const fetchMailingListEntryHistory = entryFetcher.createFetcher(entryApi.fetchMailingListEntryHistory);
