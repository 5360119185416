import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const permissionClassGroupMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.permissionClassGroup" />,
    path: '/permission-class-groups',
    necessaryPermission: 'PERMISSION_CLASS_GROUP:CAN_SEE_LIST_VIEW',
  },
];

export default permissionClassGroupMenuItems;
