/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * One export done.
 * @export
 * @interface ExportDto
 */
export interface ExportDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof ExportDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof ExportDto
     */
    version: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof ExportDto
     */
    type: ExportDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ExportDto
     */
    reason: string;
    /**
     * 
     * @type {string}
     * @memberof ExportDto
     */
    fileName: string;
}


/**
 * @export
 */
export const ExportDtoTypeEnum = {
    GUESTLIST: 'GUESTLIST',
    DRIVE_UP_LIST: 'DRIVE_UP_LIST'
} as const;
export type ExportDtoTypeEnum = typeof ExportDtoTypeEnum[keyof typeof ExportDtoTypeEnum];


/**
 * Check if a given object implements the ExportDto interface.
 */
export function instanceOfExportDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('version' in value)) return false;
    if (!('type' in value)) return false;
    if (!('reason' in value)) return false;
    if (!('fileName' in value)) return false;
    return true;
}

export function ExportDtoFromJSON(json: any): ExportDto {
    return ExportDtoFromJSONTyped(json, false);
}

export function ExportDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': VersionDtoFromJSON(json['version']),
        'type': json['type'],
        'reason': json['reason'],
        'fileName': json['fileName'],
    };
}

export function ExportDtoToJSON(value?: ExportDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'type': value['type'],
        'reason': value['reason'],
        'fileName': value['fileName'],
    };
}

