import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { collectiveOrderConfirmationIcon } from '../../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../../search/search-result';

export default function CollectiveOrderConfirmationSearchResult({ result, onClick }: SearchResultProps) {
  invariant(
    result.resultType === 'COLLECTIVE_ORDER_CONFIRMATION',
    `${result.resultType} is no instance of CollectiveOrderConfirmationSearchResult`,
  );

  const collectiveOrderConfirmation = result.result;
  const { t } = useTranslation(['order_confirmation']);

  return (
    <SearchResult
      icon={collectiveOrderConfirmationIcon}
      title={collectiveOrderConfirmation.collectiveOrderConfirmationNumber!}
      link={`/collective-order-confirmations/${collectiveOrderConfirmation.id}`}
      score={result.score}
      onClick={onClick}
      necessaryPermission="COLLECTIVE_ORDER_CONFIRMATION:CAN_SEE_DETAILS"
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('order_confirmation:issuer'),
            element: t(`order_confirmation:issuerOptions.${collectiveOrderConfirmation.issuer}`),
          },
        ]}
      />
    </SearchResult>
  );
}
