import React from 'react';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';

const taxiVoucherRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'taxi-vouchers',
    handle: {
      breadcrumb: <Translate ns="taxi_voucher" i18nKey="taxiVoucher" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./taxi-voucher-lister/taxi-voucher-lister-route'),
      },
      {
        path: 'new',
        lazy: () => import('./taxi-voucher-editor/new-taxi-voucher-route'),
      },
      // {
      //   path: ':taxiVoucherId',
      //   handle: {
      //     breadcrumb: <TaxiVoucherBreadcrumb />,
      //   },
      //   children: [
      //     {
      //       path: '',
      //       lazy: () => import('./taxi-voucher-page/taxi-voucher-page-route'),
      //       children: [],
      //     },
      //     // {
      //     //   path: 'edit',
      //     //   lazy: () => import('./taxi-voucher-editor/edit-taxi-voucher-route'),
      //     // },
      //   ],
      // },
    ],
  },
];

export default taxiVoucherRoutes;
