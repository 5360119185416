/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface NotificationSettingsDto
 */
export interface NotificationSettingsDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof NotificationSettingsDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof NotificationSettingsDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettingsDto
     */
    notifyViaMail?: boolean;
}

/**
 * Check if a given object implements the NotificationSettingsDto interface.
 */
export function instanceOfNotificationSettingsDto(value: object): boolean {
    return true;
}

export function NotificationSettingsDtoFromJSON(json: any): NotificationSettingsDto {
    return NotificationSettingsDtoFromJSONTyped(json, false);
}

export function NotificationSettingsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationSettingsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'notifyViaMail': json['notifyViaMail'] == null ? undefined : json['notifyViaMail'],
    };
}

export function NotificationSettingsDtoToJSON(value?: NotificationSettingsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'notifyViaMail': value['notifyViaMail'],
    };
}

