/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OrderConfirmationTypeDto = {
    SINGLE: 'SINGLE',
    COLLECTIVE: 'COLLECTIVE'
} as const;
export type OrderConfirmationTypeDto = typeof OrderConfirmationTypeDto[keyof typeof OrderConfirmationTypeDto];


export function OrderConfirmationTypeDtoFromJSON(json: any): OrderConfirmationTypeDto {
    return OrderConfirmationTypeDtoFromJSONTyped(json, false);
}

export function OrderConfirmationTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderConfirmationTypeDto {
    return json as OrderConfirmationTypeDto;
}

export function OrderConfirmationTypeDtoToJSON(value?: OrderConfirmationTypeDto | null): any {
    return value as any;
}

