import React from 'react';
import { Trans } from 'react-i18next';
import placementApi from '../../../../data-access/placement-api';
import Translate from '../../../../util/translate/translate';
import { GuestListDeleteAllocationExtension } from '../../../guest-list/guest-list-form/delete-allocation-extension';
import PlacementReference from '../placement-reference/placement-reference';

export const deleteAllocationExtension: GuestListDeleteAllocationExtension = {
  shouldShowDialog: (allocationId: string) => {
    return placementApi.hasSeatAssignment({ allocationId });
  },
  body: (allocationName: string, placementLink: string) => (
    <Trans
      ns="placement"
      i18nKey="delete_allocation.body"
      values={{
        allocationName: allocationName,
      }}
      components={{ placementRef: <PlacementReference placementLink={placementLink} /> }}
    />
  ),
  onDeleteLabel: <Translate ns="placement" i18nKey="delete_allocation.button_label" />,
};
