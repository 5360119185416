import { Textarea } from '@chakra-ui/react';
import { isEmpty, isEqual, trim } from 'lodash-es';
import React, { ChangeEvent, forwardRef, useEffect, useState } from 'react';
import { FieldValues, Path, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { validateEmailNotOnBlocklistFunction } from '../../../feature/common/validation/validate-email-not-on-blocklist';
import { EMAIL_PATTERN } from '../../../util/constants';
import useAsyncValidation from '../../../util/use-async-validation/use-async-validation';
import FormControl from '../form-control';

interface EmailsControlProps<TFieldValues extends FieldValues> {
  name: Path<TFieldValues>;
  label?: string;
  isRequired?: boolean;
  helperText?: React.ReactNode;
}

function EmailsControl<TFieldValues extends FieldValues>(
  { name, label, isRequired, helperText, ...props }: EmailsControlProps<TFieldValues>,
  ref: React.ForwardedRef<HTMLTextAreaElement>,
) {
  const { t } = useTranslation(['common']);

  const validateEmailNotOnBlocklist = useAsyncValidation(validateEmailNotOnBlocklistFunction());
  const areMailsValid = async (emails: string[] | undefined) => {
    if (isEmpty(emails)) {
      return true;
    }

    if (emails!.some((address) => !EMAIL_PATTERN.test(address))) {
      return t('common:validation_error.emailPattern');
    }
    const notOnBlocklist = await validateEmailNotOnBlocklist(emails!);
    if (!notOnBlocklist) {
      return t('common:validation_error.emailOnBlocklistMultiple');
    }
  };

  const { field } = useController({
    name,
    rules: {
      required: isRequired ? t('validation_error.required', { field: label }) : undefined,
      validate: areMailsValid,
    },
  });
  const [emails, setEmails] = useState('');

  useEffect(() => {
    if (!isEqual(splitAndTrimEmails(emails), field.value ?? [])) {
      setEmails(field.value.join(';\n'));
    }
  }, [emails, field.value]);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    validateEmailNotOnBlocklist.reset();

    setEmails(event.target.value);
    field.onChange(splitAndTrimEmails(event.target.value));
  };

  return (
    <FormControl name={name} label={label} helperText={helperText} isRequired={isRequired}>
      <Textarea {...props} value={emails} onChange={handleChange} ref={ref} />
    </FormControl>
  );
}

export const splitAndTrimEmails = (emails: string) => {
  return emails
    .split(';')
    .map(trim)
    .filter((e) => !isEmpty(e));
};

export default forwardRef(EmailsControl);
