/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DriveUpListPermissionDto
 */
export interface DriveUpListPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof DriveUpListPermissionDto
     */
    name: DriveUpListPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof DriveUpListPermissionDto
     */
    action: DriveUpListPermissionDtoActionEnum;
}


/**
 * @export
 */
export const DriveUpListPermissionDtoNameEnum = {
    DRIVE_UP_LIST: 'DRIVE_UP_LIST'
} as const;
export type DriveUpListPermissionDtoNameEnum = typeof DriveUpListPermissionDtoNameEnum[keyof typeof DriveUpListPermissionDtoNameEnum];

/**
 * @export
 */
export const DriveUpListPermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    SEE_DETAILS_OWN: 'CAN_SEE_DETAILS_OWN',
    EDIT: 'CAN_EDIT',
    EDIT_OPEN_OWN: 'CAN_EDIT_OPEN_OWN',
    EDIT_CLOSED_OWN: 'CAN_EDIT_CLOSED_OWN',
    EDIT_SETTINGS: 'CAN_EDIT_SETTINGS',
    EDIT_SETTINGS_OWN: 'CAN_EDIT_SETTINGS_OWN',
    CREATE: 'CAN_CREATE',
    CREATE_OWN: 'CAN_CREATE_OWN',
    ACTIVATE_DEACTIVATE: 'CAN_ACTIVATE_DEACTIVATE',
    ACTIVATE_DEACTIVATE_OWN: 'CAN_ACTIVATE_DEACTIVATE_OWN',
    EXPORT: 'CAN_EXPORT',
    EXPORT_OWN: 'CAN_EXPORT_OWN'
} as const;
export type DriveUpListPermissionDtoActionEnum = typeof DriveUpListPermissionDtoActionEnum[keyof typeof DriveUpListPermissionDtoActionEnum];


/**
 * Check if a given object implements the DriveUpListPermissionDto interface.
 */
export function instanceOfDriveUpListPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function DriveUpListPermissionDtoFromJSON(json: any): DriveUpListPermissionDto {
    return DriveUpListPermissionDtoFromJSONTyped(json, false);
}

export function DriveUpListPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriveUpListPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function DriveUpListPermissionDtoToJSON(value?: DriveUpListPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

