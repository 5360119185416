/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NotificationModeDto } from './NotificationModeDto';
import {
    NotificationModeDtoFromJSON,
    NotificationModeDtoFromJSONTyped,
    NotificationModeDtoToJSON,
} from './NotificationModeDto';

/**
 * Abbonnement für Änderungen an Geschäftsobjekten
 * @export
 * @interface SubscriptionDto
 */
export interface SubscriptionDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof SubscriptionDto
     */
    entityId: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    type: string;
    /**
     * 
     * @type {NotificationModeDto}
     * @memberof SubscriptionDto
     */
    notificationMode: NotificationModeDto;
}

/**
 * Check if a given object implements the SubscriptionDto interface.
 */
export function instanceOfSubscriptionDto(value: object): boolean {
    if (!('entityId' in value)) return false;
    if (!('type' in value)) return false;
    if (!('notificationMode' in value)) return false;
    return true;
}

export function SubscriptionDtoFromJSON(json: any): SubscriptionDto {
    return SubscriptionDtoFromJSONTyped(json, false);
}

export function SubscriptionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'entityId': json['entityId'],
        'type': json['type'],
        'notificationMode': NotificationModeDtoFromJSON(json['notificationMode']),
    };
}

export function SubscriptionDtoToJSON(value?: SubscriptionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'entityId': value['entityId'],
        'type': value['type'],
        'notificationMode': NotificationModeDtoToJSON(value['notificationMode']),
    };
}

