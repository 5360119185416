import {
  Button,
  Center,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { faGear } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NotificationSettingsDto } from '../../../api';
import notificationApi from '../../../data-access/notification-api';
import Form from '../../../ui/form/form';
import ValueSelectFormControl from '../../../ui/form/select-control/value-select-form-control';
import usePromise from '../../../util/use-promise/use-promise';
import useToast from '../../../ui/use-toast/use-toast';

export default function NotificationSettingsButton() {
  const { t } = useTranslation(['notification', 'common']);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Tooltip label={t('notification:center.label')}>
        <IconButton
          variant="ghost"
          icon={<FontAwesomeIcon icon={faGear} />}
          aria-label={t('notification:center.label')}
          onClick={onOpen}
        />
      </Tooltip>
      {isOpen && <NotificationSettingsFormModal onClose={onClose} />}
    </>
  );
}

function NotificationSettingsFormModal({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation(['notification', 'common']);
  const settings = usePromise((signal) => notificationApi.getNotificationSettings({ signal }), []);

  return (
    <Modal isOpen={true} onClose={onClose} closeOnOverlayClick={false} size="md">
      <ModalOverlay />
      {settings == null ? (
        <ModalContent>
          <ModalHeader>{t('notification:settings')}</ModalHeader>
          <ModalCloseButton />
          <Center>
            <Spinner />
          </Center>
        </ModalContent>
      ) : (
        <NotificationSettingsInnerFormModal settings={settings} onClose={onClose} />
      )}
    </Modal>
  );
}

function NotificationSettingsInnerFormModal({
  settings,
  onClose,
}: {
  settings: NotificationSettingsDto;
  onClose: () => void;
}) {
  const { t } = useTranslation(['notification', 'common']);
  const form = useForm<NotificationSettingsDto>({
    defaultValues: settings,
    mode: 'all',
  });
  const formIsValid = form.formState.isValid;
  const toast = useToast({ status: 'success' });

  const handleValid = async (settings: NotificationSettingsDto) => {
    await notificationApi.updateNotificationSettings({ notificationSettingsDto: settings });
    toast({
      title: t('notification:toast.settingsSaved.title'),
    });
    onClose();
  };

  return (
    <FormProvider<NotificationSettingsDto> {...form}>
      <ModalContent as={Form} onValid={handleValid}>
        <ModalHeader>{t('notification:settings')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ValueSelectFormControl<boolean, NotificationSettingsDto>
            options={[true, false]}
            renderLabel={(value) => t(`notification:notifyViaMailOptions.${value}`)}
            name="notifyViaMail"
            label={t('notification:notifyViaMail')}
            rules={{
              validate: (value) =>
                value != null || t('common:validation_error.required', { field: t('notification:notifyViaMail') }),
            }}
            isClearable={false}
          />
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            {t('common:action.abort')}
          </Button>
          <Button
            variant="primary"
            type="submit"
            isDisabled={
              form.formState.isSubmitting || !formIsValid || Object.keys(form.formState.dirtyFields).length == 0
            }
          >
            {t('common:action.apply')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </FormProvider>
  );
}
