import React from 'react';
import { useParams } from 'react-router-dom';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchDrivingAssignment } from '../driving-assignment-queries';
import { getDrivingAssignmentId } from './get-driving-assignment-id';

export default function DrivingAssignmentBreadcrumb() {
  const drivingAssignment = useFetcher(fetchDrivingAssignment, { id: getDrivingAssignmentId(useParams()) });

  return <>{drivingAssignment.drivingAssignmentNumber}</>;
}
